import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Preloader from "../Preloader";

function FAQ() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Make an API request to fetch data
        const response = await axios.get("https://node.mumber.com/api/allfaqs");

        // Once the data is fetched, set isLoading to false
        setIsLoading(false);

        // Process the data further if needed
        console.log(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  const [data, setData] = useState([]);

  const loadData = async () => {
    try {
      const response = await axios.get("https://node.mumber.com/api/allfaqs");
      setData(response.data.data);
      console.log(data);
    } catch (error) {
      console.log(error);
      // toast.error("Failed to fetch data");
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      {isLoading ? (
        <div className="content-wrapper" style={{ marginBottom: "200px" }}>
          <Preloader />
        </div>
      ) : (
        <div>
          <section className="banner-area pages-banner">
            <div className="container">
              <div className="row banner-row align-items-center">
                <div className="col-lg-6">
                  <div className="banner-heading">
                    <h2>FAQ</h2>
                    <p>
                      Find the perfect easy to remember phone number for your
                      business.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="faq-banner-img man-img">
                    <img src="assets/images/faq-banner.png" alt="manImg" />
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/*========================================================================================
								End banner area
	=========================================================================================*/}
          {/*========================================================================================
								Start faq area
	=========================================================================================*/}
          <section className="faq-area">
            {data.map((faq, index) => {
              if (index % 2 === 0) {
                return (
                  <div key={faq.id} className="faq-single-item faq-item">
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="faq-image">
                            <img
                              style={{ width: "100%" }}
                              src={faq.images}
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div
                            style={{ marginTop: "-111px" }}
                            className="right-faq-content faq-content"
                          >
                            <h2
                              dangerouslySetInnerHTML={{
                                __html: `${faq.Title}`,
                              }}
                            />
                            <p
                              dangerouslySetInnerHTML={{
                                __html: `${faq.ShortDescription}`,
                              }}
                            />

                            <div className="read-button">
                              <Link to={`/Faqcontent/${faq.Id}`}>
                                <a href="#">Read More</a>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              } else {
                return (
                  <div key={faq.id} className="faq-single-item white-bg">
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="faq-content">
                            <h2
                              dangerouslySetInnerHTML={{
                                __html: `${faq.Title}`,
                              }}
                            />
                            <p
                              dangerouslySetInnerHTML={{
                                __html: `${faq.ShortDescription}`,
                              }}
                            />
                            <div className="read-button">
                              <Link to={`/Faqcontent/${faq.Id}`}>
                                <a href="#">Read More</a>
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 order">
                          <div className="faq-image">
                            <img
                              style={{ width: "100%" }}
                              src={faq.images}
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
            })}
            {/* <div className="faq-single-item white-bg">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <div className="faq-content">
                    <h2>Morbi in sem quis dui <span>placerat</span> ornare.</h2>
                    <h5>Quisque volutpat mattis eros.</h5>
                    <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros. Nullam malesuada erat ut turpis. Suspendisse urna nibh, viverra non, semper suscipit, posuere a, pede.</p>
                    <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros. Nullam malesuada erat ut turpis. Suspendisse urna nibh. Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros. Nullam malesuada erat ut turpis. Suspendisse urna nibh, viverra non, semper suscipit, posuere a, pede.</p>
                    <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros. Nullam malesuada erat ut turpis. Suspendisse urna nibh.</p>
                    <div className="devon-item">
                      <img src="assets/images/devon.png" alt="" /><h4>Devon Lane</h4>
                      <img src="assets/images/devon2.png" alt="" /><h4>Devon Lane</h4>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 order">
                  <div className="faq-image">
                    <img src="assets/images/faq2.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div className="faq-single-item faq-item">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <div className="faq-image">
                    <img src="assets/images/faq3.png" alt="" />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="right-faq-content faq-content">
                    <h2>Morbi in sem quis dui <span>placerat</span> ornare.</h2>
                    <h5>Quisque volutpat mattis eros.</h5>
                    <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros. Nullam malesuada erat ut turpis. Suspendisse urna nibh, viverra non, semper suscipit, posuere a, pede.</p>
                    <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros. Nullam malesuada erat ut turpis. Suspendisse urna nibh.</p>
                    <div className="started-button">
                      <a href="#">Get Started</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          </section>
        </div>
      )}

      <section className="callout-area" style={{ marginTop: "20%" }}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="common-heading">
                <h3>
                  <span className="blue-x">Market Smarter, </span> Not Harder
                </h3>
                <p>
                  Building a brand is hard work. Make it easier by marketing
                  with a memorable phone number. Your unique number will leave a
                  lasting impression so you are first to mind when there is a
                  need for your service.
                </p>
                <div className="common-btn">
                  <Link to="/contact" className="btn-primaris">
                    Get Started
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="collout-man">
                <img
                  loading="lazy"
                  src="assets/images/footerMan2.png"
                  alt="man"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default FAQ;
