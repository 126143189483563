import React, { useEffect } from "react";

const Contact = () => {
  useEffect(() => {
    const contact_form = document.getElementById("contact_form");
    const code = `hbspt.forms.create({
            region: "na1",
            portalId: "39892086",
            formId: "113e879a-94de-4a92-98b6-c0cce4251c4b"
          });`;

    const script2 = document.createElement("script");
    script2.async = true;
    script2.innerHTML = code;

    contact_form.appendChild(script2);

    return () => {
      // Cleanup: remove the script when the component unmounts

      contact_form.innerHTML = "";
    };
  }, []);
  return (
    <section className="banner-area home-banner">
      <div className="container first_container py-lg-5">
        <div className="row banner-row align-items-center">
          <div className="col-lg-6">
            <div className="banner-heading bennar_heading">
              <h2>
                Contact <span>Us</span>
              </h2>
              <p>
                Find the perfect easy to remember phone number for your
                business.
              </p>
            </div>
            <div className="my-lg-5 m-auto pt-lg-5 pt-md-5 my-md-5 my-4">
              <div className="d-flex align-items-center py-md-3 py-lg-3 py-3">
                <img
                  height="16px"
                  src="assets/images/Unionphone.png"
                  alt=""
                  className="pe-3 px-4"
                />
                <p>+1 (838) 333-3333</p>
              </div>
              <div className="d-flex align-items-center py-md-3 py-lg-3 py-3">
                <img
                  height="16px"
                  src="assets/images/emails.png"
                  alt=""
                  className="pe-3 px-4"
                />
                <p>Support@ mumber.com</p>
              </div>
              <div className="d-flex align-items-center py-md-3 py-lg-3 py-3">
                <img
                  height="16px"
                  src="assets/images/Subtract.png"
                  alt=""
                  className="pe-3 px-4"
                />
                <p>St. Williamsville, NY 14221</p>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            {/* <!-- <div className="man-img">
                        <img
                            src="assets/images/bannerMan1.png"
                            alt="manImg"
                        />
                    </div> --> */}
            <div className="form_box mt-md-5 mt-5 mb-4 mb-md-5 mx-md-5 mx-lg-0">
              <div id="contact_form"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
