import React, {useEffect} from "react";
import { Dropdown, Badge } from 'react-bootstrap';
import { FaCheck } from 'react-icons/fa';


const Thankyou = () => {

    useEffect(() => {
        const timer = setTimeout(() => {
          window.location.href = "/"; // Replace "/home" with the actual URL of your home page
        }, 5000);
    
        return () => {
          clearTimeout(timer);
        };
      }, []);

    return(
        <section className="banner-area home-banner">
        <div className="container first_container py-lg-5">
        <div style={{height: "80px"}}>

</div>
            <div className="row banner-row align-items-center">
                <div className="col-lg-12">
                    <div className="banner-heading bennar_heading">
                        <h2 className="text-center">Thank You for <span>Your Order!</span></h2>
                        <h4 style={{color: "#ddd", marginBottom:"60px"}} className="text-center">
                            Our team will be in touch shortly.
                        </h4>
                       
                        <h1 className="thankyou"><FaCheck/></h1>
                        <h4 style={{color: "#ddd",marginTop:"50px" ,marginBottom:"60px"}} className="text-center">Please check the inbox for order details. Or in case you
                             will not see it in the inbox, please check the spam as well</h4>
                    </div>
                    
                </div>
                <div style={{height: "60px"}}>

                </div>
            </div>
        </div>
    </section>
    );
}

export default Thankyou;