import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import $ from "jquery";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Checkout2 from "./Checkout2";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import { event } from "jquery";

const Checkout = () => {
  const navigate = useNavigate();

  const [gatewaydata, setGatewaydata] = useState([]);

  const loadData11 = async () => {
    try {
      const response = await axios.get(
        "https://node.mumber.com/api/get_gateway_settings"
      );
      setGatewaydata(response.data.data[0]);

      // console.log(response.data.data[0].client_id);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    loadData11();
  }, []);

  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const products = JSON.parse(sessionStorage.getItem("cart"));
  const [personal_info, setpersonal_info] = useState({
    email: "",
    country: "United States",
    First_name: "",
    Last_name: "",
    street: "",
    city: "",
    state: "",
    zip_code: "",
    company_name: "",
    phone: "",
  });

  const [card_info, setcard_info] = useState({
    name_on_card: "",
    expire_on: "",
    acc_number: "",
    cvc: "",
  });

  var cart_total_amount = 0;

  const [product_fee, setproduct_fee] = useState(0);

  useEffect(() => {
    products.map((product) => {
      if (product != null) {
        var product_price = product.fees;
        product_price = product_price.replace("$", "");
        cart_total_amount =
          parseFloat(cart_total_amount) + parseFloat(product_price);
        setproduct_fee(
          Math.round((cart_total_amount + Number.EPSILON) * 100) / 100
        );
        console.log(product);
      }
    });

    console.log(product_fee);
  });

  const handlechange = (event) => {
    setpersonal_info({
      ...personal_info,
      [event.target.name]: event.target.value,
    });
  };

  const handlecardchange = (event) => {
    setcard_info({
      ...card_info,
      [event.target.name]: event.target.value,
    });
  };

  const [loading, setLoading] = useState(false);

  // const handleSubmit = async (event) => {
  //   event.preventDefault();

  //   try {
  //     // Check if any required fields are empty
  //     if (
  //       personal_info.email === "" ||
  //       personal_info.country === "" ||
  //       personal_info.First_name === "" ||
  //       personal_info.Last_name === "" ||
  //       personal_info.street === "" ||
  //       personal_info.city === "" ||
  //       personal_info.state === "" ||
  //       personal_info.phone === "" ||
  //       personal_info.zip_code === "" ||
  //       personal_info.email === ""
  //       // card_info.name_on_card === "" ||
  //       // card_info.acc_number === "" ||
  //       // card_info.expire_on === "" ||
  //       // card_info.cvc === ""
  //     ) {
  //       toast.error("Please fill in the data", { position: toast.POSITION.TOP_CENTER });
  //       return;
  //     }

  //     // Check if terms checkbox is checked
  //     if (!isChecked) {
  //       alert("Please check terms and conditions");
  //       return;
  //     }

  //     // Check if reCAPTCHA verification is done
  //     // if (!isRecaptchaVerified) {
  //     //   alert("Please complete the reCAPTCHA verification");
  //     //   return;
  //     // }

  //     setLoading(true);

  //     console.log(personal_info.email);
  //     console.log(personal_info.country);
  //     console.log(personal_info.First_name);
  //     console.log(personal_info.Last_name);
  //     console.log(personal_info.street);
  //     console.log(personal_info.city);
  //     console.log(personal_info.state);
  //     console.log(personal_info.phone);
  //     console.log(personal_info.zip_code);
  //     console.log(personal_info.email);
  //     // console.log(card_info.name_on_card);
  //     // console.log(card_info.acc_number);
  //     // console.log(card_info.expire_on);
  //     // console.log(card_info.cvc);

  //     // const check_customer = await checkCustomer();
  //     // const auth_token = await fetchToken();
  //     let gateway_customer;

  //     // if (check_customer.success === false) {
  //     //   gateway_customer = await gatewayCustomer(auth_token);
  //     //   gateway_customer = gateway_customer.Customer;

  //       const customerResponse = await addCustomer(gateway_customer);

  //     //   // const add_card = await addCard(auth_token, gateway_customer);
  //     //   // console.log(add_card);
  //     //   // const update_customercard = await updateCustomerid(add_card);
  //     // } else {
  //     //   gateway_customer = check_customer.data;
  //     // }

  //     // const generate_invoice = await generateInvoice(auth_token, gateway_customer);
  //     // console.log(generate_invoice);
  //     // const paymentResponse = await makePayment(auth_token);

  //     // const create_payment = await createPayment(auth_token, generate_invoice, gateway_customer);
  //     // console.log(create_payment);

  //     // const customerResponse = await addCustomer(gateway_customer);

  //     const orderResponse = await addOrder(customerResponse.data, products);
  //     // updateProductSoldStatus(productId);

  //     // const get_order_details = await getOrderDetails(orderResponse);
  //     // console.log(get_order_details);
  //     // const generate_next_invoice = await generateNextInvoice(auth_token, gateway_customer, get_order_details);

  //     // const update_next_invoice = await updateCustomerNextInvoice(generate_next_invoice, get_order_details);
  //     // const send_invoice = await sendInvoice(auth_token, generate_invoice);

  //     toast.success("Order Placed", { position: toast.POSITION.TOP_CENTER });

  //     sessionStorage.removeItem("cart");
  //     sessionStorage.removeItem("cart_phones");

  //     $("body").find(".cart_count").text(0);

  //     navigate("/Thankyou");

  //     setLoading(false);
  //   } catch (error) {
  //     console.error("Error:", error);
  //     setLoading(false);
  //   }
  // };

  const fetchToken = async () => {
    try {
      const response = await axios.get("https://node.mumber.com/api/getToken");
      const auth_token = response.data;
      // console.log('Token:', auth_token);
      console.log(gatewaydata);
      return auth_token;
    } catch (error) {
      throw new Error("Failed to fetch token");
    }
  };

  const addCard = async (auth_token, gateway_customer) => {
    const url =
      "https://sandbox.api.intuit.com/quickbooks/v4/customers/" +
      gateway_customer.Id +
      "/cards";
    const data = {
      expMonth: card_info.expire_on.split("/")[0],
      address: {
        postalCode: personal_info.zip_code,
        city: "Test",
        streetAddress: personal_info.street,
        region: "VA",
        country: "US",
      },
      number: card_info.acc_number,
      name: card_info.name_on_card,
      expYear: card_info.expire_on.split("/")[1],
    };

    try {
      const response = await axios.post(url, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth_token,
          "request-Id": Date.now(),
        },
      });

      console.log("Payment response:", response.data);
      return response;
    } catch (error) {
      // alert('Invalid Card details!');
      toast.error("Invalid Card details!", {
        position: toast.POSITION.TOP_CENTER,
      });

      throw new Error("Failed to make payment");
    }
  };

  const makePayment = async (auth_token) => {
    const url = "https://sandbox.api.intuit.com/quickbooks/v4/payments/charges";
    const data = {
      currency: "USD",
      amount: parseFloat(product_fee),
      context: {
        mobile: "false",
        isEcommerce: "true",
      },
      card: {
        name: card_info.name_on_card,
        number: card_info.acc_number,
        expMonth: card_info.expire_on.split("/")[0],
        address: {
          postalCode: personal_info.zip_code,
          country: "US",
          region: "CA",
          streetAddress: personal_info.street,
          city: "Sunnyvale",
        },
        expYear: card_info.expire_on.split("/")[1],
        cvc: card_info.cvc,
      },
    };

    try {
      const response = await axios.post(url, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth_token,
          "request-Id": Date.now(),
        },
      });

      console.log("Payment response:", response.data);
      return response;
    } catch (error) {
      // alert('Invalid Card details!');
      toast.error("Invalid Card details!", {
        position: toast.POSITION.TOP_CENTER,
      });

      throw new Error("Failed to make payment");
    }
  };

  const updateCustomerNextInvoice = async (
    generate_next_invoice,
    orderResponse
  ) => {
    try {
      let formattedDate = "";

      if (orderResponse.data[0].plan == "Yearly") {
        const currentDate = new Date();
        currentDate.setDate(currentDate.getDate() + 365);

        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, "0");
        const day = String(currentDate.getDate()).padStart(2, "0");

        formattedDate = `${year}-${month}-${day}`;
      } else {
        const currentDate = new Date();
        currentDate.setDate(currentDate.getDate() + 30);

        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, "0");
        const day = String(currentDate.getDate()).padStart(2, "0");

        formattedDate = `${year}-${month}-${day}`;
      }

      // console.log(add_card);

      const up_data = {
        date: formattedDate,
        invoice_id: generate_next_invoice.Invoice.Id,
        amount: orderResponse.data[0].Total,
        plan: orderResponse.data[0].plan,
        user_id: orderResponse.data[0].customer_id,
        order_id: orderResponse.data[0].Id,
      };
      const resp = await axios.post(
        "https://node.mumber.com/api/update_customer_next_invoice",
        {
          data: up_data,
        }
      );
      // alert("here");
      // console.log(resp);
      return resp.data;
    } catch (error) {
      // alert('Invalid Card details!');
      toast.error("Invalid Card details!", {
        position: toast.POSITION.TOP_CENTER,
      });

      throw new Error("Failed to make payment");
    }
  };

  const updateCustomerid = async (add_card) => {
    try {
      // console.log(add_card);

      const resp = await axios.get(
        "https://node.mumber.com/api/update_customerid/" +
          add_card.data.id +
          "/" +
          personal_info.email
      );
      // alert("here");
      // console.log(resp);
      return resp.data;
    } catch (error) {
      // alert('Invalid Card details!');
      toast.error("Invalid Card details!", {
        position: toast.POSITION.TOP_CENTER,
      });

      throw new Error("Failed to make payment");
    }
  };

  const checkCustomer = async () => {
    try {
      const data = {
        email: personal_info.email,
      };

      const resp = await axios.get(
        "https://node.mumber.com/api/getCustomer/" + personal_info.email,
        {
          data: data,
        }
      );

      // console.log(resp);
      return resp.data;
    } catch (error) {
      // alert('Invalid Card details!');
      toast.error("Invalid Card details!", {
        position: toast.POSITION.TOP_CENTER,
      });

      throw new Error("Failed to make payment");
    }
  };

  const gatewayCustomer = async (auth_token) => {
    try {
      const create_cust_url =
        "https://sandbox-quickbooks.api.intuit.com/v3/company/" +
        gatewaydata.company_id.trim() +
        "/customer";
      const cust_data = {
        FullyQualifiedName:
          personal_info.First_name + " " + personal_info.Last_name,
        PrimaryEmailAddr: {
          Address: personal_info.email,
        },
        DisplayName: personal_info.email,
        Suffix: "",
        Title: "Mr",
        MiddleName: "",
        Notes: "Here are other details.",
        FamilyName: personal_info.First_name,
        PrimaryPhone: {
          FreeFormNumber: "",
        },
        CompanyName: personal_info.First_name,
        BillAddr: {
          CountrySubDivisionCode: personal_info.country,
          City: personal_info.city,
          PostalCode: personal_info.zip_code,
          Line1: personal_info.street,
          Country: personal_info.country,
        },
        GivenName: personal_info.First_name,
      };

      const resp = await axios.post("https://node.mumber.com/gatewayCustomer", {
        url: create_cust_url,
        data: cust_data,
        token: auth_token,
      });

      return resp.data;
    } catch (error) {
      // alert('Invalid Card details!');
      toast.error("Invalid Card details!", {
        position: toast.POSITION.TOP_CENTER,
      });

      throw new Error("Failed to make payment");
    }
  };

  const createPayment = async (
    auth_token,
    generate_invoice,
    gateway_customer
  ) => {
    try {
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, "0");
      const day = String(currentDate.getDate()).padStart(2, "0");

      const formattedDate = `${year}-${month}-${day}`;

      const create_cust_url =
        "https://sandbox-quickbooks.api.intuit.com/v3/company/" +
        gatewaydata.company_id.trim() +
        "/payment?minorversion=65";
      const cust_data = {
        TotalAmt: parseFloat(product_fee),
        CustomerRef: {
          value: gateway_customer.Id,
        },
        Line: [
          {
            Amount: parseFloat(product_fee),
            LinkedTxn: [
              {
                TxnId: generate_invoice.Invoice.Id,
                TxnType: "Invoice",
              },
            ],
          },
        ],
      };

      const response = await axios.post(
        "https://node.mumber.com/create_payment",
        {
          url: create_cust_url,
          data: cust_data,
          token: auth_token,
        }
      );

      return response.data;
    } catch (error) {
      // alert('Invalid Card details!');
      toast.error("Invalid Card details!", {
        position: toast.POSITION.TOP_CENTER,
      });

      throw new Error("Failed to make payment");
    }
  };

  const sendInvoice = async (auth_token, generate_invoice) => {
    try {
      // alert("herell");
      const create_cust_url =
        "https://sandbox-quickbooks.api.intuit.com/v3/company/" +
        gatewaydata.company_id.trim() +
        "/invoice/" +
        generate_invoice.Invoice.Id +
        "/send?sendTo=" +
        personal_info.email;
      const cust_data = {};

      const response = await axios.post(
        "https://node.mumber.com/send_invoice",
        {
          url: create_cust_url,
          token: auth_token,
        }
      );

      return response.data;
    } catch (error) {
      // alert('Invalid Card details!');
      toast.error("Invalid Card details!", {
        position: toast.POSITION.TOP_CENTER,
      });

      throw new Error("Failed to make payment");
    }
  };

  const generateNextInvoice = async (
    auth_token,
    gateway_customer,
    orderResponse
  ) => {
    try {
      let formattedDate = "";

      if (orderResponse.data[0].plan == "Yearly") {
        const currentDate = new Date();
        currentDate.setDate(currentDate.getDate() + 365);

        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, "0");
        const day = String(currentDate.getDate()).padStart(2, "0");

        formattedDate = `${year}-${month}-${day}`;
      } else {
        const currentDate = new Date();
        currentDate.setDate(currentDate.getDate() + 30);

        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, "0");
        const day = String(currentDate.getDate()).padStart(2, "0");

        formattedDate = `${year}-${month}-${day}`;
      }

      const create_cust_url =
        "https://sandbox-quickbooks.api.intuit.com/v3/company/" +
        gatewaydata.company_id.trim() +
        "/invoice?minorversion=65";
      const cust_data = {
        Line: [
          {
            DetailType: "SalesItemLineDetail",
            Amount: parseFloat(product_fee),
            SalesItemLineDetail: {
              ItemRef: {
                name: "Number Purchased",
                value: "1",
              },
            },
          },
        ],
        CustomerRef: {
          value: gateway_customer.Id,
        },
        DueDate: formattedDate,
      };

      const response = await axios.post(
        "https://node.mumber.com/generate_invoice",
        {
          url: create_cust_url,
          data: cust_data,
          token: auth_token,
        }
      );

      return response.data;
    } catch (error) {
      // alert('Invalid Card details!');
      toast.error("Invalid Card details!", {
        position: toast.POSITION.TOP_CENTER,
      });

      throw new Error("Failed to make payment");
    }
  };

  const generateInvoice = async (auth_token, gateway_customer) => {
    try {
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, "0");
      const day = String(currentDate.getDate()).padStart(2, "0");

      const formattedDate = `${year}-${month}-${day}`;

      const create_cust_url =
        "https://sandbox-quickbooks.api.intuit.com/v3/company/" +
        gatewaydata.company_id.trim() +
        "/invoice?minorversion=65";
      const cust_data = {
        Line: [
          {
            DetailType: "SalesItemLineDetail",
            Amount: parseFloat(product_fee),
            SalesItemLineDetail: {
              ItemRef: {
                name: "Number Purchased",
                value: "1",
              },
            },
          },
        ],
        CustomerRef: {
          value: gateway_customer.Id,
        },
        DueDate: formattedDate,
      };

      const response = await axios.post(
        "https://node.mumber.com/generate_invoice",
        {
          url: create_cust_url,
          data: cust_data,
          token: auth_token,
        }
      );

      return response.data;
    } catch (error) {
      // alert('Invalid Card details!');
      toast.error("Invalid Card details!", {
        position: toast.POSITION.TOP_CENTER,
      });

      throw new Error("Failed to make payment");
    }
  };
  const sellerReceipt = async (
    auth_token,
    paymentResponse,
    gateway_customer
  ) => {
    alert("heelo");
    try {
      console.log(gateway_customer);
      const create_cust_url =
        "https://sandbox-quickbooks.api.intuit.com/v3/company/" +
        gatewaydata.company_id.trim() +
        "/salesreceipt";
      const cust_data = {
        Line: [
          {
            Id: "1",
            LineNum: 1,
            Description: personal_info.First_name,
            Amount: parseFloat(product_fee),
            DetailType: "SalesItemLineDetail",
            SalesItemLineDetail: {
              ItemRef: {
                value: "11",
                name: "Phone number",
              },
              UnitPrice: parseFloat(product_fee),
              Qty: 1,
            },
          },
        ],
        CustomerRef: {
          value: gateway_customer.Id,
          name: personal_info.First_name,
        },
        CreditCardPayment: {
          CreditChargeInfo: {
            ProcessPayment: "true",
          },
          CreditChargeResponse: {
            CCTransId: paymentResponse.id,
          },
        },
        TxnSource: "IntuitPayment",
      };

      console.log(cust_data);

      await axios
        .post("https://node.mumber.com/seller", {
          url: create_cust_url,
          data: cust_data,
          token: auth_token,
        })
        .then((response) => {
          // Handle the response from your server
          console.log(response.data);
        })
        .catch((error) => {
          // Handle any errors
          console.error(error);
        });
    } catch (error) {
      // alert('Invalid Card details!');
      toast.error("Invalid Card details!", {
        position: toast.POSITION.TOP_CENTER,
      });

      throw new Error("Failed to make payment");
    }
  };
  const addCustomer = async (gateway_customer) => {
    const users = window.localStorage.getItem("users");
    const userId = JSON.parse(users)[0].id;
    const formData = new FormData();
    formData.append("email_address", personal_info.email);
    formData.append("country", personal_info.country);
    formData.append("first_name", personal_info.First_name);
    formData.append("last_name", personal_info.Last_name);
    formData.append("street_address", personal_info.street);
    formData.append("town", personal_info.city);
    formData.append("state", personal_info.state);
    formData.append("phoneNumber", personal_info.phoneNumber);
    formData.append("ZIP_Code", personal_info.zip_code);
    formData.append("company_name", personal_info.company_name);
    formData.append("phone", personal_info.phone);
    formData.append("userId", userId);
    formData.append("gateway_customer_id", gateway_customer.Id);

    try {
      const response = await axios.post(
        "https://node.mumber.com/api/addcustomer",
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log("Customer added:", response.data);
      return response;
    } catch (error) {
      throw new Error("Failed to add customer");
    }
  };

  const cart = sessionStorage.getItem("cart");
  const parsedCart = JSON.parse(cart);
  const pro_ids = parsedCart.map((product) => product.prod_id);
  console.log("product ID " + pro_ids);

  const updateProductSoldStatus = async (productId) => {
    try {
      const response = await axios.put(
        `https://node.mumber.com/api/addsoldprod/${pro_ids}`,
        { Is_sold: 1 }
      );

      if (response.data.success) {
        console.log("Product updated successfully");
      } else {
        console.log("Failed to update product");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const productId = pro_ids;

  const getOrderDetails = async (orderResponse) => {
    try {
      const order_response = await axios.get(
        "https://node.mumber.com/api/getOrderDetails/" +
          orderResponse.data.insertId
      );

      console.log("Order response:", order_response.data);
      return order_response.data;
    } catch (error) {
      alert("Something went wrong! Please submit again.");
      throw new Error("Failed to add order");
    }
  };

  const addOrder = async (customerData, products) => {
    console.log(products[0]);
    const users = window.localStorage.getItem("users");
    const userId = JSON.parse(users)[0].id;
    console.log(customerData.id);
    const currentDate = new Date()
      .toLocaleDateString()
      .split("/")
      .reverse()
      .join("-");
    const requestData = {
      name: personal_info.First_name + " " + personal_info.Last_name,
      orderDate: currentDate,
      Status: "Completed",
      Total: product_fee,
      customer_id: customerData.id,
      user_id: userId,
      phone: personal_info.phone,
      plan: products[0].plan,
      productIds: pro_ids,
      email: personal_info.email,
    };

    try {
      const order_response = await axios.post(
        "https://node.mumber.com/api/addorder",
        requestData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log("Order response:", order_response.data);
      return order_response.data;
    } catch (error) {
      alert("Something went wrong! Please submit again.");
      throw new Error("Failed to add order");
    }
  };

  const modalRef = useRef(null);

  const [logined, setlogined] = useState(false);

  const [login_info, setlogin_info] = useState({
    useremail: "",
    password: "",
  });

  const handleloginchange = (event) => {
    setlogin_info({ ...login_info, [event.target.name]: event.target.value });
  };

  const handleLoginSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("email", login_info.useremail);
    formData.append("password", login_info.password);

    axios
      .post("https://node.mumber.com/login", formData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        // alert(res.data);
        if (res.data.Success == 1) {
          window.localStorage.setItem("token", res.data.token);
          window.localStorage.setItem("users", JSON.stringify(res.data.users));
          // alert("logined");
          window.location.reload();
          setlogined(true);
          $("#myModal .btn-close").trigger("click");
        }
      })

      .catch((err) => {
        console.log(err);
        // setErrorMessage('Internal Server Error');
      });
  };

  useEffect(() => {
    if (window.localStorage.getItem("users")) {
      setlogined(true);
    }
  }, [logined]);

  const handleforgotClick = () => {
    window.location.href = `/forgot`;
  };

  const handleregisterClick = () => {
    window.location.href = `/Register`;
  };

  // function handleSlashInsertion(event) {
  //   const input = event.target;
  //   const keyCode = event.keyCode || event.which;

  //   if (keyCode !== 8 && input.value.length === 2) {
  //     input.value += '/';
  //   }
  // }

  function handleSlashInsertion(event) {
    const input = event.target;
    const keyCode = event.keyCode || event.which;
    const key = event.key;

    if (!(keyCode === 8 || (keyCode >= 48 && keyCode <= 57))) {
      event.preventDefault();
      return;
    }

    if (input.value.length === 2 && keyCode !== 8) {
      input.value += "/";
    }
  }

  function phone(event) {
    const input = event.target;
    const keyCode = event.keyCode || event.which;
    const key = event.key;

    if (!(keyCode === 8 || (keyCode >= 48 && keyCode <= 57))) {
      event.preventDefault();
      return;
    }

    if (input.value.length === 3 && keyCode !== 8) {
      input.value = input.value.slice(0, 3) + "-" + input.value.slice(3);
    } else if (input.value.length === 7 && keyCode !== 8) {
      input.value = input.value.slice(0, 7) + "-" + input.value.slice(7);
    }
  }

  function handleKeyDown(event) {
    const numericKeys = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
    const key = event.key;

    if (!(numericKeys.includes(key) || key === "Backspace")) {
      event.preventDefault();
    }
  }

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(true);
  };

  const [isRecaptchaVerified, setIsRecaptchaVerified] = useState(false);

  const handleRecaptchaChange = (value) => {
    console.log("reCAPTCHA value:", value);
    setIsRecaptchaVerified(true);
  };

  /////////////////////////////////////////////////////////////////////////////////////////////

  const currDate = new Date()
    .toLocaleDateString()
    .split("/")
    .reverse()
    .join("-");
  const users = window.localStorage.getItem("users");
  const userId = JSON.parse(users)[0].id;
  const [formData, setFormData] = useState({
    name: "",
    orderDate: currDate,
    Status: "pending",
    Total: products[0].fees,
    customer_id: "",
    user_id: userId,
    productIds: pro_ids,
    plan: products[0].plan,
    first_name: "",
    last_name: "",
    company_name: "",
    street_address: "",
    town: "",
    state: "",
    ZIP_Code: "",
    phone: "",
    email_address: "",
    userId: userId,
    gateway_customer_id: userId,
  });

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const customerResponse = await axios.post(
        "https://node.mumber.com/api/addcustomer",
        formData
      );

      const customerData = customerResponse.data;

      console.log("customer added successfully", customerData);

      // Use the updated state directly
      const orderResponse = await axios.post(
        "https://node.mumber.com/api/addorder",
        {
          ...formData,
          customer_id: customerData.id,
          name: formData.first_name,
        }
      );

      const orderData = orderResponse.data;

      console.log("Order added successfully", orderData);

      // Clear the form or do other necessary actions
      // ...

      toast.success("Order Placed", { position: toast.POSITION.TOP_CENTER });

      sessionStorage.removeItem("cart");
      sessionStorage.removeItem("cart_phones");

      navigate("/Thankyou");
    } catch (error) {
      console.error("Error submitting form", error);
    }
  };

  return (
    <section className="checkout_main">
      <div className="container">
        <div className="row">
          <h3 className="text-dark pt-5 text-center">Check Out</h3>
          {/* <!-- coulmn one start --> */}
          <div className="col-lg-6 m-auto">
            <div className="mt-md-5 mt-5 mb-4 mb-md-5 mx-md-5 mx-lg-0">
              <form id="form_box contact_form  " onSubmit={handleSubmit}>
                <h4 className="py-4">Contact</h4>

                <div className="mb-3 checkout_text">
                  <label for="exampleInputPassword1" className="form-label">
                    Email
                  </label>
                  <input
                    required
                    type="email"
                    name="email_address"
                    className="form-control py-2"
                    id="user_email"
                    placeholder="Enter an email"
                    value={formData.email_address}
                    onChange={handleInputChange}
                  />
                </div>
                <h4 className="py-4">Billing Address</h4>
                <div className="mb-3 text_form checkout_text">
                  <label for="exampleInputPassword1" className="form-label">
                    Country / Region
                  </label>

                  <input
                    required
                    type="text"
                    name="country"
                    className="form-control py-2"
                    id="user_country"
                    value="United States"
                    // onChange={handleInputChange}
                  />
                </div>
                <div className="d-inline-flex m-auto checkout_form w-100">
                  <div className="w-50 me-3 checkout_text">
                    <label
                      for="exampleInputPassword1"
                      className="form-label d-block"
                    >
                      First name
                    </label>
                    <input
                      required
                      type="text"
                      className="p-2 border rounded-3"
                      name="first_name"
                      id="user_first_name"
                      placeholder="John"
                      value={formData.first_name}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="w-50 checkout_text">
                    <label
                      for="exampleInputPassword1"
                      className="form-label d-block"
                    >
                      Last name
                    </label>
                    <input
                      required
                      type="text"
                      className="p-2 border rounded-3"
                      name="last_name"
                      id="user_last_name"
                      placeholder="Dough"
                      value={formData.last_name}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>

                <div className="mb-3 checkout_text">
                  <label
                    for="exampleInputPassword1"
                    className="form-label my-2"
                  >
                    Company Name
                  </label>
                  <input
                    type="text"
                    name="company_name"
                    className="form-control py-2"
                    id="company_name"
                    placeholder="Company Name"
                    value={formData.company_name}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="mb-3 checkout_text">
                  <label
                    for="exampleInputPassword1"
                    className="form-label my-2"
                  >
                    Phone Number
                  </label>
                  <input
                    required
                    type="text"
                    inputMode="numeric"
                    pattern="[0-9]*"
                    name="phone"
                    maxlength="12"
                    className="form-control py-2"
                    id="user_Phone"
                    value={formData.phone}
                    placeholder="Phone number"
                    onChange={handleInputChange}
                  />
                </div>
                <div className="mb-3 checkout_text">
                  <label
                    for="exampleInputPassword1"
                    className="form-label my-2"
                  >
                    Street address
                  </label>
                  <input
                    required
                    type="text"
                    name="street_address"
                    className="form-control py-2"
                    id="user_street"
                    placeholder="123 Oak St."
                    value={formData.street_address}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="d-inline-flex m-auto checkout_form w-100 checkout_text">
                  <div className="w-50 me-3">
                    <label
                      for="exampleInputPassword1"
                      className="form-label d-block"
                    >
                      City
                    </label>
                    <input
                      required
                      type="text"
                      className="p-2 border rounded-3"
                      name="town"
                      id="user_city"
                      placeholder="Townsville"
                      value={formData.town}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="w-50 me-3">
                    <label
                      for="exampleInputPassword1"
                      className="form-label d-block"
                    >
                      State
                    </label>
                    <input
                      required
                      type="text"
                      className="p-2 border rounded-3"
                      name="state"
                      id="user_state"
                      placeholder="NY"
                      value={formData.state}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="w-50">
                    <label
                      for="exampleInputPassword1"
                      className="form-label d-block"
                    >
                      Zip Code
                    </label>
                    <input
                      required
                      type="text"
                      inputMode="numeric"
                      pattern="[0-9]*"
                      maxlength="6"
                      className="p-2 border rounded-3 "
                      name="ZIP_Code"
                      id="user_zip"
                      placeholder="12345"
                      value={formData.ZIP_Code}
                      onChange={handleInputChange}
                      onKeyDown={handleKeyDown}
                    />

                    {/* <input
                      type="hidden"
                      className="p-2 border rounded-3"
                      name="cart_total"
                      id="Last_name"
                      placeholder="12345"
                      value={product_fee}
                    /> */}
                  </div>
                </div>
                <div className="mt-2">
                  <input
                    required
                    type="checkbox"
                    name="terms"
                    id="terms"
                    class="btn btn-primary"
                    data-bs-toggle="modal"
                    href="#exampleModalToggle"
                    role="button"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                    style={{ marginTop: "-2px" }}
                  />

                  <span
                    style={{ color: "rgb(133 133 133)", paddingLeft: "7px" }}
                  >
                    I accept{" "}
                    <span
                      data-bs-toggle="modal"
                      href="#exampleModalToggle"
                      role="button"
                      style={{ color: "#0d6efd" }}
                    >
                      terms and conditions
                    </span>
                  </span>
                </div>
                <div>
                  <button type="submit" className="btn btn-primary mt-2">
                    Complete Order
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Checkout2></Checkout2>

      <div
        class="modal  fade"
        id="exampleModalToggle"
        aria-hidden="true"
        aria-labelledby="exampleModalToggleLabel"
        tabindex="-1"
      >
        <div class="modal-dialog modal-dialog-centered modal-xl">
          <div class="modal-content">
            <div class="modal-header">
              <h5
                class="modal-title"
                style={{ width: "100%" }}
                id="exampleModalToggleLabel"
              >
                <h3
                  style={{
                    textAlign: "center",
                    fontSize: "40px",
                    fontWeight: "600",
                    color: "#0d6efd",
                  }}
                  className="mt-4"
                >
                  Mumber Terms & Conditions
                </h3>
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div className="container">
                <div style={{ textAlign: "center" }}>
                  {/* <h4 style={{ color: "black" }} className='mt-5 mb-2'>  Subtotal: ${product_fee} </h4> */}
                  <h5 className="mt-4">
                    *Please agree to the License Term below.
                  </h5>
                  <p style={{ color: "black" }}>
                    {" "}
                    <i>
                      {" "}
                      The item selected at checkout shall hereby be referred to
                      as “Telephone Number”. The name entered in the Name on
                      Card field shall hereby be referred to as “Licensee”. The
                      subscriber of record of Telephone Number shall here in be
                      referred to as “Licensor”.{" "}
                    </i>
                  </p>
                  {/* <h5 className="mt-2">   *Please agree to the License Terms
                      This telephone number is being licensed.
                      <br></br>You will not own it nor be able to port the number away.*</h5> */}
                </div>

                <p style={{ color: "black" }} className="mt-3 d-flex">
                  <div>
                    {/* <input type="checkbox" name="terms" id="terms2" />  */}
                  </div>
                  <div className="ps-2">
                    <b> There is no ownership or porting away: </b>
                    The Licensee understands and agrees that at no time shall
                    they have any ownership, leasehold, or proprietary interest
                    in the telephone number. The Licensee has no rights to
                    become the subscriber of record for the telephone number, to
                    port the Telephone Number to a different carrier, to submit
                    any letter of authorization to any carrier or Resporg.Any
                    and all rights to the Telephone Number will be retained the
                    subscriber of record for the Telephone Number during the
                    term and afterwards.
                  </div>
                </p>

                <p style={{ color: "black" }} className="mt-3 d-flex">
                  <div>
                    {/* <input type="checkbox" name="terms" id="terms3" />  */}
                  </div>
                  <div className="ps-2">
                    <b>Monthly fees:</b> The licensee shall agree to pay:{" "}
                    <br></br>
                    <ol type="1" style={{ marginLeft: "20px" }}>
                      <li>
                        The monthly license fee as displayed at the time of
                        checkout.
                      </li>

                      <li>
                        Licensee warrants, represents, acknowledges, and agrees
                        that Licensee shall be responsible to pay late fees is
                        payment is not collected within 15 days of the due date.
                        Late fees shall be equal to 25% of the license fee.
                      </li>
                    </ol>
                  </div>
                </p>

                <p style={{ color: "black" }} className="mt-3 d-flex">
                  <div>
                    {/* <input type="checkbox" name="terms" id="terms4" />  */}
                  </div>
                  <div className="ps-2">
                    {" "}
                    <b>License duration:</b> The license terms are effective on
                    the date of Telephone Number activation (the "Effective
                    Date"). The license is month-to-month and can be cancelled
                    at any time by the licensee, or terminated by Licensor if
                    licensee's account is in default due to non-payment (or in
                    violation of terms of service).
                  </div>
                </p>

                <p style={{ color: "black" }} className="mt-3 d-flex">
                  <div>
                    {/* <input type="checkbox" name="terms" id="terms5" />  */}
                  </div>
                  <div className="ps-2">
                    {" "}
                    <b> Reconnection:</b> If the License Agreement is terminated
                    after 30 days of non-payment, there is no guarantee of
                    reconnection or continuance of license. It is the sole
                    responsibility of the Licensee to maintain its account in
                    good standing.
                  </div>
                </p>

                <p style={{ color: "black" }} className="mt-3">
                  <b>
                    {" "}
                    By checking the boxes above, I acknowledge that I have read
                    and agree to these terms for licensing my Telephone Number.
                    These terms can also be found in our complete {" "}
                    <span style={{ color: "#d5aa00" }}>terms of service.</span>
                  </b>
                </p>

                <br></br>

                <h3 className="text-center">Terms of Service</h3>
                <br></br>

                <p style={{ textAlign: "center", color: "black" }}>
                  <u>PRELIMINARY STATEMENTS</u>
                </p>

                <p style={{ color: "black" }}>
                  This License is entered into with reference to the following
                  facts:
                </p>

                <ul type="a" style={{ marginLeft: "20px" }}>
                  <li>
                   1. LICENSOR has the exclusive right, subject to all applicable
                    rules, obligations, limitations, and conditions under
                    applicable tariffs and statutory and common law to the use
                    of Telephone Number.
                  </li>

                  <li>
                   2. LICENSOR wishes to license to LICENSEE its right to use
                    Telephone Number to advertise, market, promote and otherwise
                    commercially exploit the same under the terms of this
                    License.
                  </li>
                  <li>
                   3. LICENSEE wishes to use the stated Telephone Number for
                    lawful marketing purposes.
                  </li>
                </ul>

                <br></br>

                <p style={{ color: "black" }}>
                  NOW, THEREFORE, in consideration of the mutual promises
                  contained in this Agreement, the Parties established this
                  relationship on the terms and conditions that follow:
                </p>
                <br></br>

                <ol type="1" style={{ marginLeft: "20px" }}>
                  <li>
                    <em>
                      <b>
                        <u>Subject Matter</u>
                      </b>
                    </em>
                    <p style={{ color: "black" }}>
                      The subject matter of this License Agreement is:
                    </p>

                    <ul type="a" style={{ marginLeft: "20px" }}>
                      <li>
                       1. The right to use Telephone Number, subject only to all
                        rules, obligations, limitations, and conditions under
                        applicable tariffs, statutory and common law copyright
                        or trademark laws as may apply, and any other laws or
                        regulations, including those promulgated by agencies
                        having jurisdiction of the subject matter.
                      </li>
                      <li>
                       2. The right to use Telephone Number is more specifically
                        defined as the right to receive telephone calls dialed
                        to the Telephone Number from an Area Code(s) phone
                        number within the continental United States of America
                        during the term of the License Agreement, commencing
                        when the Telephone Number becomes operative at Location.
                      </li>
                    </ul>
                  </li>

                  <li>
                    <em>
                      <b>
                        <u>Duties of the Parties</u>
                      </b>
                    </em>

                    <p style={{ color: "black" }}>
                      In consideration of the mutual covenants and restrictions
                      contained herein, the parties promise and agree as
                      follows:
                    </p>
                    <ol type="1" style={{ marginLeft: "20px" }}>
                      <li>
                        LICENSOR agrees to cause telephone calls connected to
                        Telephone Number from a number that starts with one of
                        the Area Code(s) within the continental United States of
                        America, commencing when the Telephone Number becomes
                        operational at Location and continuing for the duration
                        of this License Agreement, to be routed via available
                        telecommunications facilities across a Local Area
                        Code(s) telephone number, designated by LICENSEE,
                        terminated at Location.
                      </li>

                      <li>
                        LICENSOR agrees to cause such routing to become
                        operational at Location at the earliest possible date,
                        as determined by the telecommunications carrier,
                        following execution and return of this License
                        Agreement.
                      </li>

                      <li>
                        LICENSOR reserves the right, at its sole and exclusive
                        discretion, to utilize the services of any
                        telecommunications carrier, it so chooses, during the
                        term of this License Agreement.
                      </li>

                      <li>
                        LICENSEE agrees to designate, and maintain the
                        designation of, the Local Area Code(s) telephone
                        number(s) across which Telephone Number Area Code(s)
                        calls will be routed during the License Agreement.
                        LICENSEE further agrees to provide compatible telephone
                        line(s), telephone equipment and personnel required to
                        receive the telephone calls routed across the designated
                        Local Area Code(s) telephone number(s) to Location.
                        <ol type="1" style={{ marginLeft: "20px" }}>
                          <li>
                            Apart from the rights and duties imposed upon the
                            parties herein, LICENSOR shall have no control over,
                            nor knowledge of the conduct of LICENSEE'S business.
                            Accordingly, LICENSEE agrees to indemnify and hold
                            LICENSOR harmless, including but not limited to any
                            reasonable attorney fees incurred by LICENSOR, and
                            defend LICENSOR from any and all actions arising
                            from or pertaining to LICENSEE'S use of Telephone
                            Number. In no event shall either party be liable in
                            contract, tort, or otherwise for incidental,
                            consequential, specific or indirect damages,
                            including, without limitation, lost business
                            profits, even if that party has been advised of the
                            possibility of same, arising out of its acts or
                            omissions in connection with the License.
                          </li>

                          <li>
                            Neither party shall be liable for any delay or
                            failure to perform its obligations hereunder due to
                            any case beyond its reasonable control, including,
                            but not limited to, failure or interruption of the
                            telecommunications or internet carriers to provide
                            adequate service, labor disputes, fire, accident,
                            act of the public enemy, war, rebellion,
                            insurrection, sabotage, transportation delay,
                            shortage of raw material, energy of machinery, act
                            of God, government or the judiciary, provided
                            however that nothing contained herein shall be
                            construed to relieve LICENSEE of his/its obligation
                            to make all payments due hereunder as they become
                            due and payable.
                          </li>
                        </ol>
                      </li>

                      <li>
                        This License creates no agency, contract of employment,
                        partnership, association or joint venture of any
                        description between the parties. The parties intend and
                        agree that no right of continued or vested use of
                        Telephone Number not specifically and expressly created
                        herein shall be created or extended, based upon any
                        general principal of estoppel or any other general
                        principal of law.
                      </li>

                      <li>
                        LICENSEE agrees to notify LICENSOR during normal Eastern
                        Standard Time business hours of any malfunction in the
                        Telephone Number or routing systems which affect
                        LICENSEE'S use of Telephone Number at Location.
                      </li>

                      <li>
                        LICENSEE acknowledges and agrees that this right to use
                        Telephone Number is subject to all applicable tariffs,
                        laws and rules including and particularly those in
                        relation to non-payment for services, abuse of services,
                        fraud and shortage of network facilities or components.
                        LICENSEE shall be subject to all terms and conditions of
                        any underlying carrier agreements of LICENSOR.
                      </li>
                    </ol>
                  </li>

                  <li>
                    <em>
                      <b>
                        <u>Compensation Payable to LICENSOR</u>
                      </b>
                    </em>

                    <ol type="1" style={{ marginLeft: "20px" }}>
                      <li>
                        LICENSEE agrees to pay LICENSOR a Monthly License Fee in
                        the amount specified at checkout (the "License Fee").
                      </li>

                      <li>
                        As consideration for the rights and license granted
                        hereunder, LICENSEE agrees to pay LICENSOR the License
                        fee every month on the anniversary of the checkout date.
                        Any payments not received by the 15th day after due
                        shall incur a late fee equal to 25% of the License fee
                        and shall be considered a default.
                      </li>

                      <li>
                        LICENSEE agrees to complete a credit application for the
                        telecommunications carrier or LICENSOR so that the
                        Telephone Number relating to Area Code(s), phone charges
                        may be billed directly to LICENSEE at LICENSOR's option.
                        Further, LICENSEE agrees to pay all phone charges i.e.
                        all telephone charges billed by or through the
                        telecommunications carrier (including time and usage,
                        line charge and long distance), together with all
                        Federal and state taxes, surcharges and regulatory fees
                        of any description (the "Billed Charges") which are
                        attributable to Telephone Number and LICENSEE'S use of
                        Telephone Number at Location or otherwise as it relates
                        to Area Code(s). LICENSEE acknowledges that if said
                        credit application is required, that it may be required
                        to be completed before service can begin and further
                        agrees to pay all said telecommunications charged upon
                        receipt.
                      </li>

                      <li>
                        LICENSEE agrees to pay, and hold LICENSOR harmless from,
                        any claims for any and all charges imposed by the Local
                        Area Code(s), i.e. LICENSEE'S local phone company which
                        covers Location, including but not limited to, line
                        installation, line repair, line maintenance, line
                        switching, advanced line gestures, monthly flat rate
                        charges, usage charges, surcharges, deaf and disabled
                        fund charges, local, state, and Federal taxes or any
                        other fees whatsoever imposed in relation to the
                        designated Local Area Code(s) telephone number(s) across
                        which Telephone Number is routed at Location.
                      </li>
                    </ol>
                  </li>

                  <li>
                    <em>
                      <b>
                        <u>Designation of Local Area Telephone Number</u>
                      </b>
                    </em>
                    <p style={{ color: "black" }}>
                      The designated Local Area Code(s) shall mean the place or
                      places designated from time to time in writing by Licensee
                      as the location to which calls to the Number shall be
                      routed to (“Local Area Code”).
                    </p>
                  </li>

                  <li>
                    <em>
                      <b>
                        <u>Effective Date and Term of License</u>
                      </b>
                    </em>
                    <p style={{ color: "black" }}>
                      This License shall be effective on the date of checkout.
                      The license shall continue on a month to month basis until
                      Licensee requests the agreement be terminated by providing
                      written notice 30 days in advance. Licensor has the right
                      to terminate the agreement at any time if Licensee is in
                      default.
                    </p>
                  </li>

                  <li>
                    <em>
                      <b>
                        <u>Termination</u>
                      </b>
                    </em>
                    <ol type="1" style={{ marginLeft: "20px" }}>
                      <li>
                        In the event of LICENSEE'S default hereunder, this
                        License may be terminated immediately, or at any future
                        time, by LICENSOR, and termination shall be effective
                        from the date of issuance by LICENSOR of such notice.
                      </li>

                      <li>
                        Telecommunications and web address services may be
                        terminated by LICENSOR at any time co-incident with or
                        following the termination of this License, or because of
                        a violation or alleged violation of any
                        telecommunications rules or regulations, fraud, shortage
                        of network facilities and components or of any other
                        tariffs, laws, rules, or regulations by any party or as
                        a result of this License.
                      </li>

                      <li>
                        Upon termination for any reason LICENSOR retains all
                        rights to use, direct, control, point and manipulate
                        Telephone Number or any derivatives thereof and LICENSEE
                        will retain no rights or claims.
                      </li>

                      <li>
                        Licensee may terminate the agreement by providing
                        Licensor notice of their intention to cancel. Licensor
                        will cancel the agreement provided that the Licensee is
                        not in default and Licensee provides 30 day notice of
                        their intention to cancel.
                      </li>
                    </ol>
                  </li>

                  <li>
                    <em>
                      <b>
                        <u>Limitation of Liability</u>
                      </b>
                    </em>
                    <p style={{ color: "black" }}>
                      LICENSEE acknowledges that no shareholder, director,
                      officer, or agent of LICENSOR shall be liable hereunder to
                      LICENSEE in tort, contract, or otherwise for the failure,
                      either actual or alleged, of LICENSOR to perform its
                      obligations hereunder, such responsibility and liability
                      to be limited to that of LICENSOR and any such liability
                      of LICENSOR shall be limited to one- twelfth of the yearly
                      License Fee related to the Telephone Number.
                    </p>
                  </li>

                  <li>
                    <em>
                      <b>
                        <u>Non-Assignability</u>
                      </b>
                    </em>
                    <p style={{ color: "black" }}>
                      The rights and duties imposed under this License are
                      exclusive to the LICENSEE, are non-divisible,
                      non-assignable, and may not be sub-licensed, transferred
                      or assigned by LICENSEE, to any other entity without the
                      advance written consent of LICENSOR. Nothing herein shall
                      prevent LICENSOR from assigning its rights hereunder.
                    </p>
                  </li>

                  <li>
                    <em>
                      <b>
                        <u>Governing Law</u>
                      </b>
                    </em>
                    <p style={{ color: "black" }}>
                      This License shall be governed by applicable Federal
                      and/or State laws, rules, communication tariffs and rules
                      and the laws of the State of New York.
                    </p>
                  </li>

                  <li>
                    <em>
                      <b>
                        <u>Arbitration; Submission to Jurisdiction</u>
                      </b>
                    </em>
                    <p style={{ color: "black" }}>
                      The parties agree that any dispute arising between them
                      arising out of this License or the relationship herein
                      created, which cannot be resolved amicably, shall be
                      submitted to the American Arbitration Association in
                      Buffalo, New York before any suit is filed in a court of
                      competent jurisdiction, which the parties agree will be in
                      Buffalo, New York. The parties further agree to be bound
                      by the decision of the arbitrator and that a judgment on
                      the award rendered by the arbitrator, which may include
                      administrative fees costs and attorney fees, may be
                      enforced in any court having jurisdiction hereof. The
                      parties may agree to enter into mediation at any time with
                      the cost of the mediator being divided equally.
                    </p>
                  </li>

                  <li>
                    <em>
                      <b>
                        <u>Miscellaneous</u>
                      </b>
                    </em>
                    <ol type="1" style={{ marginLeft: "20px" }}>
                      <li>
                        This License contains the entire understanding between
                        the parties with respect to its subject matter and
                        supersedes all prior understandings and agreements, if
                        any, between and among them.
                      </li>

                      <li>
                        This License shall not be considered modified by any
                        course of dealing between the parties and may only be
                        amended by a written amendment to the License executed
                        by both of the parties.
                      </li>

                      <li>
                        This License shall be binding upon and inure to the
                        benefit of the parties and their respective legal
                        representatives, heirs, devisees, executors,
                        administrators, assigns and successors.
                      </li>

                      <li>
                        Each of the parties shall promptly execute and deliver
                        all documents, provide all information, and take or
                        forebear from all actions as may be reasonably necessary
                        or appropriate to carry out the purposes of this
                        License.
                      </li>

                      <li>
                        This License may be executed in one or more
                        counterparts, all of which shall be taken to be on and
                        the same instrument and all of which shall have the same
                        force and effect as if all of the parties have executed
                        the same counterpart, and any fully executed License may
                        be considered an original.
                      </li>

                      <li>
                        All notices shall be in writing and shall be deemed
                        effective when given by personal delivery or courier or
                        when sent by certified or registered mail to the last
                        known address of the party. Notice upon one individual
                        or corporate entity of LICENSEE shall be considered and
                        construed to have given notice to all of LICENSEE.
                      </li>

                      <li>
                        All pronouns and any variations therefore shall be
                        deemed to refer to the masculine, feminine, neuter,
                        singular, or plural, as the identity of the person or
                        persons may require.
                      </li>
                      <li>
                        No failure by any party to insist upon the strict
                        performance of any covenant, duty, condition of this
                        License to exercise any right of remedy consequent upon
                        a breach thereof shall constitute a waiver of any such
                        breach or of such or any other covenant, term or
                        condition.
                      </li>

                      <li>
                        The License Fee on the anniversary of the first term
                        start date will have an annual increase in the amount of
                        seven percent to account for inflation and asset
                        appreciation.
                      </li>

                      <li>
                        The parties hereto intend and believe that each
                        provision of this License complies with all applicable
                        local, state and federal laws and judicial decisions
                        etc. However, if any provision or provisions, or if any
                        portion of any provision, in this License, is found by a
                        court of law or alleged by a government entity to be in
                        violation of any applicable local, state or federal
                        ordinance, statue, law, rule, administrative or judicial
                        decision, or public policy or such court, or government
                        entity should declare such portion, provision or
                        provisions of this License to be illegal, invalid,
                        unlawful, void or unenforceable as written, then it is
                        the intent of the parties hereto that such portion,
                        provision or provisions shall be given force to the
                        fullest extent that they are legal, valid, and
                        enforceable, that the remainder of this License shall be
                        construed as if such illegal, invalid, unlawful, void or
                        unenforceable, provision or provisions were not
                        contained herein except as it relates to compensation to
                        LICENSOR, and that the rights, obligations and interest
                        of the parties under the remainder of this License shall
                        continue in full force and effect, except that such
                        construction shall not reduce or modify compensation to
                        LICENSOR and shall apply to the rights and obligations
                        of both of the parties and not just the one affected in
                        the particular determination, proceeding or allegation.
                      </li>

                      <li>
                        All advertising, use, promotion and marketing done using
                        Telephone Number shall comply with all applicable laws,
                        statues and Cannons of Ethics.
                      </li>

                      <li>
                        LICENSEE shall take no action which damages the rights,
                        goodwill or reputation of Telephone Number, and LICENSOR
                        use, rights, control and/or "ownership" of same.
                      </li>

                      <li>
                        LICENSEE shall not attempt to trademark, service mark or
                        copyright Telephone Number or any other similar
                        combination of number sequence with or without dashes or
                        other punctuation.
                      </li>

                      <li>
                        LICENSEE shall not disclose any portion of this License
                        to any third party without the advance written consent
                        of LICENSOR.
                      </li>

                      <li>
                        The Preliminary statements of this License are an
                        integral part of this License.
                      </li>
                    </ol>
                  </li>
                </ol>
              </div>
            </div>
            {/* <div class="modal-footer">
                <button class="btn btn-primary" data-bs-target="#exampleModalToggle2" data-bs-toggle="modal" data-bs-dismiss="modal">Open second modal</button>
              </div> */}
          </div>
        </div>
      </div>
      <div
        class="modal fade"
        id="exampleModalToggle2"
        aria-hidden="true"
        aria-labelledby="exampleModalToggleLabel2"
        tabindex="-1"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalToggleLabel2">
                Modal 2
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              Hide this modal and show the first with the button below.
            </div>
            <div class="modal-footer">
              <button
                class="btn btn-primary"
                data-bs-target="#exampleModalToggle"
                data-bs-toggle="modal"
                data-bs-dismiss="modal"
              >
                Back to first
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* <a class="btn btn-primary" data-bs-toggle="modal" href="#exampleModalToggle" role="button">Open first modal</a> */}

      <div className="modal" id="myModal" ref={modalRef}>
        <div className="modal-dialog">
          <div className="modal-content">
            {/* <!-- Modal Header --> */}
            <div className="container-fluid my-2">
              <div className="modal-header">
                <img src="assets/images/Unionblack.png" alt="" />
                <h4
                  className="modal-title text-muted ps-lg-3 fs-2"
                  style={{
                    marginLeft: "75px",
                  }}
                >
                  SIGN-IN
                </h4>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                ></button>
              </div>

              {/* <!-- Modal body --> */}

              <form id="login_form" onSubmit={handleLoginSubmit}>
                <div className="mb-3">
                  <label for="exampleInputEmail1" className="form-label">
                    Email*
                  </label>
                  <input
                    onChange={handleloginchange}
                    type="email"
                    name="useremail"
                    className="form-control border border-1 p-3"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="jonsnow@gmail.com"
                  />
                </div>
                <div className="mb-3">
                  <label for="exampleInputPassword1" className="form-label">
                    Password*
                  </label>
                  <input
                    onChange={handleloginchange}
                    type="password"
                    name="password"
                    className="form-control border border-1 p-3 dot_place"
                    id="exampleInputPassword1"
                    placeholder="............ "
                  />
                </div>
                <div className="d-flex justify-content-between">
                  <div>
                    <input type="checkbox" />
                    <span>Remember me</span>
                  </div>
                  <Link to="/forgot" onClick={handleforgotClick}>
                    <span style={{ color: "#3374ff" }}>Forgot Password?</span>
                  </Link>
                </div>
                <button
                  type="submit"
                  className="btn-primaris sign_in_btn mt-4 fw-semibold"
                >
                  Sign in
                </button>
                <div className="text-center py-3">or</div>
                <Link to="/register" onClick={handleregisterClick}>
                  <button className="create_an_btn mb-4 border border-1">
                    Create an Account
                  </button>
                </Link>
              </form>
            </div>

            {/* <!-- Modal footer --> */}
          </div>
        </div>
        <ToastContainer />
      </div>
    </section>
  );
};

export default Checkout;
