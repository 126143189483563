import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// import { toast } from "react-toastify";
import axios from "axios";

function SearchedNumbers() {
  const [data, setData] = useState([]);

  const loadData = async () => {
    try {
      const response = await axios.get(
        "https://node.mumber.com/api/allavailproducts"
      );
      setData(response.data.data);
    } catch (error) {
      console.log(error);
      // toast.error("Failed to fetch data");
    }
  };

  useEffect(() => {
    loadData();
    // window.location.reload();
  }, []);

  return (
    <div>
      <section className="banner-area pages-banner">
        <div className="container">
          <div className="row banner-row align-items-center">
            <div className="col-lg-6">
              <div className="banner-heading">
                <h2>
                  What was <br />
                  <span>searched</span>
                </h2>
                <p>
                  Find the perfect easy to remember phone number for your
                  business.
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="man-img">
                <img src="assets/images/search.png" alt="manImg" />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*============================================================
                    End banner area
    =============================================================*/}
      {/*============================================================
                    start search bar area
    =============================================================*/}
      <div className="search-bar-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <form id="quick-request-submit">
                <div className="reqest-a-product">
                  <div className="search-icon">
                    <img
                      loading="lazy"
                      src="assets/images/Ellipse.png"
                      alt="ellipse"
                    />
                  </div>
                  <input
                    type="text"
                    placeholder="Search here by: Phone Number, Area Code, Keyword, State, or City "
                    name="phone"
                    id="quick-request-input"
                  />
                  <button className="btn-primaris" type="submit">
                    Search
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/*============================================================
                    End search bar area
    =============================================================*/}
      {/*============================================================
                    start searched result area
    =============================================================*/}
      <section className="search-result-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="search-result-head">
                <h2>
                  searched <span>results</span>
                </h2>
                <div className="search-menu-numbers">
                  <div className="search-menu">
                    <ul>
                      <li>
                        <a href="#" className="active">
                          All
                        </a>
                      </li>
                      <li>
                        <a href="#">Top 3</a>
                      </li>
                      <li>
                        <a href="#">Low Price Firstly</a>
                      </li>
                      <li>
                        <a href="#">Hight Price Firstly</a>
                      </li>
                      <li>
                        <a href="#">Newest</a>
                      </li>
                      <li>
                        <a href="#">Oldest</a>
                      </li>
                    </ul>
                  </div>
                  <div className="search-found-numbers">
                    <span>
                      Found:{" "}
                      <label>
                        <b>134</b>
                      </label>{" "}
                      results
                    </span>
                  </div>
                </div>
              </div>
              <div className="search-result-content">
                <div className="search-table">
                  <table className="table">
                    <tbody>
                      {data.map((item) => {
                        return (
                          <tr key={item.Id}>
                            <td className="search-serial-no">01</td>
                            <td className="numbers">{item.phone_no}</td>
                            <td className="license">License fee</td>
                            <td className="price">$9.99</td>
                            <td className="moreinfo-button">
                              <a href="#">More info</a>
                            </td>
                            <td className="love-icon">
                              <i className="fas fa-heart" />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <div className="button">
                  <a href="#">Load more</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default SearchedNumbers;
