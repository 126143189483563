import React, { useState, useEffect } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import Auth_nav from "./nav_auth_buttons";
import { BsFillCartFill } from "react-icons/bs";

function Navigation(props) {
  const users = sessionStorage.getItem("users");
  const usertoken = sessionStorage.getItem("token");
  const [notificationCount, setNotificationCount] = useState(0);

  if (usertoken) {
    const parsedUsers = JSON.parse(users);
    const ids = parsedUsers.map((user) => user.id);
  }

  const [cartItem, setCartItems] = useState(
    JSON.parse(sessionStorage.getItem("cart"))
      ? JSON.parse(sessionStorage.getItem("cart"))
      : []
  );
  console.log(cartItem);

  return (
    <>
      {" "}
      {/*[if lte IE 9]>
  <p class="browserupgrade">You are using an <strong>outdated</strong> browser. Please <a href="https://browsehappy.com/">upgrade your browser</a> to improve your experience and security.</p>
<![endif]*/}
      {/*============================================================
                      Start header area
=============================================================*/}
      <header className="header-area header_shopping">
        <div className="container">
          <div className="header-top">
            <div className="logo d-flex justify-content-between align-items-center">
              <div className="logo-img">
                <NavLink to="/">
                  <img
                    loading="lazy"
                    src="/assets/images/logo.png"
                    alt="logo"
                  />
                </NavLink>
              </div>
              {/* style={{marginLeft:"51%"}} */}
              <div className="menu-area menu">
                <div className="menubar-area">
                  <div
                    className="close-bar close-area"
                    style={{ display: "none" }}
                  >
                    <div className="close">
                      <div className="close-icon" />
                    </div>
                  </div>
                  <ul className="d-flex">
                    <li>
                      <NavLink to="/Blog">Blog</NavLink>
                    </li>
                    <li>
                      <NavLink to="/FAQ">FAQ</NavLink>
                    </li>
                    <li className="hide_on_resp">
                      <NavLink to="/Map" style={{ border: "0px" }}>
                        Map
                      </NavLink>
                    </li>
                    <li style={{ marginRight: "5px" }} className="hide_on_desk">
                      <NavLink to="/Favourites">
                        <span>
                          <img
                            className="hurt-love"
                            loading="lazy"
                            src="/assets/images/love.png"
                            alt="love"
                          />
                        </span>{" "}
                        Favorite
                      </NavLink>
                    </li>

                    <li className="hide_on_desk">
                      <NavLink to="/Contact">Contact Sales</NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="header-buttons main-menu">
              <ul className="d-flex justify-content-between align-items-center">
                {/* style={{paddingRight:"49px"}} */}
                <li className="hide_on_resp" style={{ marginRight: "5px" }}>
                  <NavLink to="/Favourites">
                    <span>
                      <img
                        className="hurt-love"
                        loading="lazy"
                        src="/assets/images/love.png"
                        alt="love"
                      />
                    </span>{" "}
                    Favorite
                  </NavLink>
                </li>

                <li className="hide_on_resp">
                  <NavLink to="/Contact">Contact Sales</NavLink>
                </li>

                <Auth_nav></Auth_nav>

                <li>
                  <NavLink to="/Cart">
                    {" "}
                    <BsFillCartFill style={{ marginTop: "-5px" }} />
                    <span className="cart_count">{cartItem.length}</span>
                  </NavLink>
                </li>

                <li></li>
              </ul>

              <div className="bar-icon" style={{ display: "none" }}>
                <i className="fas fa-bars" />
              </div>
            </div>
          </div>
        </div>
      </header>
      {/*============================================================
                      End header area
=============================================================*/}
    </>
  );
}

export default Navigation;
