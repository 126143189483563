import React, { useState, useEffect } from "react";
import $ from "jquery";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { slice } from "lodash";
import axios from "axios";
import { useParams } from "react-router-dom";
import Preloader from "../Preloader";

const Subscription = () => {
  const users = window.localStorage.getItem("users");
  const userId = JSON.parse(users)[0].id;

  const [isLoading, setIsLoading] = useState(true);
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Make an API request to fetch data
        const response = await axios.get(
          `https://node.mumber.com/allorders/${userId}`
        );

        // Once the data is fetched, set isLoading to false
        setIsLoading(false);
        console.log(response.data);
        // Process the data further if needed
        setOrders(response.data.data);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [userId]);

  return (
    <>
      {isLoading ? (
        <div className="content-wrapper" style={{ marginBottom: "200px" }}>
          <Preloader />
        </div>
      ) : (
        <div>
          <section className="banner-area pages-banner">
            <div className="container">
              <div className="row banner-row align-items-center">
                <div className="col-lg-6">
                  <div className="banner-heading">
                    <h2>
                      Your <span>Subscribed</span> <br /> numbers
                    </h2>
                    <p>
                      Find the perfect easy to remember phone number for your
                      business.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="man-img">
                    <img src="assets/images/list.png" alt="manImg" />
                  </div>
                </div>
              </div>
            </div>
          </section>

          <h3 className="mt-5 text-center">Your Subscribed Numbers</h3>
          {orders.length === 0 ? (
            <div>No orders found for this user.</div>
          ) : (
            orders.map((order) => (
              <div className="container" key={order.orderDetails.Id}>
                <div className="row">
                  <div className="col-lg-5 col-md-12 d-flex justify-content-lg-between align-items-center p-4 bg-white mt-lg-5 mt-md-5 one_colm mt-4 justify-content-center">
                    <div className="d-flex m-md-auto m-lg-0">
                      <h4 className="px-lg-3 px-md-5 border-end M_number px-2">
                        {order.products[0].phone_no}
                      </h4>
                      <span className="text-muted ps-lg-3 ps-md-4 ps-3">
                        {order.orderDetails.plan}
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-7 col-md-12 d-flex justify-content-lg-end align-items-baseline p-lg-4 bg-white mt-lg-5 two_colm p-md-3 mt-md-3 justify-content-md-around mt-4 py-3 px-2">
                    <div className="d-flex align-items-baseline">
                      <span className="text-muted">Price: </span>
                      <h4 className="text-secondary doller_price">
                        ${order.products[0].yearly}
                      </h4>
                      <div className="d-flex px-lg-5 px-md-5 align-items-baseline px-3 total_tag">
                        <span className="text-muted">Total: </span>
                        <h4 className="doller_price">
                          ${order.orderDetails.Total}
                        </h4>
                      </div>
                      <button
                        className="border_rounded px-4 py-3  border border-1 d-flex remove_btn"
                        style={{
                          color: "black",
                          fontWeight: 600,
                          backgroundColor: "#1beebb",
                        }}
                      >
                        Subscribed
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      )}

      <section className="callout-area mt-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="common-heading">
                <h3>
                  <span className="blue-x">Market Smarter, </span> Not Harder
                </h3>
                <p>
                  Building a brand is hard work. Make it easier by marketing
                  with a memorable phone number. Your unique number will leave a
                  lasting impression so you are first to mind when there is a
                  need for your service.
                </p>
                <div className="common-btn">
                  <Link to="/contact" className="btn-primaris">
                    Get Started
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="collout-man">
                <img
                  loading="lazy"
                  src="assets/images/footerMan2.png"
                  alt="man"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Subscription;
