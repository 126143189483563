import React, { useState, useEffect } from "react";
import { Link, NavLink, useNavigate, useLocation } from "react-router-dom";
import "./nav.css";
import { Dropdown, Badge } from "react-bootstrap";
import { FaBell, FaUser, FaSignInAlt } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Auth_nav = ({ className }) => {
  const location = useLocation();
  const successMessage = location.state && location.state.successMessage;

  const navigate = useNavigate();

  const usersString = window.localStorage.getItem("users");
  const users = window.localStorage.getItem("users");
  const [notifications, setNotifications] = useState([]);

  // ... Other code

  useEffect(() => {
    const handleScroll = () => {
      const toastContainer = document.querySelector(".fixed-toast-container");
      if (toastContainer) {
        toastContainer.style.top = `${20 + window.pageYOffset}px`;
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    if (successMessage) {
      toast.success(successMessage, { position: toast.POSITION.TOP_CENTER });
    }
  }, [successMessage]);
  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const userId = JSON.parse(users)[0].id;
        const response = await fetch(
          `https://node.mumber.com/api/notify/${userId}`
        );
        const data = await response.json();

        setNotifications(data);
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }
    };

    fetchNotifications();
  }, [users]);

  const handleLogout = () => {
    window.localStorage.removeItem("token");
    window.localStorage.removeItem("users");
    window.localStorage.removeItem("isLoggedIn");
    navigate("/Login");
  };
  const handleNotificationClick = async () => {
    try {
      const userId = JSON.parse(users)[0].id;
      await fetch(`https://node.mumber.com/api/mark-read/${userId}`, {
        method: "PUT",
      });
    } catch (error) {
      console.error("Error marking notifications as read:", error);
    }

    notifications.forEach((notification) => {
      // Handle each notification
    });
  };

  const usertoken = window.localStorage.getItem("token");
  if (usertoken) {
    const parsedUsers = JSON.parse(users);

    const ids = parsedUsers.map((user) => user.id);

    const username = parsedUsers[0].name;
    const truncatedUsername = username.slice(0, 8);
    console.log(username);
    return (
      <ul className="d-flex justify-content-between align-items-centerq">
        <ToastContainer className="fixed-toast-container" />

        <Dropdown className="pe-2 ps-1">
          <Dropdown.Toggle id="dropdown-basic">
            <FaUser />
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item href="#">{truncatedUsername}</Dropdown.Item>
            <hr style={{ margin: "0px" }}></hr>
            <Dropdown.Item>
              {" "}
              <Link to={`/Profile/${ids}`}>Profile</Link>
            </Dropdown.Item>
            <Dropdown.Item>
              {" "}
              <Link to={"/Subscription"}>Subscription</Link>
            </Dropdown.Item>
            <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        {/* <li onClick={handleLogout}><a role="button">Logout</a></li> */}
        {/* <Link to={`/Profile/${ids}`}><li><a role="button">Profile</a></li></Link>  */}
        {/* <Link to={`/Profile/10`}><li><a role="button">Profile</a></li></Link>  */}

        {/* <li>
          <Dropdown>
            <Dropdown.Toggle as={CustomToggle} id="notification-bell">
              <FaBell />
              {notifications.length > 0 && (
                <Badge variant="danger" className="notification-badge">
                  {notifications.length}
                </Badge>
              )}
            </Dropdown.Toggle>
            <Dropdown.Menu >
              {notifications.map(notification => (
      <Dropdown.Item style={{ backgroundColor: "transparent" }} key={notification.id}>{notification.message}</Dropdown.Item>
    ))}
            </Dropdown.Menu>
          </Dropdown>

        </li> */}
      </ul>
    );
  }

  return (
    <ul className="d-flex justify-content-between align-items-center">
      {/* {successMessage && (
      <li>
        <span className="text-success">{successMessage}</span>
      </li>
    )} */}
      <ToastContainer className="fixed-toast-container" />
      <Dropdown>
        <Dropdown.Toggle
          variant="secondary"
          id="dropdown-basic"
          className="custom-dropdown"
        >
          <FaSignInAlt />
        </Dropdown.Toggle>

        <Dropdown.Menu className="custom-dropdown-menu">
          <Dropdown.Item>
            <NavLink to="/Login">Login</NavLink>
          </Dropdown.Item>
          <Dropdown.Item>
            <NavLink to="/Register">Register</NavLink>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </ul>
  );
};

export default Auth_nav;

// CustomToggle component to handle bell icon toggle
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href="/"
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </a>
));
