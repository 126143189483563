import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const Profile = () => {
  const params = useParams();
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    password: "",
  });

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const fetchUserData = async () => {
    try {
      const response = await fetch(
        `https://node.mumber.com/api/users/${params.id}`
      );
      if (response.ok) {
        const data = await response.json();
        setUserData(data);
      } else {
        console.error("Error fetching user data:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      console.error("Passwords don't match");
      return;
    }

    if (newPassword.length < 8 || newPassword.length > 10) {
      alert("Password must be between 8 and 10 characters");
      return;
    }

    try {
      const response = await fetch(
        `https://node.mumber.com/api/users/${params.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            password: newPassword,
          }),
        }
      );

      if (response.ok) {
        alert("Password updated successfully");
        setUserData({ ...userData, password: newPassword });
        setNewPassword("");
        setConfirmPassword("");
      } else {
        console.error("Error updating password:", response.statusText);
      }
    } catch (error) {
      console.error("Error updating password:", error);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, [params.id]);

  return (
    <>
      <section className="banner-area pages-banner">
        <div className="container">
          <div className="row banner-row align-items-center">
            <div className="col-lg-6">
              <div className="banner-heading">
                <h2>
                  My <span>Profile</span>
                </h2>
                <p>
                  Find the perfect easy to remember phone number for your
                  business.
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="blog-banner-img man-img">
                <img src="/assets/images/blog-banner.png" alt="manImg" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <div>
        {userData ? (
          <div className="container mt-3">
            <h2 style={{ color: "#212529" }}>Profile</h2>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="name">Name:</label>
                <input
                  type="email"
                  style={{
                    width: "100%",
                    background: "#e9ecef",
                    border: "1px solid #ced4da",
                    borderRadius: "4px",
                    padding: "6px 12px",
                  }}
                  className="form-control"
                  id="name"
                  value={userData.name}
                  readOnly
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email:</label>
                <input
                  type="email"
                  style={{
                    width: "100%",
                    background: "#e9ecef",
                    border: "1px solid #ced4da",
                    borderRadius: "4px",
                    padding: "6px 12px",
                  }}
                  id="email"
                  value={userData.email}
                  readOnly
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">Company Name:</label>
                <input
                  type="text"
                  style={{
                    width: "100%",
                    background: "#e9ecef",
                    border: "1px solid #ced4da",
                    borderRadius: "4px",
                    padding: "6px 12px",
                  }}
                  id="email"
                  value={userData.company_name}
                  readOnly
                />
              </div>
              <div className="form-group" style={{ display: "none" }}>
                <label htmlFor="password">Password:</label>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  value={userData.password}
                  readOnly
                />
              </div>
              <div className="form-group">
                <label htmlFor="newPassword">New Password:</label>
                <input
                  type="password"
                  className="form-control"
                  id="newPassword"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="confirmPassword">Confirm Password:</label>
                <input
                  type="password"
                  className="form-control"
                  id="confirmPassword"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </div>
              <button type="submit" className="btn btn-primary mb-5">
                Save
              </button>
            </form>
          </div>
        ) : (
          <p>Loading user data...</p>
        )}
      </div>
    </>
  );
};

export default Profile;
