import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Slider from "./Slider";
import { initMap } from "../map";

const Map = () => {
  const navigate = useNavigate();

  const formatPhoneNumber = (phoneNumber) => {
    if (typeof phoneNumber !== "string" || !phoneNumber) {
      return phoneNumber; // or handle the error accordingly
    }
    const cleaned = phoneNumber.replace(/\D/g, "");

    let match;
    // if (cleaned.length === 10) {
    //   match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    // } else {
    match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,5})$/);
    // }

    if (match) {
      return `${match[1]}${match[2] ? "-" : ""}${match[2]}${
        match[3] ? "-" : ""
      }${match[3]}`;
    }

    return phoneNumber;
  };

  const [suggestions, setSuggestions] = useState([]);

  const [selectedSuggestion, setSelectedSuggestion] = useState("");
  const [showSuggestions, setShowSuggestions] = useState(false);
  const handleInputChange = (event) => {
    let value = event.target.value;

    if (
      event.nativeEvent.inputType === "deleteContentBackward" &&
      value.length > 0
    ) {
      // alert(value.length)
      if (value.length <= 5) {
        value = value.slice(0, -2);
      } else if (value.length == 1) {
        value = "";
      } else {
        value = value.slice(0, -1);
      }

      // alert(value);
    }

    const startsWithAlphabet = /^[a-zA-Z]/.test(value);

    if (startsWithAlphabet == false) {
      setSelectedSuggestion(formatPhoneNumber(value));
    } else {
      setSelectedSuggestion(value);
    }

    // console.log(startsWithAlphabet);

    let api_num_value = value.replace(/[(),-]/g, "");
    if (api_num_value.trim() !== "") {
      fetch(`https://node.mumber.com/api/suggestions?query=${api_num_value}`)
        .then((response) => response.json())
        .then((data) => setSuggestions(data))
        .catch((error) => console.error(error));

      setShowSuggestions(true);
    } else {
      setSuggestions([]);
      setShowSuggestions(false);
    }
  };

  document.addEventListener("click", function (event) {
    var targetElement = event.target;

    // Check if the clicked element or any of its parents have the search area class
    var isClickedInsideSearchArea = targetElement.closest(".search-bar-area");

    if (!isClickedInsideSearchArea) {
      // Clicked outside the search area
      setSuggestions([]);
    }
  });

  return (
    <>
      <section>
        {/*============================================================
                start search bar area
=============================================================*/}
        <div className="search-bar-area" style={{ marginTop: "80px" }}>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <form
                  id="quick-request-submit"
                  style={{ position: "absolute", top: "120px", width: "100%" }}
                >
                  <div className="reqest-a-product">
                    <div className="search-icon">
                      <img
                        loading="lazy"
                        src="assets/images/Ellipse.png"
                        alt="ellipse"
                      />
                    </div>

                    <input
                      type="text"
                      placeholder="Search here by: Phone Number, Area Code, Keyword, State, or City"
                      name="phone"
                      id="quick-request-input"
                      value={selectedSuggestion}
                      onChange={handleInputChange}
                      autoComplete="off"
                      onClick={() => {
                        setSuggestions([]); // Clear the suggestions list
                        setShowSuggestions(false); // Hide the suggestions
                      }}
                    />
                    <ul style={{ paddingTop: "40px", marginTop: "-40px" }}>
                      {showSuggestions &&
                        suggestions.map((suggestion, index) => (
                          <li
                            key={index}
                            onClick={() => {
                              setSelectedSuggestion(
                                formatPhoneNumber(suggestion.phone_no)
                              );
                              setSuggestions([]);
                              setShowSuggestions(false);

                              navigate(
                                "/Search/" +
                                  formatPhoneNumber(suggestion.phone_no)
                              );
                            }}
                            style={{
                              background: "#fff",
                              padding: "10px 30px",
                              cursor: "pointer",
                              borderBottom: "1px solid rgb(227 227 227)",
                              fontSize: "14px",
                              color: "#333",
                              fontWeight: "bold",
                            }}
                          >
                            <span>
                              {formatPhoneNumber(suggestion.phone_no)}
                            </span>{" "}
                            <b style={{ color: "#999", float: "right" }}>
                              {suggestion.Description.replace(
                                /[?!]|XYY-YYYY\b/g,
                                ""
                              ).substring(0, 60)}
                              ...{" "}
                              <span
                                style={{ color: "#3374ff", marginLeft: "20px" }}
                              >
                                ${suggestion.monthly}/mo
                              </span>
                            </b>
                          </li>
                        ))}

                      {suggestions.length > 0 && (
                        <li>
                          <Link to={`/Search/${selectedSuggestion}`}>
                            <button
                              style={{
                                width: "100%",
                                color: "#000",
                                border: "1px solid #eee",
                                height: "39px",
                              }}
                            >
                              View All
                            </button>
                          </Link>
                        </li>
                      )}
                    </ul>

                    <Link to={`/Search/${selectedSuggestion}`}>
                      <button className="btn-primaris srh">Search</button>
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        {/*============================================================
                End search bar area
     =============================================================*/}
        <div id="map_contianer container-fluid content-wrapper">
          <div id="sak_map" style={{ paddingTop: "119px", height: "auto" }}>
            <iframe
              title="Google Maps Integration"
              src="../../assets/new_edit2.html"
              width="100%"
              height="1080"
              style={{
                border: "none",
                overflow: "hidden",
                marginTop: "-235px",
              }}
              frameBorder="0"
              scrolling="no"
              sandbox="allow-top-navigation allow-same-origin allow-scripts allow-popups allow-forms"
              allowFullScreen=""
              aria-hidden="false"
              tabIndex="0"
            ></iframe>
          </div>
        </div>
      </section>
    </>
  );
};

export default Map;
