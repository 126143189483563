import logo from './logo.svg';
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import {
  Navigation,
  Footer,
  Home,
  Blog,
  FAQ,
  BlogContent,
  ListOfFavriot,
  SearchedNumbers,
  Login,
  Register,
  Contact,
  Checkout,
  Cart,
  Map,
  Search,
  ResetPassword,
  Profile,
  Thankyou,
  Phone
} from "./components";
import Faqcontent from "./components/Faqcontent";
import Mumber_terms from './components/Mumber_terms';
import Policies from './components/Policies';
import Preloader from "./Preloader";
import Forgot from './components/forgot-password';
import Termcondition from './components/terms';
import Subscription from './components/Subscription';
// import { ResetPassword } from './components/Reset-password';

function Allroutes() {

  const loggedin = window.localStorage.getItem("isLoggedIn");
  console.log(loggedin,"login");
  
  return (
    <>
    <Navigation />
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/Blog" element={<Blog />} />
      <Route path="/FAQ" element={<FAQ />} />
      <Route path="/terms" element={<Termcondition />} />
      <Route path="/Subscription" element={<Subscription />} />
      <Route path="/BlogContent/:id" element={<BlogContent/>} />
      <Route path="/Faqcontent/:id" element={<Faqcontent/>} />
      <Route path="/Favourites" element={<ListOfFavriot />} />
      <Route path="/SearchedNumbers" element={<SearchedNumbers />} />
      <Route path="/Search" element={<Search/>} />
      <Route path="/Login" element={<Login />} />
      <Route path="/Register" element={<Register />} />
      <Route path="/Contact" element={<Contact />} />
      <Route path="/Checkout" element={<Checkout />} />
      <Route path="/Cart" element={<Cart />} />
      <Route path="/Profile/:id" element={<Profile/>} />
      <Route path="/Search/:id?" element={<Search/>} />
      <Route path="/forgot" element={<Forgot/>} />
      <Route path="/Map" element={<Map/>} />
      <Route path="/reset-password" element={<ResetPassword/>} />
      <Route path="/Map" element={<Map/>} />
      <Route path="/Thankyou" element={<Thankyou/>} />
      <Route path="/Phone/:id?" element={<Phone/>} />
      <Route path="/license-agreement/" element={<Mumber_terms/>} />
      <Route path="/privacy-policy/" element={<Policies/>} />
      {/* <Route path="/reset-password" element={<ResetPassword/>} /> */}
     

    </Routes>
    <Footer />
  </>
  );
}

export default Allroutes;