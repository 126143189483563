import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";


const Termcondition = () => {
  const products = JSON.parse(sessionStorage.getItem("cart"));

  var cart_total_amount = 0;

//   const [product_fee, setproduct_fee] = useState(0);

//     useEffect(() => {
//       products.map((product) => {
//         if (product != null) {
//           var product_price = product.fees;
//           product_price = product_price.replace("$", "");
//           cart_total_amount =
//           parseFloat(cart_total_amount) + parseFloat(product_price);
//           setproduct_fee(Math.round((cart_total_amount + Number.EPSILON) * 100) / 100);
//           console.log(product);
//         }
//       });
  
//       console.log(product_fee);
//     });
  
    return (
        <div>

            <section className="banner-area pages-banner">
                <div className="container">
                    <div className="row banner-row align-items-center">
                        <div className="col-lg-6">
                            <div className="banner-heading">
                                <h2>OUR TERMS AND CONDITIONS</h2>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="faq-banner-img man-img">
                                <img src="assets/images/faq-banner.png" alt="manImg" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>








            <div className='container'>

                <h1 style={{
                    textAlign: "center", fontSize: "40px",
                    fontWeight: "600",color:"#0d6efd"
                }} className='mt-4'>Mumber Terms & Conditions</h1>

                <div style={{ textAlign: "center" }}>
                    {/* <h4 style={{ color: "black" }} className='mt-5 mb-2'>  Subtotal: ${product_fee} </h4> */}
                    <h5 className="mt-5"> You can change your plan at any time after sign up.</h5>
                    
                    <h5 className="mt-2">   *Please agree to the License Terms
                        This telephone number is being licensed.
                        <br></br>You will not own it nor be able to port the number away.*</h5>
                </div>


                <p style={{ color: "black" }} className='mt-3'>
                    <b> There is no ownership or porting away: </b>
                    The licensee understands and agrees that at no time shall they have any
                    ownership, leasehold, or proprietary interest in the telephone number. The
                    Customer has no rights to become the subscriber of record for the telephone number,
                    to port the telephone number to a different carrier, to submit any letter of
                    authorization to any carrier or Resporg. Any and all rights to the Number will
                    be retained by NumberBarn which at all times will remain the subscriber of record
                    and end-user for the telephone number during the term and afterwards.


                </p>

                <p style={{ color: "black" }} className='mt-3'>

                    <b>Monthly fees:</b> The licensee shall agree to pay: <br></br>
                    (i) a one-time activation fee if listed, <br></br>
                    (ii) the monthly license amount as displayed at the time of checkout
                    and within the licensee's control panel, and <br></br>
                    (iii) the automated annual increase if so indicated at the time of purchase.
                    Customer warrants, represents, acknowledges and agrees that Customer shall be
                    solely responsible for and shall pay any and all Billed Charges incurred on and after
                    the Effective Date including all applicable Federal, State, municipal and all other
                    local taxes, surcharges and regulatory fees of any description billed by any such
                    telecommunications carrier.
                </p>

                <p style={{ color: "black" }} className='mt-3'>


                    <b>License duration:</b> The license terms are effective on the date of telephone
                    number activation (the "create date").
                    The license is month-to-month and can be cancelled at any time by the licensee,
                    or terminated by NumberBarn if licensee's account is delinquent due to non-payment
                    (or in violation of terms of service).

                </p>


                <p style={{ color: "black" }} className='mt-3'>
               <b> Reconnection:</b> If the telephone number is deleted from licensee's 
               account after two week's of non-payment, there is no guarantee of
                reconnection or continuance of license as the telephone number will be cancelled 
                from licensee's account and reassigned to the original owner. It is the sole 
                responsibility of the licensee to maintain its account in good standing


                </p>


                <p style={{ color: "black" }} className='mt-3'>
               <b> By checking the boxes above, I acknowledge that I have read and agree 
               to these terms for licensing my phone number. These terms can also be found 
               in our complete <span style={{color:"#d5aa00"}}>terms of service.</span></b>


                </p>


               



            </div>











            <section className="callout-area mt-5">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="common-heading">
                            <h3><span className="blue-x">Market Smarter, </span> Not Harder</h3>
              <p>Building a brand is hard work. Make it easier by marketing with a memorable
                 phone number. Your unique number will leave a
                 lasting impression so you are first to mind when there is a need for your service.</p>
                                <div className="common-btn">
                                <Link to="/contact" className="btn-primaris">Get Started</Link>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="collout-man">
                                <img loading="lazy" src="/assets/images/footerMan2.png" alt="man" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </div>
    )
}

export default Termcondition