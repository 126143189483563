  (g=>{var h,a,k,p="The Google Maps JavaScript API",c="google",l="importLibrary",q="__ib__",m=document,b=window;b=b[c]||(b[c]={});var d=b.maps||(b.maps={}),r=new Set,e=new URLSearchParams,u=()=>h||(h=new Promise(async(f,n)=>{await (a=m.createElement("script"));e.set("libraries",[...r]+"");for(k in g)e.set(k.replace(/[A-Z]/g,t=>"_"+t[0].toLowerCase()),g[k]);e.set("callback",c+".maps."+q);a.src=`https://maps.${c}apis.com/maps/api/js?`+e;d[q]=f;a.onerror=()=>h=n(Error(p+" could not load."));a.nonce=m.querySelector("script[nonce]")?.nonce||"";m.head.append(a)}));d[l]?console.warn(p+" only loads once. Ignoring:",g):d[l]=(f,...n)=>r.add(f)&&u().then(()=>d[l](f,...n))})({
    key: "AIzaSyAq0WOgwDxA8IDU_CteUjHrQG46bykWP9w",
    v: "weekly",
    // Use the 'v' parameter to indicate the version to use (weekly, beta, alpha, etc.).
    // Add other bootstrap parameters as needed, using camel case.
  });
// map.js

// Wrap your code in a function to avoid polluting the global scope
export const initMap = async () => {
  try {
    const { Map } = await window.google.maps.importLibrary("maps");
    const { Places } = await window.google.maps.importLibrary("places");

    window.map = new Map(document.getElementById('map'), {
      zoom: 10,
      center: new window.google.maps.LatLng(32.900000000000000, -98.99807793570024),
    });
  } catch (error) {
    console.error('Error initializing the map:', error);
  }
};

