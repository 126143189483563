import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Slider from "./Slider";

function Home({ props }) {
  const navigate = useNavigate();

  const formatPhoneNumber = (phoneNumber) => {
    if (typeof phoneNumber !== "string" || !phoneNumber) {
      return phoneNumber; // or handle the error accordingly
    }
    const cleaned = phoneNumber.replace(/\D/g, "");

    let match;
    // if (cleaned.length === 10) {
    //   match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    // } else {
    match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,5})$/);
    // }

    if (match) {
      return `${match[1]}${match[2] ? "-" : ""}${match[2]}${
        match[3] ? "-" : ""
      }${match[3]}`;
    }

    return phoneNumber;
  };

  const [data, setData] = useState([]);

  const loadData = async () => {
    try {
      const response = await axios.get("https://node.mumber.com/api/getreview");
      setData(response.data.data);
    } catch (error) {
      console.log(error);
    }
    // navigate('/products');
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    // $('.social-proof-carouser').owlCarousel()
  }, []);

  const [suggestions, setSuggestions] = useState([]);

  const [selectedSuggestion, setSelectedSuggestion] = useState("");
  const [showSuggestions, setShowSuggestions] = useState(false);
  const handleInputChange = (event) => {
    let value = event.target.value;

    if (
      event.nativeEvent.inputType === "deleteContentBackward" &&
      value.length > 0
    ) {
      // alert(value.length)
      if (value.length <= 5) {
        value = value.slice(0, -2);
      } else if (value.length == 1) {
        value = "";
      } else {
        value = value.slice(0, -1);
      }

      // alert(value);
    }

    const startsWithAlphabet = /^[a-zA-Z]/.test(value);

    if (startsWithAlphabet == false) {
      setSelectedSuggestion(formatPhoneNumber(value));
    } else {
      setSelectedSuggestion(value);
    }

    // console.log(startsWithAlphabet);

    let api_num_value = value.replace(/[(),-]/g, "");
    if (api_num_value.trim() !== "") {
      fetch(`https://node.mumber.com/api/suggestions?query=${api_num_value}`)
        .then((response) => response.json())
        .then((data) => setSuggestions(data))
        .catch((error) => console.error(error));

      setShowSuggestions(true);
    } else {
      setSuggestions([]);
      setShowSuggestions(false);
    }
  };
  document.addEventListener("click", function (event) {
    var targetElement = event.target;

    // Check if the clicked element or any of its parents have the search area class
    var isClickedInsideSearchArea = targetElement.closest(".search-bar-area");

    if (!isClickedInsideSearchArea) {
      // Clicked outside the search area
      setSuggestions([]);
    }
  });

  const [activeImage, setActiveImage] = useState(null);

  const handleImageHover = (index) => {
    setActiveImage(index);
  };

  // const handleImageLeave = () => {
  //   setActiveImage(null);
  // };

  // const imageList = [
  //   // "assets/images/call.png",
  //   "assets/images/leads.png",
  // ];

  return (
    <div>
      {/*============================================================
                start banner area
     =============================================================*/}
      <section className="banner-area home-banner">
        <div className="container">
          <div className="row banner-row align-items-center">
            <div className="col-lg-6">
              <div className="banner-heading">
                <h2>
                  <span>Find</span> Your New Vanity Phone Number
                </h2>
                <p>
                  Find the perfect easy to remember phone number for your
                  business.
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="man-img">
                <img src="assets/images/bannerMan1.png" alt="manImg" />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*============================================================
                End banner area
     =============================================================*/}
      {/*============================================================
                start search bar area
=============================================================*/}
      <div className="search-bar-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <form id="quick-request-submit">
                <div className="reqest-a-product">
                  <div className="search-icon">
                    <img
                      loading="lazy"
                      src="assets/images/Ellipse.png"
                      alt="ellipse"
                    />
                  </div>

                  <input
                    type="text"
                    className="search-box-main"
                    placeholder="Search here by: Phone Number, Area Code, Keyword, State, or City"
                    name="phone"
                    id="quick-request-input"
                    value={selectedSuggestion}
                    onChange={handleInputChange}
                    autoComplete="off"
                    onClick={() => {
                      setSuggestions([]); // Clear the suggestions list
                      setShowSuggestions(false); // Hide the suggestions
                    }}
                  />
                  <ul style={{ paddingTop: "40px", marginTop: "-40px" }}>
                    {showSuggestions &&
                      suggestions.map((suggestion, index) => (
                        <li
                          title={suggestion.Description.replace(
                            /[?!]|XYY-YYYY\b/g,
                            ""
                          )}
                          key={index}
                          onClick={() => {
                            setSelectedSuggestion(
                              formatPhoneNumber(suggestion.phone_no)
                            );
                            setSuggestions([]);
                            setShowSuggestions(false);
                            navigate(
                              "/Search/" +
                                formatPhoneNumber(suggestion.phone_no)
                            );
                          }}
                          style={{
                            background: "#fff",
                            padding: "10px 30px",
                            cursor: "pointer",
                            borderBottom: "1px solid rgb(227 227 227)",
                            fontSize: "14px",
                            color: "#333",
                            fontWeight: "bold",
                          }}
                        >
                          <span>{formatPhoneNumber(suggestion.phone_no)}</span>
                          <b style={{ color: "#999", float: "right" }}>
                            {suggestion.Description.replace(
                              /[?!]|XYY-YYYY\b/g,
                              ""
                            ).substring(0, 60)}
                            ...
                            <span
                              style={{ color: "#3374ff", marginLeft: "20px" }}
                            >
                              ${suggestion.monthly}/mo
                            </span>
                          </b>
                        </li>
                      ))}

                    {suggestions.length > 0 && (
                      <li>
                        <Link to={`/Search/${selectedSuggestion}`}>
                          <button
                            style={{
                              width: "100%",
                              color: "#000",
                              border: "1px solid #eee",
                              height: "39px",
                            }}
                          >
                            View All
                          </button>
                        </Link>
                      </li>
                    )}
                  </ul>

                  <Link to={`/Search/${selectedSuggestion}`}>
                    <button className="btn-primaris srh">Search</button>
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/*============================================================
                End search bar area
     =============================================================*/}
      {/*============================================================
                start location area
     =============================================================*/}
      <section className="searchlocation-area">
        <div className="container">
          <div className="row align-items-center">
            {/* <div class="col-lg-7">
                        <div class="location-map">
                            <img loading="lazy" src="assets/images/mapx.png" alt="map">
                        </div>
                    </div> */}
            <div className="col-lg-6 offset-lg-3">
              <div className="search-location-right">
                <div className="common-heading">
                  <h3>
                    Choose your <span className="blue">Number</span>
                  </h3>
                  <p>
                    Find the perfect easy to remember phone number for your
                    business.
                  </p>
                </div>
                <div className="state-main-content">
                  {/* state top content */}
                  <div className="state-top-content d-flex justify-content-between align-items-center">
                    <div className="state-top-left d-flex align-items-center">
                      {/* <img loading="lazy" src="assets/images/location.png" alt="location" /> */}
                      <h6 className="text-center">Premium Collection</h6>
                    </div>

                    <Link
                      to={"/Search"}
                      style={{ float: "right", marginRight: "20px" }}
                    >
                      <u>View All</u>
                    </Link>
                  </div>
                  {/* state table area */}
                  <div className="state-table-main">
                    <div className="row">
                      <div className="col-lg-6">
                        {/* single item */}
                        <div className="state-table-single-item d-flex justify-content-between align-items-center">
                          <div className="table-single-left">
                            <img
                              loading="lazy"
                              src="assets/images/union.png"
                              alt="union"
                            />

                            <b href="tel:+915-333-3333"> +915 -333-3333</b>
                          </div>
                          <div className="table-single-right">
                            <span></span>
                          </div>
                        </div>
                        {/* single item */}
                        <div className="state-table-single-item d-flex justify-content-between align-items-center">
                          <div className="table-single-left">
                            <img
                              loading="lazy"
                              src="assets/images/union.png"
                              alt="union"
                            />
                            <b href="tel:+701-888-8888"> +701 -888-8888</b>
                          </div>
                          <div className="table-single-right">
                            <span></span>
                          </div>
                        </div>
                        {/* single item */}
                        <div className="state-table-single-item d-flex justify-content-between align-items-center">
                          <div className="table-single-left">
                            <img
                              loading="lazy"
                              src="assets/images/union.png"
                              alt="union"
                            />
                            <b href="tel:+605-888-8888"> +605 -888-8888</b>
                          </div>
                          <div className="table-single-right">
                            <span></span>
                          </div>
                        </div>
                        {/* single item */}
                        <div className="state-table-single-item d-flex justify-content-between align-items-center">
                          <div className="table-single-left">
                            <img
                              loading="lazy"
                              src="assets/images/union.png"
                              alt="union"
                            />
                            <b href="tel:+267-777-7777"> +267 -777-7777</b>
                          </div>
                          <div className="table-single-right">
                            <span></span>
                          </div>
                        </div>
                        {/* single item */}
                        <div className="state-table-single-item d-flex justify-content-between align-items-center">
                          <div className="table-single-left">
                            <img
                              loading="lazy"
                              src="assets/images/union.png"
                              alt="union"
                            />
                            <b href="tel:+410-888-8888"> +410 -888-8888</b>
                          </div>
                          <div className="table-single-right">
                            <span></span>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="state-table-single-item d-flex justify-content-between align-items-center">
                          <div className="table-single-left">
                            <img
                              loading="lazy"
                              src="assets/images/union.png"
                              alt="union"
                            />
                            <b href="tel:+856-444-4444"> +856 -444-4444</b>
                          </div>
                          <div className="table-single-right">
                            <span></span>
                          </div>
                        </div>
                        <div className="state-table-single-item d-flex justify-content-between align-items-center">
                          <div className="table-single-left">
                            <img
                              loading="lazy"
                              src="assets/images/union.png"
                              alt="union"
                            />
                            <b href="tel:+901-444-4444"> +901 -444-4444</b>
                          </div>
                          <div className="table-single-right">
                            <span></span>
                          </div>
                        </div>
                        <div className="state-table-single-item d-flex justify-content-between align-items-center">
                          <div className="table-single-left">
                            <img
                              loading="lazy"
                              src="assets/images/union.png"
                              alt="union"
                            />
                            <b href="tel:+256-222-2222"> +256 -222-2222</b>
                          </div>
                          <div className="table-single-right">
                            <span></span>
                          </div>
                        </div>
                        <div className="state-table-single-item d-flex justify-content-between align-items-center">
                          <div className="table-single-left">
                            <img
                              loading="lazy"
                              src="assets/images/union.png"
                              alt="union"
                            />
                            <b href="tel:+216-999-9999"> +216 -999-9999</b>
                          </div>
                          <div className="table-single-right">
                            <span></span>
                          </div>
                        </div>
                        <div className="state-table-single-item d-flex justify-content-between align-items-center">
                          <div className="table-single-left">
                            <img
                              loading="lazy"
                              src="assets/images/union.png"
                              alt="union"
                            />
                            <b href="tel:+414-999-9999"> +414 -999-9999</b>
                          </div>
                          <div className="table-single-right">
                            <span></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*============================================================
                End location area
     =============================================================*/}
      {/*============================================================
                start values-proposal area
     =============================================================*/}
      <section className="icon1but">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-6 col-sm-6 text-center">
              {activeImage === 0 && (
                <h1
                  className="heding"
                  style={{
                    position: "absolute",
                    width: "100%",
                    color: "#1beebb",
                    fontFamily: "Poppins",
                    textTransform: "none",
                    letterSpacing: "0px",
                    fontSize: "26px",
                    lineHeight: "34px",
                    fontWeight: "600",
                    top: "180px",
                  }}
                >
                  Protect your Leads
                </h1>
              )}

              {activeImage === 1 && (
                <h1
                  className="heding"
                  style={{
                    position: "absolute",
                    width: "100%",
                    color: "#1beebb",
                    fontFamily: "Poppins",
                    textTransform: "none",
                    letterSpacing: "0px",
                    fontSize: "26px",
                    lineHeight: "34px",
                    fontWeight: "600",
                    top: "180px",
                  }}
                >
                  Increase call volume
                </h1>
              )}

              {(activeImage === 2 || activeImage === null) && (
                <h1
                  className="heding"
                  style={{
                    position: "absolute",
                    width: "100%",
                    color: "#1beebb",
                    fontFamily: "Poppins",
                    textTransform: "none",
                    letterSpacing: "0px",
                    fontSize: "26px",
                    lineHeight: "34px",
                    fontWeight: "600",
                    top: "180px",
                  }}
                >
                  Branding Strategy
                </h1>
              )}

              {activeImage === 3 && (
                <h1
                  className="heding"
                  style={{
                    position: "absolute",
                    width: "100%",
                    color: "#1beebb",
                    fontFamily: "Poppins",
                    textTransform: "none",
                    letterSpacing: "0px",
                    fontSize: "26px",
                    lineHeight: "34px",
                    fontWeight: "600",
                    top: "180px",
                  }}
                >
                  First Look
                </h1>
              )}

              {activeImage === 4 && (
                <h1
                  className="heding"
                  style={{
                    position: "absolute",
                    width: "100%",
                    color: "#1beebb",
                    fontFamily: "Poppins",
                    textTransform: "none",
                    letterSpacing: "0px",
                    fontSize: "26px",
                    lineHeight: "34px",
                    fontWeight: "600",
                    top: "180px",
                  }}
                >
                  First Impression
                </h1>
              )}

              <img
                style={{ position: "relative" }}
                src="assets/images/mumber-infographic.png"
              />

              <img
                className={`rotate-hover ${activeImage === 0 ? "active" : ""}`}
                onMouseEnter={() => handleImageHover(0)}
                style={{
                  position: "relative",
                  width: "39px",
                  top: "-159px",
                  left: "-13px",
                }}
                src={
                  activeImage === 0
                    ? "assets/images/leads.png"
                    : "assets/images/shield2.png"
                }
              />

              <img
                className={`rotate-hover ${activeImage === 1 ? "active" : ""}`}
                onMouseEnter={() => handleImageHover(1)}
                style={{
                  position: "relative",
                  width: "37px",
                  top: "-219px",
                  left: "-51px",
                }}
                src={
                  activeImage === 1
                    ? "assets/images/call.png"
                    : "assets/images/viber2.png"
                }
              />

              <img
                className={`rotate-hover ${activeImage === 2 ? "active" : ""}`}
                onMouseEnter={() => handleImageHover(2)}
                style={{
                  position: "relative",
                  width: "39px",
                  top: "-161px",
                  left: "-151px",
                }}
                src={
                  activeImage === 2 || activeImage === null
                    ? "assets/images/first-look-active.png"
                    : "assets/images/eye3.png"
                }
              />

              <img
                className={`rotate-hover ${activeImage === 3 ? "active" : ""}`}
                onMouseEnter={() => handleImageHover(3)}
                style={{
                  position: "relative",
                  width: "39px",
                  top: "-158px",
                  left: "-67px",
                }}
                src={
                  activeImage === 3
                    ? "assets/images/branding1.png"
                    : "assets/images/branding.png"
                }
              />

              <img
                className={`rotate-hover ${activeImage === 4 ? "active" : ""}`}
                onMouseEnter={() => handleImageHover(4)}
                style={{
                  position: "relative",
                  width: "39px",
                  top: "-97px",
                  left: "-166px",
                }}
                src={
                  activeImage === 4
                    ? "assets/images/impression.png"
                    : "assets/images/handshake2.png"
                }
              />
            </div>
            <div className="col-lg-7 col-md-6 col-sm-6 text-center p-5">
              {activeImage === 0 && (
                <div className="text1 fade-up">
                  <h5 className="" style={{ marginTop: "23%" }}>
                    Many businesses focus on getting customers to their website.
                    From there, the website is designed to prompt a phone call.
                    If you have a memorable number customers can skip the first
                    step! This is critical because it can reduce costs due to
                    expensive Google ad clicks. More importantly, it reduces the
                    chance a competitor ad picks off your lead during an online
                    search.{" "}
                  </h5>{" "}
                </div>
              )}

              {activeImage === 1 && (
                <div className="text2 fade-up">
                  <h5 className="mt-3 mb-5">
                    What percentage of your sales happen on the phone? Inbound
                    calls are the lifeblood for many businesses. The right
                    memorable number can boost inbound calls dramatically.
                  </h5>
                  <img
                    style={{
                      maxWidth: "200px",
                      height: "auto",
                      color: "#007aff",
                    }}
                    src="assets/images/call-desc1.png"
                  />
                </div>
              )}

              {(activeImage === 2 || activeImage === null) && (
                <div className="text3 fade-up">
                  <h5 className="mt-3 mb-5">
                    It's simple. Tie your brand to a memorable number.
                    Prospective customers and clients will have an easier time
                    remembering your business.
                  </h5>
                  <img
                    style={{
                      maxWidth: "200px",
                      height: "auto",
                      color: "#007aff",
                    }}
                    src="assets/images/branding-desc1.png"
                  />
                </div>
              )}

              {activeImage === 3 && (
                <div className="text4 fade-up">
                  <h5 className="mt-3 mb-5">
                    While we all strive to be the best choice, it pays to be the
                    easy choice. Use a memorable number so that you get the
                    first call and first chance to earn a client's business
                  </h5>
                  <img
                    style={{
                      maxWidth: "200px",
                      height: "auto",
                      color: "#007aff",
                    }}
                    src="assets/images/first-look-desc1.png"
                  />
                </div>
              )}

              {activeImage === 4 && (
                <div className="text5 fade-up">
                  <h5 className="mt-3 mb-5">
                    A strong number leaves a strong first impression. It can
                    simultaneously let the customer know you are established and
                    allow you to stand out from the crowd.
                  </h5>
                  <img
                    style={{
                      maxWidth: "200px",
                      height: "auto",
                      color: "#007aff",
                    }}
                    src="assets/images/impression-desc1.png"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </section>

      {/* /////////////////////////////////////////////////////////////////////////////////////////// */}

      <section className="icon2but mb-5">
        <div className="container text-center">
          <div className="mb-4">
            {activeImage === 5 && (
              <h1
                className="heding"
                style={{
                  width: "100%",
                  color: "#1beebb",
                  fontFamily: "Poppins",
                  textTransform: "none",
                  letterSpacing: "0px",
                  fontSize: "26px",
                  lineHeight: "34px",
                  fontWeight: "600",
                  top: "180px",
                }}
              >
                Protect your Leads
              </h1>
            )}

            {activeImage === 6 && (
              <h1
                className="heding"
                style={{
                  width: "100%",
                  color: "#1beebb",
                  fontFamily: "Poppins",
                  textTransform: "none",
                  letterSpacing: "0px",
                  fontSize: "26px",
                  lineHeight: "34px",
                  fontWeight: "600",
                  top: "180px",
                }}
              >
                Increase call volume
              </h1>
            )}

            {(activeImage === 7 || activeImage === null) && (
              <h1
                className="heding"
                style={{
                  width: "100%",
                  color: "#1beebb",
                  fontFamily: "Poppins",
                  textTransform: "none",
                  letterSpacing: "0px",
                  fontSize: "26px",
                  lineHeight: "34px",
                  fontWeight: "600",
                  top: "180px",
                }}
              >
                Branding Strategy
              </h1>
            )}

            {activeImage === 8 && (
              <h1
                className="heding"
                style={{
                  width: "100%",
                  color: "#1beebb",
                  fontFamily: "Poppins",
                  textTransform: "none",
                  letterSpacing: "0px",
                  fontSize: "26px",
                  lineHeight: "34px",
                  fontWeight: "600",
                  top: "180px",
                }}
              >
                First Look
              </h1>
            )}

            {activeImage === 9 && (
              <h1
                className="heding"
                style={{
                  width: "100%",
                  color: "#1beebb",
                  fontFamily: "Poppins",
                  textTransform: "none",
                  letterSpacing: "0px",
                  fontSize: "26px",
                  lineHeight: "34px",
                  fontWeight: "600",
                  top: "180px",
                }}
              >
                First Impression
              </h1>
            )}
          </div>

          <img
            className={`rotate-hover ${activeImage === 5 ? "active" : ""}`}
            onMouseEnter={() => handleImageHover(5)}
            style={{
              position: "relative",
              width: "54px",
              border: "1px solid #007aff",
              borderRadius: "50%",
              padding: "5px",
              margin: "5px",
            }}
            src={
              activeImage === 5
                ? "assets/images/leads.png"
                : "assets/images/shield2.png"
            }
          />

          <img
            className={`rotate-hover ${activeImage === 6 ? "active" : ""}`}
            onMouseEnter={() => handleImageHover(6)}
            style={{
              position: "relative",
              width: "54px",
              border: "1px solid #007aff",
              borderRadius: "50%",
              padding: "5px",
              margin: "5px",
            }}
            src={
              activeImage === 6
                ? "assets/images/call.png"
                : "assets/images/viber2.png"
            }
          />

          <img
            className={`rotate-hover ${activeImage === 7 ? "active" : ""}`}
            onMouseEnter={() => handleImageHover(7)}
            style={{
              position: "relative",
              width: "54px",
              border: "1px solid #007aff",
              borderRadius: "50%",
              padding: "5px",
              margin: "5px",
            }}
            src={
              activeImage === 7 || activeImage === null
                ? "assets/images/first-look-active.png"
                : "assets/images/eye3.png"
            }
          />

          <img
            className={`rotate-hover ${activeImage === 8 ? "active" : ""}`}
            onMouseEnter={() => handleImageHover(8)}
            style={{
              position: "relative",
              width: "54px",
              border: "1px solid #007aff",
              borderRadius: "50%",
              padding: "5px",
              margin: "5px",
            }}
            src={
              activeImage === 8
                ? "assets/images/branding1.png"
                : "assets/images/branding.png"
            }
          />

          <img
            className={`rotate-hover ${activeImage === 9 ? "active" : ""}`}
            onMouseEnter={() => handleImageHover(9)}
            style={{
              position: "relative",
              width: "54px",
              border: "1px solid #007aff",
              borderRadius: "50%",
              padding: "5px",
              margin: "5px",
            }}
            src={
              activeImage === 9
                ? "assets/images/impression.png"
                : "assets/images/handshake2.png"
            }
          />

          <div
            className=""
            style={{ width: "70%", margin: "0 auto", height: "370px" }}
          >
            {activeImage === 5 && (
              <div className="text1 fade-up">
                <h5 className="" style={{ marginTop: "100px" }}>
                  Many business focus on getting customers to their website.
                  From there, the website is designed to prompt a phone call. If
                  you have a meomrable number customers can skip the first step!
                  This is critical because it can reduce expensive google ad
                  clicks. More importantly, it reduces the chance a competitor
                  ad picls off your lead during an online search.{" "}
                </h5>{" "}
              </div>
            )}

            {activeImage === 6 && (
              <div className="text2 fade-up">
                <h5 className="mt-3 mb-5">
                  What percentage of your sales happen on the phone? Inbound
                  calls are the lifeblood for many businesses. The right
                  memorable number can boost inbound calls dramatically.
                </h5>
                <img
                  style={{
                    maxWidth: "200px",
                    height: "auto",
                    color: "#007aff",
                  }}
                  src="assets/images/call-desc1.png"
                />
              </div>
            )}

            {(activeImage === 7 || activeImage === null) && (
              <div className="text3 fade-up">
                <h5 className="mt-3 mb-5">
                  It's simple. Tie your brand to a memorable number. Prospective
                  customers and clients will have an easier time remembering
                  your business.
                </h5>
                <img
                  style={{
                    maxWidth: "200px",
                    height: "auto",
                    color: "#007aff",
                  }}
                  src="assets/images/branding-desc1.png"
                />
              </div>
            )}

            {activeImage === 8 && (
              <div className="text4 fade-up">
                <h5 className="mt-3 mb-5">
                  While we all strive to be the best choice, it pays to be the
                  easy choice. Use a memorable number so that you get the first
                  call and first chance to earn a client's business
                </h5>
                <img
                  style={{
                    maxWidth: "200px",
                    height: "auto",
                    color: "#007aff",
                  }}
                  src="assets/images/first-look-desc1.png"
                />
              </div>
            )}

            {activeImage === 9 && (
              <div className="text5 fade-up">
                <h5 className="mt-3 mb-5">
                  A strong number leaves a strong first impression. It can
                  simultaneously let the customer know you are established and
                  allow you to stand out from the crown
                </h5>
                <img
                  style={{
                    maxWidth: "200px",
                    height: "auto",
                    color: "#007aff",
                  }}
                  src="assets/images/impression-desc1.png"
                />
              </div>
            )}
          </div>
        </div>
      </section>

      {/* <section className="values-proposal-area">
        <div className="container">
          <div className="common-heading">
            <h3><span>Value</span> proposals</h3>
            <p>Find the perfect easy to remember phone number for your business.</p>
          </div>
          <div className="row values-proposal-row">
            <div className="col-lg-4">
              <div className="values-proposal-single">
                <div className="proposal-img">
                  <img loading="lazy" src="assets/images/phon.png" alt="phon" />
                </div>
                <div className="proposal-content">
                  <h4>Pick A <span>Number</span></h4>
                  <p>Search by map or the shop numbers page. We can provide suggestions based upon your
                    preferences and have over 20,000 premium vanity numbers to choose from. Flexible payment
                    plans available.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="values-proposal-single">
                <div className="proposal-img">
                  <img loading="lazy" src="assets/images/subtract2.png" alt="subtract1" />
                </div>
                <div className="proposal-content">
                  <h4>Easy<span> Setup</span> </h4>
                  <p>
                    Mumber will get your new number up and running in 48 hours. No need to do anything with
                    your current number. Our call forwarding service will allow you to capture all calls
                    from your new number along with any calls to your old number.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="values-proposal-single">
                <div className="proposal-img">
                  <img loading="lazy" src="assets/images/subtract1.png" alt="subtract2" />
                </div>
                <div className="proposal-content">
                  <h4><span>Brand</span>, Brand, Brand</h4>
                  <p>Our creative team provides free consultations to help develop an effective slogan, and
                    offer strategic guidance. We provide monthly call reports so you can easily track the
                    results from new campaigns using the memorable phone number.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="common-btn">
            <a href="#" className="btn-primaris">Get Started</a>
          </div>
        </div>
      </section> */}
      {/*============================================================
                End values-proposal area
     =============================================================*/}
      {/*============================================================
                start how it work area
     =============================================================*/}
      <section className="how-it-work-area">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="how-it-img">
                <img
                  loading="lazy"
                  src="assets/images/how-it-man.png"
                  alt="woman"
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="how-it-work-content-main">
                <div className="common-heading">
                  <h3>How it Works?</h3>
                </div>
                {/* how it title number */}
                {/* single item */}
                <div className="how-it-title-number-single">
                  <h6>
                    <span className="blue">01.</span> Pick A Number
                  </h6>
                  <p>
                    Search by map or the shop numbers page. We can provide
                    suggestions based upon your preferences and have over 20,000
                    premium vanity numbers to choose from. Flexible payment
                    plans available.
                  </p>
                </div>
                {/* single item */}
                <div className="how-it-title-number-single">
                  <h6>
                    <span className="blue">02.</span> Easy Setup
                  </h6>
                  <p>
                    Mumber will get your new number up and running in 48 hours.
                    No need to do anything with your current number. Our call
                    forwarding service will allow you to capture all calls from
                    your new number along with any calls to your old number.
                  </p>
                </div>
                {/* single item */}
                <div className="how-it-title-number-single">
                  <h6>
                    <span className="blue">03.</span> Brand, Brand, Brand
                  </h6>
                  <p>
                    Our creative team provides free consultations to help
                    develop an effective slogan and offer strategic guidance. We
                    provide monthly call reports so you can easily track the
                    results from new campaigns using the memorable phone number.
                  </p>
                </div>
                {/* how it title number */}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*============================================================
                End how it work area
     =============================================================*/}
      {/*============================================================
                start social proof area
     =============================================================*/}

      {/* <Slider></Slider> */}

      {/*============================================================
                End social proof area
     =============================================================*/}
      {/*============================================================
                start callout area
     =============================================================*/}
      <section className="callout-area">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="common-heading">
                <h3>
                  <span className="blue-x">Market Smarter, </span> Not Harder
                </h3>
                <p>
                  Building a brand is hard work. Make it easier by marketing
                  with a memorable phone number. Your unique number will leave a
                  lasting impression so you are first to mind when there is a
                  need for your service.
                </p>
                <div className="common-btn">
                  <Link to="/contact" className="btn-primaris">
                    Get Started
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="collout-man" style={{ zIndex: "9" }}>
                <img
                  loading="lazy"
                  src="assets/images/footerMan2.png"
                  alt="man"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*============================================================
                End callout area
     =============================================================*/}
    </div>
  );
}

export default Home;
