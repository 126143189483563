import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Validation from "./LoginValidation";
import axios from "axios";

function Login(props) {
  const [values, setValues] = useState({ email: "", password: "" });
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [backendError, setBackendError] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  const handleInput = (event) => {
    setValues((prev) => ({ ...prev, [event.target.name]: event.target.value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const err = Validation(values);
    setErrors(err);
    if (err.email === "" && err.password === "") {
      axios
        .post("https://node.mumber.com/login", values)
        .then((res) => {
          if (res.data.errors) {
            setBackendError(res.data.errors);
          } else {
            setBackendError([]);
            if (res.data.Success === 1) {
              setLoading(false);
              // Set the token, user ID, and user from session storage
              window.localStorage.setItem("token", res.data.token);
              window.localStorage.setItem("userId", res.data.users[0].id);
              window.localStorage.setItem(
                "users",
                JSON.stringify(res.data.users)
              );
              window.localStorage.setItem("isLoggedIn", true);
              toast.success("Welcome", { position: toast.POSITION.TOP_CENTER });
              navigate("/");
            } else {
              toast.error("Incorrect Email or Password", {
                position: toast.POSITION.TOP_CENTER,
              });
            }
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const handleRememberMe = () => {
    setRememberMe((prev) => !prev);
  };

  return (
    <div className="d-flex justify-content-center align-items-center bg-primary pb-5">
      <div className="bg-white p-3 rounded w-25 text-center log">
        <h3 style={{ color: "#0d6efd" }}>Login</h3>
        <br></br>

        {backendError ? (
          backendError.map((e) => (
            <p className="text-danger mb-3">
              Your Email or Password is Incorrect
            </p>
          ))
        ) : (
          <span></span>
        )}

        <form action="" onSubmit={handleSubmit}>
          <div className="mb-3">
            <input
              type="email"
              placeholder="Enter Email"
              name="email"
              onChange={handleInput}
              className="form-control fix-border rounded-0"
            />
            {errors.email && (
              <span className="text-danger">Email should not be empty</span>
            )}
          </div>
          <br></br>
          <div className="mb-3">
            <input
              type="password"
              placeholder="Enter Password"
              name="password"
              onChange={handleInput}
              className="form-control rounded-0"
            />
            {errors.password && (
              <span className="text-danger"> {errors.password}</span>
            )}
          </div>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <label htmlFor="rememberMe" style={{ color: "black" }}>
              <input
                type="checkbox"
                id="rememberMe"
                checked={rememberMe}
                onChange={handleRememberMe}
              />
              <span style={{ paddingLeft: "2px" }}> Remember Me </span>
            </label>
          </div>
          <button
            type="submit"
            className="btn w-100 rounded-0"
            style={{
              color: "black",
              backgroundColor: "#0d6efd",
              borderColor: "#0d6efd",
            }}
          >
            Login
          </button>
          <div>
            <br></br>
            <div>
              <Link
                to="/Register"
                className="regist"
                style={{ color: "blue", marginRight: "30px" }}
              >
                Register
              </Link>

              <Link to="/forgot" style={{ color: "blue", marginLeft: "30px" }}>
                Forgot password
              </Link>
            </div>
          </div>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
}

export default Login;
