import React, { useState, useEffect, useRef } from "react";
import $ from "jquery";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { slice } from "lodash";
import axios from "axios";
import { useParams } from "react-router-dom";
import "./Login.module.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Modal, Button, Form, Table } from "react-bootstrap";
import Preloader from "../Preloader";

function Search() {
  const params = useParams();
  const [buttonDisabled, setbuttonDisabled] = useState(true);

  const [activeMenuItem, setActiveMenuItem] = useState("All");
  const handleMenuClick = (totalCount) => {
    setActiveMenuItem(totalCount);
  };

  const formatPhoneNumber = (phoneNumber) => {
    if (typeof phoneNumber !== "string" || !phoneNumber) {
      return phoneNumber; // or handle the error accordingly
    }

    const cleaned = phoneNumber.replace(/\D/g, "");
    let match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,5})$/);

    if (match) {
      return `${match[1]}${match[2] ? "-" : ""}${match[2]}${
        match[3] ? "-" : ""
      }${match[3]}`;
    }

    return phoneNumber;
  };

  const [selectedPlan, setSelectedPlan] = useState("");

  const handlePlanChange = (event) => {
    setSelectedPlan(event.target.value);

    let selectedPlan = $("#plan_select :selected").text();
    if (selectedPlan != "Plan") {
      $("#buyButton").prop("disabled", false);
    } else {
      $("#buyButton").prop("disabled", true);
    }
    // alert();
  };
  const [totalCount, setTotalCount] = useState(0);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!params.id) {
          const response = await axios.get("https://node.mumber.com/api/get");
          setIsLoading(false);
          setTotalCount(response.data.length); // Set the total count of products
          setPost(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  const navigate = useNavigate();
  const [selectedRow, setSelectedRow] = useState(null);
  const handleShow = () => setSelectedRow(true);
  const handleClose = () => setSelectedRow(false);
  const handleRowClick = (rowData) => {
    setSelectedRow(rowData);
    console.log(rowData);
  };
  const location = useLocation();

  const [data1, setData1] = useState(null);
  const [print, setPrint] = useState(false);

  function getData1(val) {
    setData1(val.target.value);
    setPrint(false);
  }

  // const[search,setSearch]=useState([]);
  const [record, setRecord] = React.useState([]);
  const [sortType, setSortType] = useState("asc");
  const [buttonClicked, setButtonClicked] = useState(false);

  const [post, setPost] = React.useState([]);

  // load more code

  const [isCompleted, setIsCompleted] = useState(false);
  const [index, setIndex] = useState(10);
  const [totalproducts, setTotalproducts] = useState(0);
  const initialPosts = slice(post, 0, index);
  const getData = () => {
    fetch("https://node.mumber.com/api/get")
      .then((res) => res.json())
      .then((json) => {
        setPost(json);
        axios
          .get(`https://node.mumber.com/api/get_d/${record}`)
          .then((response) => setPost(response.data));
      })
      .catch((e) => console.log(e));
  };
  const loadMore = () => {
    setIndex(index + 10);
    console.log(index);
    if (index >= post.length) {
      setIsCompleted(true);
    } else {
      setIsCompleted(false);
    }
  };

  const handleNewest = () => {
    handleMenuClick("Newest");
    setPost([...post].sort((a, b) => a.id - b.id));
    setSortType("desc");
  };
  const handleOldest = () => {
    handleMenuClick("Oldest");
    setPost([...post].sort((a, b) => b.id - a.id));
    setSortType("desc");
  };

  const handleSortpa = () => {
    setPost([...post].sort((a, b) => a.monthly - b.monthly));
    setSortType("desc");
  };

  const handleSortpd = () => {
    setPost([...post].sort((a, b) => b.monthly - a.monthly));
    setSortType("asc");
  };

  const [dataLength, setDataLength] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (params.id) {
        axios
          .get(
            `https://node.mumber.com/api/get_d/${params.id.replace(
              /[(),-]/g,
              ""
            )}`
          )
          .then((res) => {
            setPost(res.data);
            setData1(params.id.replace(/[()\s,-]/g, ""));
            setSelectedSuggestion(params.id);
            setSuggestions([]);
            setShowSuggestions(false);
            setDataLength(res.data.length);
            setIsLoading(false);
            // console.log("malik"+res.data.length)
            // console.log("malik"+res.data.length)
          });
      } else {
        axios.get(`https://node.mumber.com/api/get`).then((res) => {
          setPost(res.data);
          setSuggestions([]);
          setShowSuggestions(false);
          setIsLoading(false);
          // setData1((params.id).replace(/[()\s,-]/g, ""));
        });
      }
    }, 500);
  }, [location]);

  const handle3Click = () => {
    handleMenuClick("Top 3");

    setPost(post.slice(0, 3));
    // alert("ehllo");
    setButtonClicked(true);
    $("#buyButton").prop("disabled", true);
  };

  const fetchData = async () => {
    const response = await fetch("https://node.mumber.com/api/get");
    const jsonData = await response.json();
    setPost(jsonData);
  };

  const users = window.localStorage.getItem("users");
  const parsedUsers = users ? JSON.parse(users) : [];
  const ids = parsedUsers.map((user) => user.id);
  // console.log(ids);
  const userId = ids;

  //   const users = sessionStorage.getItem('users');
  // const parsedUsers = JSON.parse(users);
  // const ids = parsedUsers.map(user => user.id);
  //   console.log(ids);
  // const userId = (ids);

  // const [rowClickStatus, setRowClickStatus] = useState(
  //   JSON.parse(localStorage.getItem('rowClickStatus')) || []
  // );

  const [rowClickStatus, setRowClickStatus] = useState([]);
  const handleClick = (index, userId, productId) => {
    setRowClickStatus((prevStatus) => {
      const updatedStatus = [...prevStatus];
      const currentClickCount = updatedStatus[index]?.clickCount || 0;

      if (currentClickCount === 0) {
        updatedStatus[index] = {
          ...updatedStatus[index],
          iconColor: "#1beebb",
          clickCount: 1,
          productId: productId, // Add productId to the status object
        };
        addToFavorites(userId, productId);
      } else if (currentClickCount === 1) {
        updatedStatus[index] = {
          ...updatedStatus[index],
          iconColor: "",
          clickCount: 0,
          productId: null, // Remove productId from the status object
        };
        deleteFavorite(userId, productId);
      }

      // Store the updated rowClickStatus in local storage
      // localStorage.setItem('rowClickStatus', JSON.stringify(updatedStatus));

      return updatedStatus;
    });
  };

  const addToFavorites = (userId, productId) => {
    fetch("https://node.mumber.com/favorites", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ userId, productId }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to add product to favorites");
        }
        return response.json();
      })
      .then((data) => {
        console.log(data.message); // Optional: Handle the success message
      })
      .catch((error) => {
        console.error("Error adding product to favorites", error);
        // Handle the error condition here, e.g., show an error message to the user
      });
  };

  const deleteFavorite = (userId, productId) => {
    // Send a DELETE request to the server to remove the favorite item from the database
    fetch(`https://node.mumber.com/favorites/${productId}`, {
      method: "DELETE",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to delete favorite");
        }
        return response.json();
      })
      .then((data) => {
        console.log(data.message); // Optional: Handle the success message
      })
      .catch((error) => {
        console.error("Error deleting favorite", error);
        // Handle the error condition here, e.g., show an error message to the user
      });
  };

  const [cartItem, setCartItems] = useState(
    JSON.parse(sessionStorage.getItem("cart"))
      ? JSON.parse(sessionStorage.getItem("cart"))
      : []
  );
  // console.log(cartItem);
  $("header").find(".cart_count").text(cartItem.length);
  sessionStorage.setItem("cart", JSON.stringify(cartItem));

  const handleBuy = () => {
    var prod_id = $(".modal-body").find("table #prod_id").text();
    var phone_no = $(".modal-body").find("table #phone_no").text();
    var plan = $(".modal-body").find("table #plan").text();
    var fees = $(".modal-body").find("#fees").text();

    const cart_products = {
      prod_id: prod_id,
      phone_no: phone_no,
      plan: plan,
      fees: fees,
    };

    let plan_seleted_val = $("body").find("#plan_select").val();

    if (plan_seleted_val !== "") {
      const updatedCart = [...cartItem, cart_products]; // Update cart item
      setCartItems(updatedCart);
      console.log(updatedCart); // Use updatedCart instead of cartItem
      sessionStorage.setItem("cart", JSON.stringify(updatedCart)); // Update session storage with updatedCart
      console.log(sessionStorage.getItem("cart"));
      $("body").find(".modal-header .btn-close").trigger("click");
      toast.success("Number added to cart", {
        position: toast.POSITION.TOP_CENTER,
      });
      navigate("/Cart");
    } else {
      toast.error("Please select a plan", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const modalRef = useRef(null);

  const [logined, setlogined] = useState(false);

  const [login_info, setlogin_info] = useState({
    useremail: "",
    password: "",
  });

  const handleloginchange = (event) => {
    setlogin_info({ ...login_info, [event.target.name]: event.target.value });
  };

  // const handleLoginSubmit = (event) => {
  //   event.preventDefault();
  //   const formData = new FormData();
  //   formData.append("email", login_info.useremail);
  //   formData.append("password", login_info.password);

  //   axios
  //     .post("https://node.mumber.com/login", formData, {
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //     })
  //     .then((res) => {
  //       // alert(res.data);
  //       if (res.data.Success == 1) {

  //         window.localStorage.setItem("token", res.data.token);
  //         window.localStorage.setItem("users", JSON.stringify(res.data.users));
  //         // alert("logined");
  //         window.location.reload();
  //         setlogined(true);
  //         $('#myModal .btn-close').trigger('click');
  //       }
  //     })

  //     .catch((err) => {
  //       console.log(err);
  //       // setErrorMessage('Internal Server Error');
  //     });
  // };

  const handleLoginSubmit = (event) => {
    event.preventDefault();

    if (!login_info.useremail) {
      toast.error("Please enter an email address.");
      return;
    }

    if (!login_info.password) {
      toast.error("Please enter a password.");
      return;
    }

    const formData = new FormData();
    formData.append("email", login_info.useremail);
    formData.append("password", login_info.password);

    axios
      .post("https://node.mumber.com/login", formData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.data.Success === 1) {
          window.localStorage.setItem("token", res.data.token);
          window.localStorage.setItem("users", JSON.stringify(res.data.users));
          window.location.reload();
          setlogined(true);
          $("#myModal .btn-close").trigger("click");
          toast.success("Login successful!");
        } else {
          toast.error("Incorrect email or password. Please try again.");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (window.localStorage.getItem("users")) {
      setlogined(true);
    }
  }, [logined]);

  const [suggestions, setSuggestions] = useState([]);

  const [selectedSuggestion, setSelectedSuggestion] = useState("");
  const [showSuggestions, setShowSuggestions] = useState(false);
  const handleInputChange = (event) => {
    let value = event.target.value;

    if (
      event.nativeEvent.inputType === "deleteContentBackward" &&
      value.length > 0
    ) {
      // alert(value.length)
      if (value.length <= 5) {
        value = value.slice(0, -2);
      } else if (value.length == 1) {
        value = "";
      } else {
        value = value.slice(0, -1);
      }

      // alert(value);
    }
    const startsWithAlphabet = /^[a-zA-Z]/.test(value);

    if (startsWithAlphabet == false) {
      setSelectedSuggestion(formatPhoneNumber(value));
    } else {
      setSelectedSuggestion(value);
    }

    let api_num_value = value.replace(/[(),-]/g, "");

    if (api_num_value.trim() !== "") {
      fetch(`https://node.mumber.com/api/suggestions?query=${api_num_value}`)
        .then((response) => response.json())
        .then((data) => setSuggestions(data))
        .catch((error) => console.error(error));

      setShowSuggestions(true);
    } else {
      setSuggestions([]);
      setShowSuggestions(false);
    }
  };

  // const [totalproducts, setTotalproducts] = useState(0);

  useEffect(() => {
    fetchTotalproducts();
  }, []);

  const fetchTotalproducts = () => {
    fetch("https://node.mumber.com/api/countproducts")
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setTotalproducts(data.totalCount);
        } else {
          console.log(data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleforgotClick = () => {
    window.location.href = `/forgot`;
  };

  const handleregisterClick = () => {
    window.location.href = `/Register`;
  };

  document.addEventListener("click", function (event) {
    var targetElement = event.target;

    // Check if the clicked element or any of its parents have the search area class
    var isClickedInsideSearchArea = targetElement.closest(".search-bar-area");

    if (!isClickedInsideSearchArea) {
      // Clicked outside the search area
      setSuggestions([]);
    }
  });

  return (
    <>
      {isLoading ? (
        <div className="content-wrapper" style={{ marginBottom: "200px" }}>
          <Preloader />
        </div>
      ) : (
        <div>
          <section className="banner-area pages-banner">
            <div className="container">
              <div className="row banner-row align-items-center">
                <div className="col-lg-6">
                  <div className="banner-heading">
                    <h2>
                      What <span>Was</span> <br /> Searched
                    </h2>
                    <p>
                      Find the perfect easy to remember phone number for your
                      business.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="man-img">
                    <img src="/assets/images/list.png" alt="manImg" />
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/*============================================================
                    End banner area
    =============================================================*/}
          {/*============================================================
                    start search bar area
    =============================================================*/}
          <div className="search-bar-area">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <form id="quick-request-submit">
                    <div className="reqest-a-product">
                      <div className="search-icon">
                        <img
                          loading="lazy"
                          src="/assets/images/Ellipse.png"
                          alt="ellipse"
                        />
                      </div>
                      <input
                        className="search-box-main"
                        type="text"
                        placeholder="Search here by: Phone Number, Area Code, Keyword, State, or City"
                        name="phone"
                        id="quick-request-input"
                        value={selectedSuggestion}
                        autoComplete="off"
                        onChange={handleInputChange}
                        onClick={() => {
                          setSuggestions([]); // Clear the suggestions list
                          setShowSuggestions(false); // Hide the suggestions
                        }}
                      />
                      <ul style={{ paddingTop: "40px", marginTop: "-40px" }}>
                        {showSuggestions &&
                          suggestions.map((suggestion, index) => (
                            <li
                              title={suggestion.Description.replace(
                                /[?!]|XYY-YYYY\b/g,
                                ""
                              )}
                              key={index}
                              onClick={() => {
                                setSelectedSuggestion(
                                  formatPhoneNumber(suggestion.phone_no)
                                );
                                setSuggestions([]);
                                setShowSuggestions(false);
                                navigate(
                                  "/Search/" +
                                    formatPhoneNumber(suggestion.phone_no)
                                );
                              }}
                              style={{
                                background: "#fff",
                                padding: "10px 30px",
                                cursor: "pointer",
                                borderBottom: "1px solid rgb(227 227 227)",
                                fontSize: "14px",
                                color: "#333",
                                fontWeight: "bold",
                              }}
                            >
                              <span>
                                {formatPhoneNumber(suggestion.phone_no)}
                              </span>{" "}
                              <b style={{ color: "#999", float: "right" }}>
                                {suggestion.Description.replace(
                                  /[?!]|XYY-YYYY\b/g,
                                  ""
                                ).substring(0, 60)}
                                ...{" "}
                                <span
                                  style={{
                                    color: "#3374ff",
                                    marginLeft: "20px",
                                  }}
                                >
                                  ${suggestion.monthly}/mo
                                </span>
                              </b>
                            </li>
                          ))}
                        {suggestions.length > 0 && (
                          <li>
                            <Link to={`/Search/${selectedSuggestion}`}>
                              <button
                                style={{
                                  width: "100%",
                                  color: "#000",
                                  border: "1px solid #eee",
                                  height: "39px",
                                }}
                              >
                                View All
                              </button>
                            </Link>
                          </li>
                        )}
                      </ul>

                      <Link to={`/Search/${selectedSuggestion}`}>
                        <button className="btn-primaris srh">Search</button>
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          {/*============================================================
                    End search bar area
    =============================================================*/}
          {/*============================================================
                    start searched result area
    =============================================================*/}
          <section className="search-result-area">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="search-result-head">
                    <h2>Searched Results</h2>
                    <div className="search-menu-numbers">
                      <div className="search-menu">
                        <ul>
                          <li>
                            <button
                              className="border-0"
                              style={{ background: "white" }}
                            >
                              <a
                                onClick={fetchData}
                                className={
                                  activeMenuItem === "All" ? "active" : ""
                                }
                              >
                                Numeric
                              </a>
                            </button>
                          </li>
                          {/* <li>
                          <button className="border-0" style={{background:"white"}}>
                            <a onClick={handle3Click} className={activeMenuItem === "Top 3" ? "active" : ""}>
                              Top 3
                            </a>
                            </button>
                          </li> */}
                          <li>
                            <button
                              className="border-0"
                              style={{ background: "white" }}
                            >
                              <a
                                onClick={handleSortpa}
                                className={
                                  activeMenuItem === "Low Price Firstly"
                                    ? "active"
                                    : ""
                                }
                              >
                                Low Price Firstly
                              </a>
                            </button>
                          </li>
                          <li>
                            <button
                              className="border-0"
                              style={{ background: "white" }}
                            >
                              <a
                                onClick={handleSortpd}
                                className={
                                  activeMenuItem === "High Price Firstly"
                                    ? "active"
                                    : ""
                                }
                              >
                                High Price Firstly
                              </a>
                            </button>
                          </li>
                          {/* <li> */}
                          {/* <button className="border-0" style={{background:"white"}}>
                            <a onClick={handleNewest} className={activeMenuItem === "Newest" ? "active" : ""}>
                              Newest
                            </a>
                            </button>
                          </li>
                          <li>
                          <button className="border-0" style={{background:"white"}}>
                            <a onClick={handleOldest} className={activeMenuItem === "Oldest" ? "active" : ""}>
                              Oldest
                            </a>
                            </button>
                          </li> */}
                        </ul>
                      </div>

                      <div className="search-found-numbers">
                        {/* {activeMenuItem === "All" || handleMenuClick === "All"? (
      <span>
        Saved: <label><b>{totalCount}</b></label> Numbers
      </span>
    ) : (
      <span>
        Saved: <label><b>{dataLength}</b></label> Numbers
      </span>
    )} */}

                        <span>
                          Found:{" "}
                          <label>
                            <b>{dataLength}</b>
                          </label>{" "}
                          results
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="search-result-content">
                    <div className="search-table">
                      <table className="table">
                        <tbody>
                          {initialPosts.map((dataObj, index) => {
                            return (
                              <tr key={dataObj.id}>
                                <td className="search-serial-no">
                                  {index + 1}
                                </td>
                                <td className="numbers">
                                  {formatPhoneNumber(dataObj.phone_no)}
                                </td>
                                <td className="license">License fee</td>
                                <td className="price text-lowercase">
                                  ${parseFloat(dataObj.monthly).toFixed(2)}/mo
                                </td>
                                {/* <td><button onClick={() => handleShow(dataObj)}>Show Details</button></td> */}

                                <td className="moreinfo-button">
                                  <Link
                                    to={`/Phone/${formatPhoneNumber(
                                      dataObj.phone_no
                                    )}`}
                                  >
                                    <Button
                                      variant="primary"
                                      onClick={() => handleRowClick(dataObj)}
                                      style={{
                                        width: "123px",
                                        height: "51px",

                                        border: "1px solid #E4E7F0",
                                        borderRadius: "50px",
                                        background: "#FFFFFF",
                                        color: "#8790AE",
                                      }}
                                    >
                                      More Info
                                    </Button>
                                  </Link>
                                </td>
                                <td
                                  style={{ borderTop: "none" }}
                                  className="love-icon"
                                >
                                  {" "}
                                  {logined == false ? (
                                    <i
                                      className="fas fa-heart"
                                      type="button"
                                      data-bs-toggle="modal"
                                      data-bs-target="#myModal"
                                    />
                                  ) : (
                                    <i
                                      className="fas fa-heart"
                                      style={{
                                        color:
                                          rowClickStatus[index]?.iconColor ||
                                          "",
                                        border: "none",
                                      }}
                                      onClick={() =>
                                        handleClick(index, userId, dataObj.id)
                                      } // Pass the productId as an argument
                                    />
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                        <div className="row">
                          <div className="button">
                            {/* <a href="#">Load more</a> */}

                            {isCompleted ? (
                              <button
                                onClick={loadMore}
                                type="button"
                                className="btn btn-danger disabled"
                                style={{
                                  borderRadius: "50px",
                                  width: "148px",
                                  height: "51px",
                                  border: "none",
                                  fontSize: "19px",
                                }}
                              >
                                That's It
                              </button>
                            ) : (
                              <button
                                onClick={loadMore}
                                type="button"
                                className="button"
                                style={{
                                  borderRadius: "50px",
                                  width: "148px",
                                  height: "51px",
                                  fontSize: "19px",
                                  border: "none",
                                  background: "#F0F0F0",
                                }}
                              >
                                Load More
                              </button>
                            )}
                          </div>
                        </div>
                      </table>

                      {selectedRow && handleShow && (
                        <Modal
                          show={true}
                          onHide={handleClose}
                          dialogClassName="modal-90w modal-80h custom_modal"
                          animation={false}
                        >
                          <Modal.Header closeButton>
                            <Modal.Title>Product Detail</Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <Table
                              striped
                              bordered
                              hover
                              style={{ display: "none" }}
                            >
                              <tbody>
                                <tr>
                                  <td id="prod_id">104</td>
                                  <br></br>

                                  <td id="phone_no">{selectedRow.phone_no}</td>
                                  <br></br>

                                  <td id="yearly">{selectedRow.yearly}</td>
                                  <br></br>
                                  <td id="monthly">${selectedRow.monthly}</td>
                                  <br></br>
                                  <Form.Select
                                    value={selectedPlan}
                                    onChange={handlePlanChange}
                                  >
                                    <option value="">Plan</option>
                                    <option value={selectedRow.monthly}>
                                      Monthly
                                    </option>
                                    <option value={selectedRow.yearly}>
                                      Yearly
                                    </option>
                                  </Form.Select>
                                  {selectedPlan && (
                                    <div>
                                      <h4>Selected Plan:</h4>
                                      <td id="fee">${selectedPlan}</td>
                                    </div>
                                  )}
                                </tr>
                              </tbody>
                            </Table>
                            <h2>
                              <span style={{ color: "black" }}>
                                {formatPhoneNumber(selectedRow.phone_no)}
                              </span>
                            </h2>
                            <Form.Select
                              value={selectedPlan}
                              id="plan_select"
                              onChange={handlePlanChange}
                            >
                              <option value="">Plan</option>
                              <option value={selectedRow.monthly}>
                                Monthly
                              </option>
                              <option value={selectedRow.yearly}>Yearly</option>
                            </Form.Select>
                            {/* <h3>
              <span style={{ color: '#999' }}>${selectedRow.monthly}</span>
            </h3>
            <h3>
              <span style={{ color: '#999' }}>${selectedRow.yearly}</span>
            </h3> */}

                            {selectedPlan && (
                              <div>
                                <h4>Selected Plan:</h4>
                                <h1 style={{ color: "#999" }} id="fees">
                                  {selectedPlan}
                                </h1>
                              </div>
                            )}
                          </Modal.Body>
                          <Modal.Footer>
                            <Button
                              variant="secondary"
                              style={{ marginTop: "4px" }}
                              onClick={handleClose}
                            >
                              Close
                            </Button>

                            <Button
                              variant="primary"
                              id="buyButton"
                              onClick={handleBuy}
                            >
                              Add to cart{buttonDisabled}
                            </Button>
                          </Modal.Footer>
                        </Modal>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="modal" id="myModal" ref={modalRef}>
              <div className="modal-dialog">
                <div className="modal-content">
                  {/* <!-- Modal Header --> */}
                  <div className="container-fluid my-2">
                    <div className="modal-header">
                      <img src="assets/images/Unionblack.png" alt="" />
                      <h4
                        className="modal-title text-muted ps-lg-3 fs-2"
                        style={{
                          marginLeft: "75px",
                        }}
                      >
                        SIGN-IN
                      </h4>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                      ></button>
                    </div>

                    {/* <!-- Modal body --> */}

                    <form id="login_form" onSubmit={handleLoginSubmit}>
                      <div className="mb-3">
                        <label for="exampleInputEmail1" className="form-label">
                          Email*
                        </label>
                        <input
                          onChange={handleloginchange}
                          type="email"
                          name="useremail"
                          className="form-control border border-1 p-3"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          placeholder="jonsnow@gmail.com"
                        />
                      </div>
                      <div className="mb-3">
                        <label
                          for="exampleInputPassword1"
                          className="form-label"
                        >
                          Password*
                        </label>
                        <input
                          onChange={handleloginchange}
                          type="password"
                          name="password"
                          className="form-control border border-1 p-3 dot_place"
                          id="exampleInputPassword1"
                          placeholder="............ "
                        />
                      </div>
                      <div className="d-flex justify-content-between">
                        <div>
                          <input type="checkbox" />
                          <span style={{ paddingLeft: "2px" }}>
                            {" "}
                            Remember Me{" "}
                          </span>
                        </div>
                        <Link to="/forgot" onClick={handleforgotClick}>
                          <span style={{ color: "#3374ff" }}>
                            Forgot Password?
                          </span>
                        </Link>
                      </div>
                      <button
                        type="submit"
                        className="btn-primaris sign_in_btn mt-4 fw-semibold"
                      >
                        Sign in
                      </button>
                      <div className="text-center py-3">or</div>
                      <Link to="/register" onClick={handleregisterClick}>
                        <button className="create_an_btn mb-4 border border-1">
                          Create an Account
                        </button>
                      </Link>
                    </form>
                  </div>

                  {/* <!-- Modal footer --> */}
                </div>
              </div>
            </div>
          </section>
        </div>
      )}

      <section className="callout-area">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="common-heading">
                <h3>
                  <span className="blue-x">Market Smarter, </span> Not Harder
                </h3>
                <p>
                  Building a brand is hard work. Make it easier by marketing
                  with a memorable phone number. Your unique number will leave a
                  lasting impression so you are first to mind when there is a
                  need for your service.
                </p>
                <div className="common-btn">
                  <Link to="/contact" className="btn-primaris">
                    Get Started
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="collout-man">
                <img
                  loading="lazy"
                  src="/assets/images/footerMan2.png"
                  alt="man"
                />
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </section>
    </>
  );
}

export default Search;
