import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

function BlogContent() {
  const [blog, setBlog] = useState({});
  const [latest, setLatest] = useState([]);

  useEffect(() => {
    const id = window.location.pathname.split("/").pop();
    fetch(`https://node.mumber.com/api/blog/${id}`)
      .then((response) => response.json())
      .then((data) => setBlog(data.data))
      .catch((error) => console.error(error));
  }, []);

  const formatDate = (dateString) => {
    const dateObj = new Date(dateString);
    const formattedDate = `${
      dateObj.getMonth() + 1
    }/${dateObj.getDate()}/${dateObj.getFullYear()}`;
    return formattedDate;
  };

  const loadblog = async () => {
    try {
      const response = await axios.get(
        "https://node.mumber.com/api/latestblogs"
      );
      const filteredBlogs = response.data.data.filter((b) => b.Id !== blog.Id);
      setLatest(filteredBlogs);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    loadblog();
  }, [blog]);

  const handleOtherBlogClick = (blogId) => {
    window.location.href = `/BlogContent/${blogId}`; // Redirect to the new page
  };

  return (
    <>
      <section className="banner-area pages-banner" style={{ height: "620px" }}>
        <div className="container"></div>
      </section>
      {/*============================================================
                    End banner area
    =============================================================*/}
      {/*============================================================
                    start single article area
    =============================================================*/}
      <section className="article-area">
        <div className="container">
          <div className="col-lg-12">
            <div className="article-content-area">
              <article>
                <div className="article-topbar">
                  <div className="article-back">
                    <div className="icon">
                      <img src="assets/images/left.png" alt="" />
                    </div>
                    <Link to="/blog">
                      <span>Back</span>
                    </Link>
                  </div>
                  <div className="article-date">
                    <span>{formatDate(blog.created_at)}</span>
                  </div>
                </div>

                <div className="article-content">
                  <h2>{blog.Title}</h2>

                  <img
                    src={`https://node.mumber.com/uploads/${blog.images}`}
                    alt=""
                  />
                  <p
                    dangerouslySetInnerHTML={{ __html: `${blog.Description}` }}
                  />

                  <div className="share-area" style={{ float: "right" }}>
                    <ul style={{ float: "right" }}>
                      <li>
                        <a href="#">
                          <i className="fab fa-facebook-f" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-twitter" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fas fa-envelope" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-instagram" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </section>
      {/*============================================================
                    End single article area
    =============================================================*/}
      {/*============================================================
                    Start others articles area
    =============================================================*/}
      <section className="others-article-area">
        <div className="container">
          <div className="row">
            <div className="section-heading">
              <h2>Other articles</h2>
            </div>
          </div>
          <div className="row others-article">
            {/* single item */}
            {latest.map((blog) => {
              return (
                <div className="col-lg-4 col-sm-6" key={blog.Id}>
                  <div className="blog-single-item">
                    <Link
                      to={`/BlogContent/${blog.Id}`}
                      onClick={() => handleOtherBlogClick(blog.Id)}
                    >
                      <img src={blog.images} alt="" />
                    </Link>
                    <h3>
                      <Link
                        to={`/BlogContent/${blog.Id}`}
                        onClick={() => handleOtherBlogClick(blog.Id)}
                      >
                        {blog.Title}
                      </Link>
                    </h3>
                    <p>{blog.ShortDescription}.</p>
                    <div className="post-date">
                      <span style={{ float: "right" }}>
                        {formatDate(blog.created_at)}
                      </span>
                    </div>
                    <div className="author-date-area">
                      <div className="author-area">
                        {/* <div className="author-image">
                          <img src={blog.images} alt="" />
                        </div>
                        <span>Marvin McKinney</span> */}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
            {/* single item */}
          </div>
        </div>
      </section>

      <section className="callout-area">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="common-heading">
                <h3>
                  <span className="blue-x">Market Smarter, </span> Not Harder
                </h3>
                <p>
                  Building a brand is hard work. Make it easier by marketing
                  with a memorable phone number. Your unique number will leave a
                  lasting impression so you are first to mind when there is a
                  need for your service.
                </p>
                <div className="common-btn">
                  <Link to="/contact" className="btn-primaris">
                    Get Started
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="collout-man">
                <img
                  loading="lazy"
                  src="/assets/images/footerMan2.png"
                  alt="man"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default BlogContent;
