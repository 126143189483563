import React, { useEffect, useState } from "react";
import { Dropdown, Badge } from "react-bootstrap";
import axios from "axios";
import { FaCheck } from "react-icons/fa";
import { slice } from "lodash";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Preloader from "../Preloader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import $ from "jquery";

const Phone = () => {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);

  const formatPhoneNumber = (phoneNumber) => {
    if (typeof phoneNumber !== "string" || !phoneNumber) {
      return phoneNumber; // or handle the error accordingly
    }
    const cleaned = phoneNumber.replace(/\D/g, "");

    let match;
    // if (cleaned.length === 10) {
    //   match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    // } else {
    match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,5})$/);
    // }

    if (match) {
      return `${match[1]}${match[2] ? "-" : ""}${match[2]}${
        match[3] ? "-" : ""
      }${match[3]}`;
    }

    return phoneNumber;
  };

  const [total_price, settotal_price] = useState("");
  const [suggestions, setSuggestions] = useState([]);

  const [selectedSuggestion, setSelectedSuggestion] = useState("");
  const [showSuggestions, setShowSuggestions] = useState(false);
  const handleInputChange = (event) => {
    let value = event.target.value;

    if (
      event.nativeEvent.inputType === "deleteContentBackward" &&
      value.length > 0
    ) {
      // alert(value.length)
      if (value.length <= 5) {
        value = value.slice(0, -2);
      } else if (value.length == 1) {
        value = "";
      } else {
        value = value.slice(0, -1);
      }

      // alert(value);
    }

    const startsWithAlphabet = /^[a-zA-Z]/.test(value);

    if (startsWithAlphabet == false) {
      setSelectedSuggestion(formatPhoneNumber(value));
    } else {
      setSelectedSuggestion(value);
    }

    // console.log(startsWithAlphabet);

    let api_num_value = value.replace(/[(),-]/g, "");
    if (api_num_value.trim() !== "") {
      fetch(`https://node.mumber.com/api/suggestions?query=${api_num_value}`)
        .then((response) => response.json())
        .then((data) => setSuggestions(data))
        .catch((error) => console.error(error));

      setShowSuggestions(true);
    } else {
      setSuggestions([]);
      setShowSuggestions(false);
    }
  };

  const params = useParams();
  const [record, setRecord] = React.useState([]);
  const [post, setPost] = React.useState([]);

  useEffect(() => {
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (params.id) {
      axios
        .get(
          `https://node.mumber.com/api/get_d/${params.id.replace(
            /[(),-]/g,
            ""
          )}`
        )
        .then((res) => {
          setPost(res.data[0]);

          settotal_price(res.data[0].monthly);
        });
    }
  }, [location]);

  const [selectedPlan, setSelectedPlan] = useState("");
  const handlePlanChange = (event) => {
    setSelectedPlan(event.target.value);

    let selected_plan = event.target.options[event.target.selectedIndex].text;

    let plan_val = $("#plan_select :selected").val();
    // console.log(plan_val);
    if (plan_val != "") {
      if (selected_plan == "Monthly") {
        $("#fee").html(
          "$" + (Math.round(plan_val * 100) / 100).toFixed(2) + "/mo"
        );
      } else {
        if (selected_plan == "Yearly") {
          $("#fee").html(
            "$" + (Math.round(plan_val * 100) / 100).toFixed(2) + "/yr"
          );
        }
      }

      settotal_price(plan_val);
    }

    if (plan_val == 0 && plan_val != "") {
      $("#fee").html("Ask for price");
      settotal_price(plan_val);
    }
    // alert(plan_val);
    // alert();
  };
  const navigate = useNavigate();
  const [cartItem, setCartItems] = useState(
    JSON.parse(sessionStorage.getItem("cart")) || []
  );
  const [cartPhoneItem, setCartPhoneItems] = useState(
    JSON.parse(sessionStorage.getItem("cart_phones")) || []
  );
  console.log(cartItem);
  console.log(cartPhoneItem);
  $("header").find(".cart_count").text(cartItem.length);

  const handleBuy = () => {
    var prod_id = $("#prod_id").val();
    var phone_no = $("#phone_no").val();
    var plan = $("#plan_select :selected").text();
    var fees = $("#fee_price").val();

    // alert(fees);

    if (fees != 0) {
      const cart_products = {
        prod_id: prod_id,
        phone_no: phone_no,
        plan: plan,
        fees: fees,
      };

      let plan_seleted_val = $("body").find("#plan_select :selected").val();

      if (plan_seleted_val !== "") {
        if (cartPhoneItem.includes(phone_no)) {
          toast.error("This number is already added to your cart!", {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (cartPhoneItem.length > 0) {
          toast.error("You can buy one number at a time!", {
            position: toast.POSITION.TOP_CENTER,
          });
        } else {
          const updatedCartPhoneItems = [...cartPhoneItem, phone_no];
          sessionStorage.setItem(
            "cart_phones",
            JSON.stringify(updatedCartPhoneItems)
          );
          console.log(sessionStorage.getItem("cart_phones"));

          setCartItems((prevCartItems) => {
            const updatedCartItems = [...prevCartItems, cart_products];
            console.log(updatedCartItems);
            sessionStorage.setItem("cart", JSON.stringify(updatedCartItems));
            console.log(sessionStorage.getItem("cart"));
            navigate("/Cart");
            return updatedCartItems;
          });
        }
      } else {
        toast.error("please select a plan", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } else {
      if (plan == "Yearly") {
        navigate("/Contact");
      } else {
        toast.error("please select a plan", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  document.addEventListener("click", function (event) {
    var targetElement = event.target;

    // Check if the clicked element or any of its parents have the search area class
    var isClickedInsideSearchArea = targetElement.closest(".search-bar-area");

    if (!isClickedInsideSearchArea) {
      // Clicked outside the search area
      setSuggestions([]);
    }
  });
  return (
    <>
      {isLoading ? (
        <div className="content-wrapper" style={{ marginBottom: "200px" }}>
          {/* Replace 'Preloader' with the appropriate loading spinner component */}
          <Preloader />
        </div>
      ) : (
        <section>
          <section className="banner-area home-banner">
            <div className="container">
              <div className="row banner-row align-items-center">
                <div className="col-lg-6">
                  <div className="banner-heading">
                    <h2>
                      <span>Get</span> Your Dream Number
                    </h2>
                    <p>
                      Find the perfect easy to remember phone number for your
                      business.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="man-img">
                    <img src="/assets/images/bannerMan1.png" alt="manImg" />
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div className="search-bar-area">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <form id="quick-request-submit">
                    <div className="reqest-a-product">
                      <div className="search-icon">
                        <img
                          loading="lazy"
                          src="/assets/images/Ellipse.png"
                          alt="ellipse"
                        />
                      </div>

                      <input
                        type="text"
                        className="search-box-main"
                        placeholder="Search here by: Phone Number, Area Code, Keyword, State, or City"
                        name="phone"
                        id="quick-request-input"
                        value={selectedSuggestion}
                        onChange={handleInputChange}
                        autoComplete="off"
                        onClick={() => {
                          setSuggestions([]); // Clear the suggestions list
                          setShowSuggestions(false); // Hide the suggestions
                        }}
                      />
                      <ul
                        style={{
                          paddingTop: "40px",
                          marginTop: "-40px",
                        }}
                      >
                        {showSuggestions &&
                          suggestions.map((suggestion, index) => (
                            <li
                              title={suggestion.Description.replace(
                                /[?!]|XYY-YYYY\b/g,
                                ""
                              )}
                              key={index}
                              onClick={() => {
                                setSelectedSuggestion(
                                  formatPhoneNumber(suggestion.phone_no)
                                );
                                setSuggestions([]);
                                setShowSuggestions(false);

                                navigate(
                                  "/Search/" +
                                    formatPhoneNumber(suggestion.phone_no)
                                );
                              }}
                              style={{
                                background: "#fff",
                                padding: "10px 30px",
                                cursor: "pointer",
                                borderBottom: "1px solid rgb(227 227 227)",
                                fontSize: "14px",
                                color: "#333",
                                fontWeight: "bold",
                              }}
                            >
                              <span>
                                {formatPhoneNumber(suggestion.phone_no)}
                              </span>{" "}
                              <b style={{ color: "#999", float: "right" }}>
                                {suggestion.Description.replace(
                                  /[?!]|XYY-YYYY\b/g,
                                  ""
                                ).substring(0, 60)}
                                ...
                                <span
                                  style={{
                                    color: "#3374ff",
                                    marginLeft: "20px",
                                  }}
                                >
                                  ${suggestion.monthly}/mo
                                </span>
                              </b>
                            </li>
                          ))}
                      </ul>

                      <Link to={`/Search/${selectedSuggestion}`}>
                        <button className="btn-primaris srh">Search</button>
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div
              className="row"
              style={{
                background: "#fff",
                border: "1px solid #eee",
                padding: "15px",
                marginTop: "40px",
                marginBottom: "40px",
              }}
            >
              <div className="col-md-12 common-heading">
                <h1
                  style={{
                    fontSize: "41px",
                    marginTop: "17px",
                  }}
                >
                  #{formatPhoneNumber(post.phone_no)}
                </h1>

                <span
                  style={{
                    position: "absolute",
                    right: 0,
                    top: "5px",
                    color: "#838383",
                  }}
                >
                  License fee
                </span>
                <span
                  id="fee"
                  style={{
                    position: "absolute",
                    right: 0,
                    fontSize: "41px",
                    fontWeight: 700,
                    color: "#007bfd",
                  }}
                >
                  ${parseFloat(post.monthly).toFixed(2)}/mo
                </span>
                <div class="form-group">
                  <h3
                    style={{
                      fontSize: "20px",
                      marginTop: "19px",
                      color: "#505050",
                    }}
                  >
                    Plan
                  </h3>
                  <select
                    id="plan_select"
                    onChange={handlePlanChange}
                    style={{
                      height: "41px",
                      width: "300px",
                    }}
                  >
                    <option value="">Choose</option>
                    <option value={parseFloat(post.monthly).toFixed(2)}>
                      Monthly
                    </option>
                    <option value={parseFloat(post.yearly).toFixed(2)}>
                      Yearly
                    </option>
                  </select>

                  <input type="hidden" value={post.id} id="prod_id" />
                  <input type="hidden" value={post.phone_no} id="phone_no" />
                  <input type="hidden" value={total_price} id="fee_price" />

                  <button
                    onClick={handleBackClick}
                    style={{
                      position: "absolute",
                      right: "77px",
                      border: 0,
                      background: "#2a71f9",
                      padding: "11px 20px",
                      color: "#fff",
                    }}
                  >
                    Back
                  </button>

                  <button
                    id="buy"
                    onClick={handleBuy}
                    style={{
                      position: "absolute",
                      right: 0,
                      border: 0,
                      background: "#a3f7d5",
                      padding: "11px 20px",
                    }}
                  >
                    Buy
                  </button>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
        </section>
      )}
    </>
  );
};

export default Phone;
