import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <>
      {/*============================================================
                start footer area
     =============================================================*/}

      <footer className="footer-area">
        <div className="container">
          <div className="row footer-top-row">
            <div className="col-lg-3">
              <div className="footer-logo">
                <Link to="/">
                  <img
                    loading="lazy"
                    src="/assets/images/logo.png"
                    alt="logo"
                  />
                </Link>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="footer-address d-flex">
                <div className="single-item">
                  <h5>Address:</h5>
                  <a href="#">
                    6720 Main St<br></br>
                    #400<br></br>
                    Williamsville, NY 14221
                  </a>
                </div>
                <div className="single-item">
                  <h5>Phone:</h5>
                  <a href="tel:+1 (838) 333-3333">+1 (838) 333-3333</a>
                </div>
                <div className="single-item">
                  <h5>Email:</h5>
                  <a href="mailto:support@ mumber.com">support@mumber.com</a>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="footer-contact">
                <Link to="/contact" className="btn-primaris">
                  Contact Us
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row justify-content-between copy-write-row">
            <div className="col-6">
              <div className="copy-write">
                <p>
                  © 2023 Mumber. |{" "}
                  <Link to="/privacy-policy" style={{ color: "#eee" }}>
                    Privacy Policy
                  </Link>{" "}
                  |{" "}
                  <Link to="/license-agreement" style={{ color: "#eee" }}>
                    License Agreement
                  </Link>
                </p>
              </div>
            </div>
            <div className="col-6">
              <div className="social-links">
                <ul>
                  <li>
                    <a href="https://www.facebook.com" target="_blank">
                      <img
                        className="fb"
                        loading="lazy"
                        src="/assets/images/fb.png"
                        alt="fb"
                      />
                    </a>
                  </li>
                  {/* <li>
                  <a href="https://www.instagram.com" target="_blank"><img loading="lazy" src="/assets/images/insta.png" alt="insta" /></a>
                </li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
