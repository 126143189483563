import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

function Faqcontent() {
  const [faq, setFaq] = useState({});
  const [latest, setLatest] = useState([]);

  useEffect(() => {
    const id = window.location.pathname.split("/").pop();
    fetch(`https://node.mumber.com/api/faq/${id}`)
      .then((response) => response.json())
      .then((data) => setFaq(data.data))
      .catch((error) => console.error(error));
  }, []);

  const loadFaq = async () => {
    try {
      const response = await axios.get(
        "https://node.mumber.com/api/latestfaqs"
      );
      const filteredFaqs = response.data.data.filter((b) => b.Id !== faq.id);
      setLatest(filteredFaqs); // Update latest state with filtered FAQs
    } catch (error) {
      console.log(error);
      // toast.error("Failed to fetch data");
    }
  };

  useEffect(() => {
    loadFaq();
  }, [faq]);

  const handleOtherFaqClick = (faqId) => {
    window.location.href = `/faqContent/${faqId}`; // Redirect to the new page
  };

  return (
    <>
      <section className="banner-area pages-banner" style={{ height: "620px" }}>
        <div className="container"></div>
      </section>
      {/*============================================================
                    End banner area
    =============================================================*/}
      {/*============================================================
                    start single article area
    =============================================================*/}
      <section className="article-area">
        <div className="container">
          <div className="col-lg-12">
            <div className="article-content-area">
              <article>
                <div className="article-topbar">
                  <div className="article-back">
                    <div className="icon">
                      <img src="assets/images/left.png" alt="" />
                    </div>
                    <Link to="/FAQ">
                      <span>Back</span>
                    </Link>
                  </div>
                  <div className="article-date">
                    <span>02.21.2024</span>
                  </div>
                </div>
                <div className="article-content">
                  <h2 dangerouslySetInnerHTML={{ __html: `${faq.Title}` }} />
                  <p>{faq.ShortDescription}</p>
                  <img
                    style={{ width: "100%" }}
                    src={`https://node.mumber.com/uploads/${faq.image}`}
                    alt=""
                  />
                  <p
                    dangerouslySetInnerHTML={{ __html: `${faq.Description}` }}
                  />

                  {/* <h2>Praesent dapibus, neque id cursus faucibus.</h2>
                    <div className="row">
                      <div className="col-lg-6">
                        <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros. Nullam malesuada erat ut turpis. Suspendisse urna nibh, viverra non, semper suscipit, posuere a, pede.</p>
                        <p>Praesent dapibus, neque id cursus faucibus, tortor neque egestas auguae, eu vulputate magna eros eu erat. Aliquam erat volutpat. Nam dui mi, tincidunt quis, accumsan porttitor, facilisis luctus, metus.
                          Phasellus ultrices nulla quis nibh. Quisque a lectus. Donec consectetuer ligula vulputate sem tristique cursus. Nam nulla quam, gravida non, commodo a, sodales sit amet, nisi.</p>
                        <p>Morbi in sem quis dui placerat ornare. Pellentesque odio nisi, euismod in, pharetra a, ultricies in, diam. Sed arcu. Cras consequat.</p>
                      </div>
                      <div className="col-lg-6">
                        <p>Donec nec justo eget felis facilisis fermentum. Aliquam porttitor mauris sit amet orci. Aenean dignissim pellentesque felis.</p>
                        <p>
                          Morbi in sem quis dui placerat ornare. Pellentesque odio nisi, euismod in, pharetra a, ultricies in, diam. Sed arcu. Cras consequat.</p>
                        <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros. Nullam malesuada erat ut turpis. Suspendisse urna nibh, viverra non, semper suscipit, posuere a, pede. Donec nec justo eget felis facilisis fermentum. Aliquam porttitor mauris sit amet orci. Aenean dignissim pellentesque felis.</p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <img src={faq.images} alt="" />
                      </div>
                      <div className="col-md-6">
                        <img src="/assets/images/article3.png" alt="" />
                      </div>
                    </div>
                    <h2>Praesent dapibus, neque id cursus faucibus, tortor neque egestas auguae</h2>
                    <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros. Nullam malesuada erat ut turpis. Suspendisse urna nibh, viverra non, semper suscipit, posuere a, pede.
                      Donec nec justo eget felis facilisis fermentum. Aliquam porttitor mauris sit amet orci. Aenean dignissim pellentesque felis.
                      Morbi in sem quis dui placerat ornare. Pellentesque odio nisi, euismod in, pharetra a, ultricies in, diam. Sed arcu. Cras consequat. </p>
                    <p>Praesent dapibus, neque id cursus faucibus, tortor neque egestas auguae, eu vulputate magna eros eu erat. Aliquam erat volutpat. Nam dui mi, tincidunt quis, accumsan porttitor, facilisis luctus, metus.
                      Phasellus ultrices nulla quis nibh. Quisque a lectus. Donec consectetuer ligula vulputate sem tristique cursus. Nam nulla quam, gravida non, commodo a, sodales sit amet, nisi. Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros. Nullam malesuada erat ut turpis. Suspendisse urna nibh, viverra non, semper suscipit, posuere a, pede.</p>
                    <p>Donec nec justo eget felis facilisis fermentum. Aliquam porttitor mauris sit amet orci. Aenean dignissim pellentesque felis.
                      Morbi in sem quis dui placerat ornare. Pellentesque odio nisi, euismod in, pharetra a, ultricies in, diam. Sed arcu. Cras consequat.</p>
                    <p>Praesent dapibus, neque id cursus faucibus, tortor neque egestas auguae, eu vulputate magna eros eu erat. Aliquam erat volutpat. Nam dui mi, tincidunt quis, accumsan porttitor, facilisis luctus, metus.
                      Phasellus ultrices nulla quis nibh. Quisque a lectus. Donec consectetuer ligula vulputate sem tristique cursus. Nam nulla quam, gravida non, commodo a, sodales sit amet, nisi.</p>
                */}
                </div>
                {/* <div className="author-share-area">
                    <div className="author-area">
                      <div className="author-image">
                        <img src="/assets/images/author12.png" alt="" />
                      </div>
                      <span>Marvin McKinney</span>
                    </div>
                    <div className="share-area">
                      <ul>
                        <li><a href="#"><i className="fab fa-facebook-f" /></a></li>
                        <li><a href="#"><i className="fab fa-twitter" /></a></li>
                        <li><a href="#"><i className="fas fa-envelope" /></a></li>
                        <li><a href="#"><i className="fab fa-instagram" /></a></li>
                      </ul>
                    </div>
                  </div> */}
              </article>
            </div>
          </div>
        </div>
      </section>
      {/*============================================================
                    End single article area
    =============================================================*/}
      {/*============================================================
                    Start others articles area
    =============================================================*/}
      <section className="others-article-area">
        <div className="container">
          <div className="row">
            <div className="section-heading">
              <h2>Other articles</h2>
            </div>
          </div>
          <div className="row others-article">
            {/* single item */}
            {latest.map((faq) => {
              return (
                <div key={faq.Id} className="col-lg-4 col-sm-6">
                  <div className="faq-single-item">
                    <Link
                      to={`/Faqcontent/${faq.Id}`}
                      onClick={() => handleOtherFaqClick(faq.Id)}
                    >
                      <img src={faq.images} alt="" />
                    </Link>
                    <h3>
                      {" "}
                      <Link
                        to={`/Faqcontent/${faq.Id}`}
                        onClick={() => handleOtherFaqClick(faq.Id)}
                      >
                        {faq.Title}
                      </Link>
                    </h3>
                    <p>{faq.ShortDescription}.</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      <section className="callout-area">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="common-heading">
                <h3>
                  <span className="blue-x">Market Smarter, </span> Not Harder
                </h3>
                <p>
                  Building a brand is hard work. Make it easier by marketing
                  with a memorable phone number. Your unique number will leave a
                  lasting impression so you are first to mind when there is a
                  need for your service.
                </p>
                <div className="common-btn">
                  <Link to="/contact" className="btn-primaris">
                    Get Started
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="collout-man">
                <img
                  loading="lazy"
                  src="/assets/images/footerMan2.png"
                  alt="man"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Faqcontent;
