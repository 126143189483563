import React from "react";
import { Link } from "react-router-dom";

const Checkout2 = () => {
    return(
    <section className="callout-area">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-lg-6">
                    <div className="common-heading">
                    <h3><span className="blue-x">Market Smarter, </span> Not Harder</h3>
              <p>Building a brand is hard work. Make it easier by marketing with a memorable
                 phone number. Your unique number will leave a
                 lasting impression so you are first to mind when there is a need for your service.</p>
                        <div className="common-btn">
                        <Link to="/contact" className="btn-primaris">Get Started</Link>

                        </div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="collout-man">
                        <img
                            loading="lazy"
                            src="assets/images/footerMan2.png"
                            alt="man"
                        />
                    </div>
                </div>
            </div>
        </div>
    </section>

    );
}

export default Checkout2;