import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import $ from "jquery";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Cart = () => {
  const formatPhoneNumber = (phoneNumber) => {
    const cleaned = phoneNumber.replace(/\D/g, "");

    let match;
    // if (cleaned.length === 10) {
    //   match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    // } else {
    match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,5})$/);
    // }

    if (match) {
      return `${match[1]}${match[1] ? "-" : ""}${match[2]}${
        match[2] ? "-" : ""
      }${match[3]}`;
    }

    return phoneNumber;
  };

  console.log(sessionStorage.getItem("cart"));

  const inlineStyle = {
    color: "#3374ff",
  };

  const [Cart_products, setCart_products] = useState(
    JSON.parse(sessionStorage.getItem("cart"))
  );
  const [cartPhoneItem, setCartPhoneItems] = useState(
    JSON.parse(sessionStorage.getItem("cart_phones"))
  );

  console.log(Cart_products);

  const handleRemoveElement = (index) => {
    const updatedElements = [...Cart_products];

    const updatedCartPhoneItems = [...cartPhoneItem];

    updatedElements.splice(index, 1);
    updatedCartPhoneItems.splice(index, 1);
    sessionStorage.setItem("cart", JSON.stringify(updatedElements));
    sessionStorage.setItem(
      "cart_phones",
      JSON.stringify(updatedCartPhoneItems)
    );
    $("header").find(".cart_count").text(updatedElements.length);
    setCart_products(updatedElements);
    setCartPhoneItems(updatedCartPhoneItems);
  };

  const modalRef = useRef(null);

  const [logined, setlogined] = useState(false);

  const [login_info, setlogin_info] = useState({
    useremail: "",
    password: "",
  });

  const handleloginchange = (event) => {
    setlogin_info({ ...login_info, [event.target.name]: event.target.value });
  };

  const [errors, setErrors] = useState({
    email: "",
    password: "",
    loginError: "",
  });

  const handleLoginSubmit = (event) => {
    event.preventDefault();

    if (!login_info.useremail) {
      setErrors({ ...errors, email: "Please enter an email address." });
      return;
    }

    if (!login_info.password) {
      setErrors({ ...errors, password: "Please enter a password." });
      return;
    }

    const formData = new FormData();
    formData.append("email", login_info.useremail);
    formData.append("password", login_info.password);

    axios
      .post("https://node.mumber.com/login", formData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.data.Success === 1) {
          window.localStorage.setItem("token", res.data.token);
          window.localStorage.setItem("users", JSON.stringify(res.data.users));
          window.location.reload();
          setlogined(true);
          $("#myModal .btn-close").trigger("click");
          toast.success("Login successful!");
        } else {
          setErrors({
            ...errors,
            loginError: "Incorrect email or password. Please try again.",
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (window.localStorage.getItem("users")) {
      setlogined(true);
    }
  }, [logined]);

  const handleforgotClick = () => {
    window.location.href = `/forgot`;
  };

  const handleregisterClick = () => {
    window.location.href = `/Register`;
  };

  if (Cart_products && Cart_products.length > 0) {
    return (
      <section className="new_container">
        <div className="container py-5">
          <h3 className="text-dark pt-lg-5 mt-3  shopc ">Shopping Cart</h3>
          {Cart_products.map((product, index) => {
            if (product != null) {
              return (
                <div className="row" key={index}>
                  <div className="col-lg-5 col-md-12 d-flex justify-content-lg-between align-items-center p-4 bg-white mt-lg-5 mt-md-5 one_colm mt-4 justify-content-center">
                    <div className="d-flex m-md-auto m-lg-0">
                      {/* <span className="text-muted">Sim</span> */}
                      <h4 className="px-lg-3 px-md-5 border-end M_number px-2">
                        {formatPhoneNumber(product.phone_no)}
                      </h4>
                      <span className="text-muted ps-lg-3 ps-md-4 ps-3">
                        {product.plan}
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-7 col-md-12 d-flex justify-content-lg-end align-items-baseline p-lg-4 bg-white mt-lg-5 two_colm p-md-3 mt-md-3 justify-content-md-around mt-4 py-3 px-2">
                    <div className="d-flex align-items-baseline">
                      <span className="text-muted">Price: </span>
                      <h4 className=" text-secondary    doller_price">
                        ${product.fees}
                      </h4>
                      <div className="d-flex px-lg-5 px-md-5 align-items-baseline px-3 total_tag">
                        <span className="text-muted">Total: </span>
                        <h4 style={inlineStyle} className="doller_price">
                          ${product.fees}
                        </h4>
                      </div>
                      <button
                        onClick={() => handleRemoveElement(index)}
                        className="border_rounded px-4 py-3 bg-white border border-1 d-flex remove_btn"
                      >
                        <img
                          className="pe-2"
                          src="assets/images/Vectordust.png"
                          alt=""
                        />
                        Remove
                      </button>
                    </div>
                  </div>
                </div>
              );
            }
          })}

          <div className="d-flex justify-content-end pb-5 div_for_btn">
            <div className="common-btn px-4 py-3">
              {/* <Link to={`/Checkout`}>
                                <button className="btn-primaris">Continue</button>
                            </Link> */}

              <div className="d-flex">
                {logined == false ? (
                  <button
                    style={{ marginTop: "25px" }}
                    type="button"
                    className="btn-primaris"
                    data-bs-toggle="modal"
                    data-bs-target="#myModal"
                  >
                    Continue
                  </button>
                ) : (
                  <Link to={`/Checkout`}>
                    <button className="btn-primaris">Continue</button>
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
        <section className="callout-area">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="common-heading">
                  <h3>
                    <span className="blue-x">Market Smarter, </span> Not Harder
                  </h3>
                  <p>
                    Building a brand is hard work. Make it easier by marketing
                    with a memorable phone number. Your unique number will leave
                    a lasting impression so you are first to mind when there is
                    a need for your service.
                  </p>
                  <div className="common-btn">
                    <Link to="/contact" className="btn-primaris">
                      Get Started
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="collout-man">
                  <img
                    loading="lazy"
                    src="assets/images/footerMan2.png"
                    alt="man"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="modal" id="myModal" ref={modalRef}>
          <div className="modal-dialog">
            <div className="modal-content">
              {/* <!-- Modal Header --> */}
              <div className="container-fluid my-2">
                <div className="modal-header">
                  <img src="assets/images/Unionblack.png" alt="" />
                  <h4
                    className="modal-title text-muted ps-lg-3 fs-2"
                    style={{
                      marginLeft: "173px",
                    }}
                  >
                    SIGN-IN
                  </h4>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                  ></button>
                </div>

                {/* <!-- Modal body --> */}

                <form id="login_form" onSubmit={handleLoginSubmit}>
                  <div className="mb-3">
                    <div className="text-center">
                      {errors.loginError && (
                        <p className="text-danger">{errors.loginError}</p>
                      )}
                    </div>
                    <label htmlFor="exampleInputEmail1" className="form-label">
                      Email*
                    </label>
                    <input
                      onChange={handleloginchange}
                      type="email"
                      name="useremail"
                      className="form-control border border-1 p-3"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="jonsnow@gmail.com"
                    />
                    {errors.email && (
                      <p className="text-danger">{errors.email}</p>
                    )}
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="exampleInputPassword1"
                      className="form-label"
                    >
                      Password*
                    </label>
                    <input
                      onChange={handleloginchange}
                      type="password"
                      name="password"
                      className="form-control border border-1 p-3 dot_place"
                      id="exampleInputPassword1"
                      placeholder="............"
                    />
                    {errors.password && (
                      <p className="text-danger">{errors.password}</p>
                    )}
                  </div>
                  <div className="d-flex justify-content-between">
                    <div>
                      <input type="checkbox" />
                      <span style={{ paddingLeft: "2px" }}> Remember Me </span>
                    </div>
                    <Link to="/forgot" onClick={handleforgotClick}>
                      <span style={{ color: "#3374ff" }}>Forgot Password?</span>
                    </Link>
                  </div>
                  <button
                    type="submit"
                    className="btn-primaris sign_in_btn mt-4 fw-semibold"
                  >
                    Sign in
                  </button>
                  <div className="text-center py-3">or</div>
                  <Link to="/register" onClick={handleregisterClick}>
                    <button className="create_an_btn mb-4 border border-1">
                      Create an Account
                    </button>
                  </Link>
                </form>
              </div>

              {/* <!-- Modal footer --> */}
            </div>
          </div>
        </div>
      </section>
    );
  } else {
    return (
      <section className="new_container">
        <div className="container py-5">
          <h3 className="text-dark pt-lg-5 mt-3 shopc">Shopping Cart</h3>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>

          <div style={{ margin: "0 auto", textAlign: "center" }}>
            <Link to="/search">
              <button
                style={{
                  width: "118px",
                  justifyContent: "center",
                  background: "#1BEEBB",
                  color: "#131732",
                  borderRadius: "50px",
                  border: "none",

                  height: "51px",
                }}
              >
                Shop Now
              </button>
            </Link>
          </div>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
        </div>
      </section>
    );
  }
};

export default Cart;
