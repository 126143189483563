import React, { useState, useEffect } from "react";
import $ from "jquery";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { slice } from "lodash";
import axios from "axios";
import { useParams } from "react-router-dom";
import Preloader from "../Preloader";

import { Modal, Button, Table } from "react-bootstrap";
function ListOfFavriot() {
  const [activeMenuItem, setActiveMenuItem] = useState("All");
  const handleMenuClick = (menuItem) => {
    setActiveMenuItem(menuItem);
  };
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Make an API request to fetch data
        const response = await axios.get(
          "https://node.mumber.com/favorites/123"
        );

        // Once the data is fetched, set isLoading to false
        setIsLoading(false);

        // Process the data further if needed
        console.log(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  const formatPhoneNumber = (phoneNumber) => {
    if (typeof phoneNumber !== "string" || !phoneNumber) {
      return phoneNumber; // or handle the error accordingly
    }
    const cleaned = phoneNumber.replace(/\D/g, "");

    let match;
    // if (cleaned.length === 10) {
    //   match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    // } else {
    match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,5})$/);
    // }

    if (match) {
      return `${match[1]}${match[2] ? "-" : ""}${match[2]}${
        match[3] ? "-" : ""
      }${match[3]}`;
    }

    return phoneNumber;
  };

  const [suggestions, setSuggestions] = useState([]);

  const [selectedSuggestion, setSelectedSuggestion] = useState("");
  const [showSuggestions, setShowSuggestions] = useState(false);
  const handleInputChange = (event) => {
    let value = event.target.value;

    if (
      event.nativeEvent.inputType === "deleteContentBackward" &&
      value.length > 0
    ) {
      // alert(value.length)
      if (value.length <= 5) {
        value = value.slice(0, -2);
      } else if (value.length == 1) {
        value = "";
      } else {
        value = value.slice(0, -1);
      }

      // alert(value);
    }

    const startsWithAlphabet = /^[a-zA-Z]/.test(value);

    if (startsWithAlphabet == false) {
      setSelectedSuggestion(formatPhoneNumber(value));
    } else {
      setSelectedSuggestion(value);
    }

    // console.log(startsWithAlphabet);

    let api_num_value = value.replace(/[(),-]/g, "");
    if (api_num_value.trim() !== "") {
      fetch(`https://node.mumber.com/api/suggestions?query=${api_num_value}`)
        .then((response) => response.json())
        .then((data) => setSuggestions(data))
        .catch((error) => console.error(error));

      setShowSuggestions(true);
    } else {
      setSuggestions([]);
      setShowSuggestions(false);
    }
  };

  const navigate = useNavigate();
  const [selectedRow, setSelectedRow] = useState(null);
  const handleShow = () => setSelectedRow(true);
  const handleClose = () => setSelectedRow(false);
  const handleRowClick = (rowData) => {
    setSelectedRow(rowData);
    console.log(rowData);
  };

  // const[search,setSearch]=useState([]);
  const [record, setRecord] = useState([]);
  const [sortType, setSortType] = useState("asc");
  const [buttonClicked, setButtonClicked] = useState(false);

  // var i =1; // For Serial no increment

  // // For loading reacord on Windoe Load
  // const loadRecords = async () => {
  //   axios.get("https://node.mumber.com/api/get")
  //     .then(response => {
  //       setSearch(response.data);
  //     });

  // }
  // useEffect(() => {
  //     loadRecords();
  // }, []);

  // Search Item by Name
  const searchRecords = () => {
    axios
      .get(`https://node.mumber.com/api/get_d/${record}`)
      .then((response) => {
        setPost(response.data);
      });
  };

  const users = window.localStorage.getItem("users");
  const parsedUsers = users ? JSON.parse(users) : []; // Assign an empty array if users is null

  const ids = parsedUsers.map((user) => user.id);
  console.log(ids);
  const userId = ids;

  // load more code
  const [post, setPost] = useState([]);
  const [isCompleted, setIsCompleted] = useState(false);
  const [index, setIndex] = useState(10);
  const initialPosts = slice(post, 0, index);
  const getData = () => {
    fetch(`https://node.mumber.com/favorites/${ids}`)
      .then((res) => res.json())
      .then((json) => setPost(json))
      .catch((e) => console.log(e));
  };

  const loadMore = () => {
    setIndex(index + 10);
    console.log(index);
    if (index >= post.length) {
      setIsCompleted(true);
    } else {
      setIsCompleted(false);
    }
  };
  useEffect(() => {
    getData();
  }, []);

  const handleNewest = () => {
    handleMenuClick("Newest");
    // if (sortType === 'asc') {
    setPost([...post].sort((a, b) => a.id - b.id));
    setSortType("desc");
    // }
    // else if (sortType === 'desc') {
    //   setPost([...post].sort((a, b) => b.id - a.id));
    //   setSortType('asc');
    // }
  };
  const handleOldest = () => {
    handleMenuClick("Oldest");

    // if (sortType === 'asc') {
    setPost([...post].sort((a, b) => a.id - b.id));
    setSortType("desc");
    // }
    // else if (sortType === 'desc') {
    //   setPost([...post].sort((a, b) => b.id - a.id));
    //   setSortType('asc');
    // }
  };

  const handleDeleteFavorite = async (event) => {
    const productId = event.target.dataset.productid;

    try {
      const response = await axios.delete(
        `https://node.mumber.com/favorites/${productId}`
      );
      console.log(response.data);
      getData();

      // window.location.reload();// Optional: Handle the response if needed
      // You can update the UI or perform any necessary actions after successful deletion
    } catch (error) {
      console.error(error);
      // Handle the error if deletion fails
    }
  };

  const handleSortpa = () => {
    if (sortType === "asc") {
      setPost([...post].sort((a, b) => a.monthly - b.monthly));
      setSortType("desc");
    }
  };

  const handleSortpd = () => {
    if (sortType === "desc") {
      setPost([...post].sort((a, b) => b.monthly - a.monthly));
      setSortType("asc");
    }
  };

  const handleSorta = () => {
    handleMenuClick("Low Price Firstly");

    if (sortType === "asc") {
      setPost([...post].sort((a, b) => a.id - b.id));
      setSortType("desc");
    }
  };

  const handleSortd = () => {
    handleMenuClick("High Price Firstly");
    if (sortType === "desc") {
      setPost([...post].sort((a, b) => b.id - a.id));
      setSortType("asc");
    }
  };

  const handle3Click = () => {
    handleMenuClick("Top 3");
    setPost(post.slice(0, 3));
    setButtonClicked(true);
  };
  const fetchData = async () => {
    const response = await fetch(`https://node.mumber.com/favorites/${ids}`);
    const jsonData = await response.json();
    setPost(jsonData);
  };
  // const url = "https://node.mumber.com/api/get";
  // const [data, setData] = useState([]);

  // const fetchInfo = () => {
  //   return fetch(url)
  //     .then((res) => res.json())
  //     .then((d) => setData(d))
  // }

  // useEffect(() => {
  //   fetchInfo();
  // }, []);

  const handleBuy = () => {
    var prod_id = $(".modal-body").find("table #prod_id").text();
    var phone_no = $(".modal-body").find("table #phone_no").text();
    var plan = $(".modal-body").find("table #plan").text();
    var fees = $(".modal-body").find("table #fee").text();
    const cart_products = {
      prod_id: prod_id,
      phone_no: phone_no,
      plan: plan,
      fees: fees,
    };

    sessionStorage.setItem("cart", JSON.stringify(cart_products));

    navigate("/Cart");
  };

  const hideLoadButton = () => {
    if (post.length < 5) {
      return { display: "none" };
    } else {
      return { display: "block" };
    }
  };

  document.addEventListener("click", function (event) {
    var targetElement = event.target;

    // Check if the clicked element or any of its parents have the search area class
    var isClickedInsideSearchArea = targetElement.closest(".search-bar-area");

    if (!isClickedInsideSearchArea) {
      // Clicked outside the search area
      setSuggestions([]);
    }
  });

  return (
    <>
      {isLoading ? (
        <div className="content-wrapper" style={{ marginBottom: "200px" }}>
          <Preloader />
        </div>
      ) : (
        <div>
          <section className="banner-area pages-banner">
            <div className="container">
              <div className="row banner-row align-items-center">
                <div className="col-lg-6">
                  <div className="banner-heading">
                    <h2>
                      Your <span>favorite</span> <br /> numbers
                    </h2>
                    <p>
                      Find the perfect easy to remember phone number for your
                      business.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="man-img">
                    <img src="assets/images/list.png" alt="manImg" />
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/*============================================================
                    End banner area
    =============================================================*/}
          {/*============================================================
                    start search bar area
    =============================================================*/}
          <div className="search-bar-area">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <form id="quick-request-submit">
                    <div className="reqest-a-product">
                      <div className="search-icon">
                        <img
                          loading="lazy"
                          src="assets/images/Ellipse.png"
                          alt="ellipse"
                        />
                      </div>

                      <input
                        type="text"
                        className="search-box-main"
                        placeholder="Search here by: Phone Number, Area Code, Keyword, State, or City"
                        name="phone"
                        id="quick-request-input"
                        value={selectedSuggestion}
                        onChange={handleInputChange}
                        autoComplete="off"
                        onClick={() => {
                          setSuggestions([]); // Clear the suggestions list
                          setShowSuggestions(false); // Hide the suggestions
                        }}
                      />
                      <ul style={{ paddingTop: "40px", marginTop: "-40px" }}>
                        {showSuggestions &&
                          suggestions.map((suggestion, index) => (
                            <li
                              title={suggestion.Description.replace(
                                /[?!]|XYY-YYYY\b/g,
                                ""
                              )}
                              key={index}
                              onClick={() => {
                                setSelectedSuggestion(
                                  formatPhoneNumber(suggestion.phone_no)
                                );
                                setSuggestions([]);
                                setShowSuggestions(false);

                                navigate(
                                  "/Search/" +
                                    formatPhoneNumber(suggestion.phone_no)
                                );
                              }}
                              style={{
                                background: "#fff",
                                padding: "10px 30px",
                                cursor: "pointer",
                                borderBottom: "1px solid rgb(227 227 227)",
                                fontSize: "14px",
                                color: "#333",
                                fontWeight: "bold",
                              }}
                            >
                              <span>
                                {formatPhoneNumber(suggestion.phone_no)}
                              </span>{" "}
                              <b style={{ color: "#999", float: "right" }}>
                                {suggestion.Description.replace(
                                  /[?!]|XYY-YYYY\b/g,
                                  ""
                                ).substring(0, 60)}
                                ...{" "}
                                <span
                                  style={{
                                    color: "#3374ff",
                                    marginLeft: "20px",
                                  }}
                                >
                                  ${suggestion.monthly}/mo
                                </span>
                              </b>
                            </li>
                          ))}
                        {suggestions.length > 0 && (
                          <li>
                            <Link to={`/Search/${selectedSuggestion}`}>
                              <button
                                style={{
                                  width: "100%",
                                  color: "#000",
                                  border: "1px solid #eee",
                                  height: "39px",
                                }}
                              >
                                View All
                              </button>
                            </Link>
                          </li>
                        )}
                      </ul>

                      <Link to={`/Search/${selectedSuggestion}`}>
                        <button className="btn-primaris srh">Search</button>
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          {/*============================================================
                    End search bar area
    =============================================================*/}
          {/*============================================================
                    start searched result area
    =============================================================*/}
          <section className="search-result-area">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="search-result-head">
                    <h2>List of favorites</h2>
                    <div className="search-menu-numbers">
                      <div className="search-menu">
                        <ul>
                          <li>
                            <button
                              className="border-0"
                              style={{ background: "white" }}
                            >
                              <a
                                onClick={fetchData}
                                className={
                                  activeMenuItem === "All" ? "active" : ""
                                }
                              >
                                Numeric
                              </a>
                            </button>
                          </li>
                          {/* <li>
    <button className="border-0" style={{background:"white"}}>
      <a onClick={handle3Click} className={activeMenuItem === "Top 3" ? "active" : ""}>
        Top 3
      </a>
      </button>
    </li> */}
                          <li>
                            <button
                              className="border-0"
                              style={{ background: "white" }}
                            >
                              <a
                                onClick={handleSortpa}
                                className={
                                  activeMenuItem === "Low Price Firstly"
                                    ? "active"
                                    : ""
                                }
                              >
                                Low Price Firstly
                              </a>
                            </button>
                          </li>
                          <li>
                            <button
                              className="border-0"
                              style={{ background: "white" }}
                            >
                              <a
                                onClick={handleSortpd}
                                className={
                                  activeMenuItem === "High Price Firstly"
                                    ? "active"
                                    : ""
                                }
                              >
                                High Price Firstly
                              </a>
                            </button>
                          </li>
                          {/* <li>
    <button className="border-0" style={{background:"white"}}>
      <a onClick={handleSortd} className={activeMenuItem === "Newest" ? "active" : ""}>
        Newest
      </a>
      </button>
    </li>
    <li>
    <button className="border-0" style={{background:"white"}}>
      <a onClick={handleOldest} className={activeMenuItem === "Oldest" ? "active" : ""}>
        Oldest
      </a>
      </button>
    </li> */}
                        </ul>
                      </div>
                      <div className="search-found-numbers">
                        <span>
                          Saved:{" "}
                          <label>
                            <b>{initialPosts.length}</b>
                          </label>{" "}
                          Numbers
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="search-result-content">
                    <div className="search-table">
                      <table className="table">
                        <tbody>
                          {/* {search.map((dataObj, index)=> */}
                          {initialPosts.map((dataObj, index) => {
                            return (
                              <tr>
                                <td
                                  style={{ borderTop: "none" }}
                                  className="search-serial-no"
                                >
                                  {index + 1}
                                </td>
                                <td
                                  style={{ borderTop: "none" }}
                                  className="numbers"
                                >
                                  {formatPhoneNumber(dataObj.phone_no)}
                                </td>
                                {/* <td style={{borderTop:"none"}} className="license">{dataObj.plan}</td> */}
                                {/* <td style={{borderTop:"none"}} className="price">{dataObj.licence_fee}</td> */}

                                <td
                                  style={{ borderTop: "none" }}
                                  className="license"
                                >
                                  {dataObj.area_code}
                                </td>
                                <td
                                  style={{ borderTop: "none" }}
                                  className="price text-lowercase"
                                >
                                  ${dataObj.monthly}/mo
                                </td>
                                <td
                                  style={{ borderTop: "none" }}
                                  className="moreinfo-button"
                                >
                                  <Link
                                    to={`/Phone/${formatPhoneNumber(
                                      dataObj.phone_no
                                    )}`}
                                  >
                                    <Button
                                      variant="primary"
                                      onClick={() => handleRowClick(dataObj)}
                                      style={{
                                        width: "123px",
                                        height: "51px",

                                        border: "1px solid #E4E7F0",
                                        borderRadius: "50px",
                                        background: "#FFFFFF",
                                        color: "#8790AE",
                                      }}
                                    >
                                      More Info
                                    </Button>
                                  </Link>
                                </td>
                                <td
                                  style={{ borderTop: "none" }}
                                  className="love-icon"
                                >
                                  <i
                                    style={{ color: "#1beebb" }}
                                    onClick={handleDeleteFavorite}
                                    data-productid={dataObj.productId}
                                    className="fas fa-heart"
                                  />
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                        <div className="row">
                          <div className="button">
                            {/* <a href="#">Load more</a> */}

                            {isCompleted ? (
                              <button
                                onClick={loadMore}
                                type="button"
                                className="btn btn-danger disabled"
                                style={{
                                  borderRadius: "50px",
                                  width: "148px",
                                  height: "51px",
                                  border: "none",
                                  fontSize: "19px",
                                }}
                              >
                                That's It
                              </button>
                            ) : (
                              <button
                                onClick={loadMore}
                                type="button"
                                style={{
                                  borderRadius: "50px",
                                  width: "148px",
                                  height: "51px",
                                  fontSize: "19px",
                                  color: "#8790AE",
                                  border: "none",
                                  background: "#F0F0F0",
                                }}
                                className="button"
                              >
                                Load More
                              </button>
                            )}
                          </div>
                        </div>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {selectedRow && handleShow && (
              <Modal show={true} onHide={handleClose} animation={false}>
                <Modal.Header closeButton>
                  <Modal.Title>Product Detail</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Phone No</th>
                        <th>Plan</th>
                        <th>Fee</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td id="phone_no">{selectedRow.phone_no}</td>
                        <td id="prod_id">{selectedRow.id}</td>
                        <td id="plan">{selectedRow.plan}</td>
                        <td id="fee">${selectedRow.licence_fee}</td>
                      </tr>
                    </tbody>
                  </Table>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={handleClose}
                    style={{ marginTop: "8px" }}
                  >
                    Close
                  </Button>
                  <Button variant="primary" onClick={handleBuy}>
                    Buy
                  </Button>
                </Modal.Footer>
              </Modal>
            )}
          </section>
        </div>
      )}

      <section className="callout-area" style={{ marginTop: "1px" }}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="common-heading">
                <h3>
                  <span className="blue-x">Market Smarter, </span> Not Harder
                </h3>
                <p>
                  Building a brand is hard work. Make it easier by marketing
                  with a memorable phone number. Your unique number will leave a
                  lasting impression so you are first to mind when there is a
                  need for your service.
                </p>
                <div className="common-btn">
                  <Link to="/contact" className="btn-primaris">
                    Get Started
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="collout-man">
                <img
                  loading="lazy"
                  src="assets/images/footerMan2.png"
                  alt="man"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ListOfFavriot;
