import React, { useState, useEffect } from "react";
import { slice } from "lodash";
import axios from "axios";

function Product() {
  // const[search,setSearch]=useState([]);
  const [record, setRecord] = useState([]);

  // var i =1; // For Serial no increment

  // // For loading reacord on Windoe Load
  // const loadRecords = async () => {
  //   axios.get("https://node.mumber.com/api/get")
  //     .then(response => {
  //       setSearch(response.data);
  //     });

  // }
  // useEffect(() => {
  //     loadRecords();
  // }, []);

  // Search Item by Name
  const searchRecords = () => {
    axios
      .get(`https://node.mumber.com/api/get_d/${record}`)
      .then((response) => {
        setPost(response.data);
      });
  };

  // load more code
  const [post, setPost] = useState([]);
  const [isCompleted, setIsCompleted] = useState(false);
  const [index, setIndex] = useState(10);
  const initialPosts = slice(post, 0, index);
  const getData = () => {
    fetch("https://node.mumber.com/api/get")
      .then((res) => res.json())
      .then((json) => setPost(json))
      .catch((e) => console.log(e));
  };
  const loadMore = () => {
    setIndex(index + 10);
    console.log(index);
    if (index >= post.length) {
      setIsCompleted(true);
    } else {
      setIsCompleted(false);
    }
  };
  useEffect(() => {
    getData();
  }, []);

  // const url = "https://node.mumber.com/api/get";
  // const [data, setData] = useState([]);

  // const fetchInfo = () => {
  //   return fetch(url)
  //     .then((res) => res.json())
  //     .then((d) => setData(d))
  // }

  // useEffect(() => {
  //   fetchInfo();
  // }, []);

  return (
    <>
      <section className="banner-area pages-banner">
        <div className="container">
          <div className="row banner-row align-items-center">
            <div className="col-lg-6">
              <div className="banner-heading">
                <h2>
                  Your <span>favorite</span> <br /> numbers
                </h2>
                <p>
                  Find the perfect easy to remember phone number for your
                  business.
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="man-img">
                <img src="assets/images/list.png" alt="manImg" />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*============================================================
                    End banner area
    =============================================================*/}
      {/*============================================================
                    start search bar area
    =============================================================*/}
      <div className="search-bar-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <form id="quick-request-submit">
                <div className="reqest-a-product">
                  <div className="search-icon">
                    <img
                      loading="lazy"
                      src="assets/images/Ellipse.png"
                      alt="ellipse"
                    />
                  </div>
                  <input
                    type="search"
                    onChange={(e) => setRecord(e.target.value)}
                    placeholder="Search here by: Phone Number, Area Code, Keyword, State, or City "
                    id="quick-request-input"
                  />
                  <button
                    className="btn-primaris"
                    onClick={searchRecords}
                    type="button"
                  >
                    Search
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/*============================================================
                    End search bar area
    =============================================================*/}
      {/*============================================================
                    start searched result area
    =============================================================*/}

      <section className="search-result-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="search-result-head">
                <h2>List of favorites</h2>
                <div className="search-menu-numbers">
                  <div className="search-menu">
                    <ul>
                      <li>
                        <a href="#" className="active">
                          All
                        </a>
                      </li>
                      <li>
                        <a href="#">Top 3</a>
                      </li>
                      <li>
                        <a href="#">Low Price Firstly</a>
                      </li>
                      <li>
                        <a href="#">Hight Price Firstly</a>
                      </li>
                      <li>
                        <a href="#">Newest</a>
                      </li>
                      <li>
                        <a href="#">Oldest</a>
                      </li>
                    </ul>
                  </div>
                  <div className="search-found-numbers">
                    <span>
                      Saved:{" "}
                      <label>
                        <b>{initialPosts.length}</b>
                      </label>{" "}
                      Numbers
                    </span>
                  </div>
                </div>
              </div>
              <div className="search-result-content">
                <div className="search-table">
                  <table className="table">
                    <tbody>
                      {/* {search.map((dataObj, index)=> */}
                      {initialPosts.map((dataObj, index) => {
                        return (
                          <tr>
                            <td className="search-serial-no">{index + 1}</td>
                            <td className="numbers">{dataObj.phone_no}</td>
                            <td className="license">{dataObj.plan}</td>
                            <td className="price">{dataObj.licence_fee}</td>
                            <td className="moreinfo-button">
                              <a href="#">More info</a>
                            </td>
                            <td className="love-icon">
                              <i className="fas fa-heart" />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                    <div className="row">
                      <div className="button">
                        {/* <a href="#">Load more</a> */}

                        {isCompleted ? (
                          <button
                            onClick={loadMore}
                            type="button"
                            className="btn btn-danger disabled"
                          >
                            That's It
                          </button>
                        ) : (
                          <button
                            onClick={loadMore}
                            type="button"
                            className="button"
                          >
                            Load More +
                          </button>
                        )}
                      </div>
                    </div>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Product;
