import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./reset.css";
import { ToastContainer, toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");

  const navigate = useNavigate();

  const handleFormSubmit = (e) => {
    e.preventDefault();

    // Validate password and confirmPassword
    if (password.trim() === "") {
      setErrorMessage("Password is required");
      return;
    }

    if (password !== confirmPassword) {
      setErrorMessage("Passwords do not match");
      return;
    }

    // Add any other password validation logic here

    // Clear error message
    setErrorMessage("");

    // Proceed with the API request
    fetch("https://node.mumber.com/api/reset-password", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ password, resetToken: token }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        // Handle the API response here
        toast.success("Password reset successfully");
        setPassword("");
        setConfirmPassword("");
        navigate("/login");
      })
      .catch((error) => {
        console.error("Error:", error);
        // Handle errors and display an error message to the user
      });
  };

  return (
    <>
      {/* <div style={{margin:"200px"}}>
      <h2>Reset Password</h2>
      <form onSubmit={handleFormSubmit}>
        <div>
          <label htmlFor="password">New Password:</label>
          <input
            id="password"
            name="password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="confirmPassword">Confirm Password:</label>
          <input
            id="confirmPassword"
            name="confirmPassword"
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </div>
        <div className="error-message">
        {errorMessage && <p >{errorMessage}</p>}
        </div>
        <div>
          <input
            id="resetToken"
            name="resetToken"
            type="hidden"
            value={token}
          />
        </div>
        <div>
          <button type="submit">Reset Password</button>
        </div>
      </form>
    </div> */}

      <ToastContainer />
      <div className="banner-area pages-banner mt-4">
        <div className="form-gap"></div>
        <div className="container">
          <div className="row">
            <div className="col-md-4 col-md-offset-4">
              <div className="panel panel-default">
                <div className="panel-body">
                  <div className="text-center">
                    <h3>
                      <i className="fa fa-lock fa-2x"></i>
                    </h3>
                    <h2 className="text-center" style={{ color: "black" }}>
                      Reset Password
                    </h2>
                    <p style={{ color: "black" }}>
                      Create your new password here.
                    </p>
                    <div className="panel-body">
                      <form onSubmit={handleFormSubmit}>
                        <div className="form-group">
                          <div className="input-group">
                            <label htmlFor="password">New Password</label>
                            <br></br>
                            <div>
                              <input
                                id="password"
                                name="password"
                                className="form-control"
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                              />
                            </div>

                            <label className="mt-3" htmlFor="confirmPassword">
                              Confirm Password:
                            </label>

                            <div>
                              <input
                                id="confirmPassword"
                                className="form-control"
                                name="confirmPassword"
                                type="password"
                                value={confirmPassword}
                                onChange={(e) =>
                                  setConfirmPassword(e.target.value)
                                }
                              />
                            </div>
                            {errorMessage && (
                              <h5 className="error-message mt-2">
                                {errorMessage}
                              </h5>
                            )}
                            <div>
                              <input
                                id="resetToken"
                                name="resetToken"
                                type="hidden"
                                value={token}
                              />
                            </div>
                            <div>
                              <button
                                className="btn btn-lg btn-primary btn-block mt-4"
                                type="submit"
                              >
                                Reset Password
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
