import React from 'react'
import './Preloader.css'

const Preloader = () => {
  return (
    <>
    <div className='content-wrapper'>
      
    <div className='preload'>
      <img src='/assets/images/logo_res-min-1.png'></img>
    </div>
    </div>
    
    </>
  )
}

export default Preloader