import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { slice } from "lodash";
import Preloader from "../Preloader";
function Blog() {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [isCompleted, setIsCompleted] = useState(false);
  const [index, setIndex] = useState(10);
  const [visibleBlogs, setVisibleBlogs] = useState(6);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Make an API request to fetch data
        const response = await axios.get(
          "https://node.mumber.com/api/allblogs"
        );
        setData(response.data.data);
        // Once the data is fetched, set isLoading to false
        setIsLoading(false);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  const loadMore = () => {
    setVisibleBlogs(visibleBlogs + 6);
    if (visibleBlogs >= data.length) {
      setIsCompleted(true);
    } else {
      setIsCompleted(false);
    }
  };

  const formatPhoneNumber = (phoneNumber) => {
    if (typeof phoneNumber !== "string" || !phoneNumber) {
      return phoneNumber; // or handle the error accordingly
    }
    const cleaned = phoneNumber.replace(/\D/g, "");

    let match;

    match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,5})$/);

    if (match) {
      return `${match[1]}${match[2] ? "-" : ""}${match[2]}${
        match[3] ? "-" : ""
      }${match[3]}`;
    }

    return phoneNumber;
  };

  const [suggestions, setSuggestions] = useState([]);

  const [selectedSuggestion, setSelectedSuggestion] = useState("");
  const [showSuggestions, setShowSuggestions] = useState(false);
  const handleInputChange = (event) => {
    let value = event.target.value;

    if (
      event.nativeEvent.inputType === "deleteContentBackward" &&
      value.length > 0
    ) {
      if (value.length <= 5) {
        value = value.slice(0, -2);
      } else if (value.length == 1) {
        value = "";
      } else {
        value = value.slice(0, -1);
      }
    }

    const startsWithAlphabet = /^[a-zA-Z]/.test(value);

    if (startsWithAlphabet == false) {
      setSelectedSuggestion(formatPhoneNumber(value));
    } else {
      setSelectedSuggestion(value);
    }

    let api_num_value = value.replace(/[(),-]/g, "");
    if (api_num_value.trim() !== "") {
      fetch(`https://node.mumber.com/api/suggestions?query=${api_num_value}`)
        .then((response) => response.json())
        .then((data) => setSuggestions(data))
        .catch((error) => console.error(error));

      setShowSuggestions(true);
    } else {
      setSuggestions([]);
      setShowSuggestions(false);
    }
  };

  const [sortType, setSortType] = useState("asc");
  const [buttonClicked, setButtonClicked] = useState(false);

  // load more code
  const [post, setPost] = useState([]);

  const handleSortAsc = () => {
    const sortedData = [...data].sort((a, b) => a.Id - b.Id);
    setData(sortedData);
    setSortType("asc");
  };

  const handleSortDesc = () => {
    const sortedData = [...data].sort((a, b) => b.Id - a.Id);
    setData(sortedData);
    setSortType("desc");
    window.location.reload();
  };

  const handleSortDesc1 = () => {
    const sortedData = [...data].sort((a, b) => b.Id - a.Id);
    setData(sortedData);
    setSortType("desc");
  };

  // const handleShowDescThree = () => {
  //   const sortedData = [...data].sort((a, b) => b.Id - a.Id);
  //   const topThree = sortedData.slice(0, 3);
  //   setData(topThree);
  //   setSortType("desc");
  // };

  const handleShowDescThree = () => {
    const sortedData = [...data].sort((a, b) => a.Id - b.Id);
    const topThree = [];
    for (let i = 0; i < Math.min(sortedData.length, 3); i++) {
      topThree.push(sortedData[i]);
    }
    setData(topThree);
    setSortType("desc");
  };

  // const fetchData = async () => {
  //   try {
  //     const response = await fetch('https://node.mumber.com/api/allblogs');
  //     if (!response.ok) {
  //       throw new Error('Failed to fetch data');
  //     }
  //     const jsonData = await response.json();
  //     if (!Array.isArray(jsonData)) {
  //       throw new Error('Invalid data format');
  //     }
  //     setPost(jsonData);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const fetchData = async () => {
    try {
      const response = await fetch("https://node.mumber.com/api/allblogs");
      const responseData = await response.json();

      if (Array.isArray(responseData.data)) {
        setPost(responseData.data.slice(0, 6)); // Set only the first 6 items
        setIsLoading(false);
      } else {
        console.error("Data property is not an array.");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const [totalblogs, setTotalblogs] = useState(0);

  useEffect(() => {
    fetchTotalblogs();
  }, []);

  const fetchTotalblogs = () => {
    fetch("https://node.mumber.com/api/countblogs")
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setTotalblogs(data.totalCount);
        } else {
          console.log(data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  document.addEventListener("click", function (event) {
    var targetElement = event.target;

    // Check if the clicked element or any of its parents have the search area class
    var isClickedInsideSearchArea = targetElement.closest(".search-bar-area");

    if (!isClickedInsideSearchArea) {
      // Clicked outside the search area
      setSuggestions([]);
    }
  });

  const formatDate = (dateString) => {
    const dateObj = new Date(dateString);
    const formattedDate = `${
      dateObj.getMonth() + 1
    }/${dateObj.getDate()}/${dateObj.getFullYear()}`;
    return formattedDate;
  };

  return (
    <>
      {isLoading ? (
        <div className="content-wrapper" style={{ marginBottom: "200px" }}>
          <Preloader />
        </div>
      ) : (
        <div>
          <section className="banner-area pages-banner">
            <div className="container">
              <div className="row banner-row align-items-center">
                <div className="col-lg-6">
                  <div className="banner-heading">
                    <h2>
                      Our <span>Blog</span>
                    </h2>
                    <p>
                      Find the perfect easy to remember phone number for your
                      business.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="blog-banner-img man-img">
                    <img src="assets/images/blog-banner.png" alt="manImg" />
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/*============================================================
                    End banner area
    =============================================================*/}
          {/*============================================================
                    start search bar area
    =============================================================*/}
          <div className="search-bar-area">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <form id="quick-request-submit">
                    <div className="reqest-a-product">
                      <div className="search-icon">
                        <img
                          loading="lazy"
                          src="assets/images/Ellipse.png"
                          alt="ellipse"
                        />
                      </div>

                      <input
                        type="text"
                        className="search-box-main"
                        placeholder="Search here by: Phone Number, Area Code, Keyword, State, or City"
                        name="phone"
                        id="quick-request-input"
                        value={selectedSuggestion}
                        onChange={handleInputChange}
                        autoComplete="off"
                        onClick={() => {
                          setSuggestions([]); // Clear the suggestions list
                          setShowSuggestions(false); // Hide the suggestions
                        }}
                      />
                      <ul style={{ paddingTop: "40px", marginTop: "-40px" }}>
                        {showSuggestions &&
                          suggestions.map((suggestion, index) => (
                            <li
                              title={suggestion.Description.replace(
                                /[?!]|XYY-YYYY\b/g,
                                ""
                              )}
                              key={index}
                              onClick={() => {
                                setSelectedSuggestion(
                                  formatPhoneNumber(suggestion.phone_no)
                                );
                                setSuggestions([]);
                                setShowSuggestions(false);
                                navigate(
                                  "/Search/" +
                                    formatPhoneNumber(suggestion.phone_no)
                                );
                              }}
                              style={{
                                background: "#fff",
                                padding: "10px 30px",
                                cursor: "pointer",
                                borderBottom: "1px solid rgb(227 227 227)",
                                fontSize: "14px",
                                color: "#333",
                                fontWeight: "bold",
                              }}
                            >
                              <span>
                                {formatPhoneNumber(suggestion.phone_no)}
                              </span>{" "}
                              <b style={{ color: "#999", float: "right" }}>
                                {suggestion.Description.replace(
                                  /[?!]|XYY-YYYY\b/g,
                                  ""
                                ).substring(0, 60)}
                                ...{" "}
                                <span
                                  style={{
                                    color: "#3374ff",
                                    marginLeft: "20px",
                                  }}
                                >
                                  ${suggestion.monthly}/mo
                                </span>
                              </b>
                            </li>
                          ))}

                        {suggestions.length > 0 && (
                          <li>
                            <Link to={`/Search/${selectedSuggestion}`}>
                              <button
                                style={{
                                  width: "100%",
                                  color: "#000",
                                  border: "1px solid #eee",
                                  height: "39px",
                                }}
                              >
                                View All
                              </button>
                            </Link>
                          </li>
                        )}
                      </ul>

                      <Link to={`/Search/${selectedSuggestion}`}>
                        <button className="btn-primaris srh">Search</button>
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          {/*============================================================
                    End search bar area
    =============================================================*/}
          {/*============================================================
                    start blog area
    =============================================================*/}
          <section className="blogs-area search-result-area">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="search-result-head">
                    <h2>
                      searched <span>results</span>
                    </h2>
                    <div className="search-menu-numbers">
                      <div className="search-menu">
                        <ul>
                          <li>
                            <button
                              className="border-0"
                              style={{ background: "white" }}
                            >
                              <a onClick={handleSortDesc} className="active">
                                All
                              </a>
                            </button>
                          </li>
                          <li>
                            <button
                              className="border-0"
                              style={{ background: "white" }}
                            >
                              <a onClick={handleShowDescThree}>Top 3</a>
                            </button>
                          </li>

                          <li>
                            <button
                              className="border-0"
                              style={{ background: "white" }}
                            >
                              <a onClick={handleSortDesc1}>Newest</a>
                            </button>
                          </li>
                          <li>
                            <button
                              className="border-0"
                              style={{ background: "white" }}
                            >
                              <a onClick={handleSortAsc}>Oldest</a>
                            </button>
                          </li>
                        </ul>
                      </div>
                      <div className="search-found-numbers">
                        <span>
                          Found:{" "}
                          <label>
                            <b>{totalblogs}</b>
                          </label>{" "}
                          results
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="blogs-items">
                <div className="row">
                  {/* single item */}
                  {data.map(
                    (blog, index) =>
                      index < visibleBlogs && (
                        <div className="col-lg-4 col-sm-6">
                          <div className="blog-single-item">
                            <Link to={`/BlogContent/${blog.Id}`}>
                              <img src={`${blog.images}`} alt="" />
                            </Link>
                            <h3>
                              {" "}
                              <Link to={`/BlogContent/${blog.Id}`}>
                                {blog.Title}
                              </Link>
                            </h3>
                            <p>{blog.ShortDescription}.</p>
                            <div className="post-date">
                              <span style={{ float: "right" }}>
                                {formatDate(blog.created_date)}
                              </span>
                            </div>
                            <div className="author-date-area">
                              <div className="author-area">
                                <div className="author-image">
                                  {/* <img src={(`${blog.images}`)} alt="" /> */}
                                </div>
                                {/* <span>Marvin McKinney</span> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                  )}
                  {/* single item */}
                </div>

                <div className="row">
                  <div className="button">
                    {/* <a href="#">Load more</a> */}

                    {isCompleted ? (
                      <button
                        onClick={loadMore}
                        type="button"
                        className="btn btn-danger disabled"
                        style={{
                          borderRadius: "50px",
                          width: "148px",
                          height: "51px",
                          border: "none",
                          fontSize: "19px",
                        }}
                      >
                        That's It
                      </button>
                    ) : (
                      <button
                        onClick={loadMore}
                        type="button"
                        style={{
                          borderRadius: "50px",
                          width: "148px",
                          height: "51px",
                          fontSize: "19px",
                          color: "#8790AE",
                          border: "none",
                          background: "#F0F0F0",
                          paddingTop: "0",
                        }}
                        className="button"
                      >
                        Load More
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}

      <section className="callout-area">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="common-heading">
                <h3>
                  <span className="blue-x">Market Smarter, </span> Not Harder
                </h3>
                <p>
                  Building a brand is hard work. Make it easier by marketing
                  with a memorable phone number. Your unique number will leave a
                  lasting impression so you are first to mind when there is a
                  need for your service.
                </p>
                <div className="common-btn">
                  <Link to="/contact" className="btn-primaris">
                    Get Started
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="collout-man">
                <img
                  loading="lazy"
                  src="assets/images/footerMan2.png"
                  alt="man"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Blog;
