import logo from './logo.svg';
import './App.css';
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import {
  Navigation,
  Footer,
  Home,
  Blog,
  FAQ,
  BlogContent,
  ListOfFavriot,
  SearchedNumbers,
  Login,
  Register,
  Contact,
  Checkout,
  Cart,
  Search,
  Map,
  Thankyou,
  Phone
} from "./components";
import Faqcontent from "./components/Faqcontent";
import Preloader from "./Preloader";
import Allroutes from './routess';

import moment from 'moment';
import 'moment-timezone';

// Set the default time zone to UTC
moment.tz.setDefault('UTC');

function App() {
  const [isLoading, setIsLoading] = useState(true);


  useEffect(() => {
    const fetchData = async () => {
      // Simulate data fetching or any asynchronous operations
      // For example, you can fetch data from an API or perform other loading tasks here
      // Replace the setTimeout with your actual data fetching logic
      await new Promise((resolve) => setTimeout(resolve, 1000));

      // Once the data is fetched, set isLoading to false
      setIsLoading(false);
    };

    fetchData();
  }, []);

  return (
    <div>
    {isLoading ? (
      <Preloader />
    ) : (
      <div>
        
        <Allroutes/>
      </div>
    )}
  </div>
  );
}

export default App;
