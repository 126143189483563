import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Validation from "./SignupValidation";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from "react-router-dom";

function Register() {
  const location = useLocation();
  const successMessage = location.state && location.state.successMessage;

  const [values, setValues] = useState({
    name: "",
    email: "",
    company_name: "",
    password: "",
  });
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});

  const handleInput = (event) => {
    setValues((prev) => ({ ...prev, [event.target.name]: event.target.value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const err = Validation(values);
    setErrors(err);

    if (err.name === "" && err.email === "" && err.password === "") {
      axios
        .post("https://node.mumber.com/api/register", values)
        .then((res) => {
          // Clear input fields
          setValues({ name: "", email: "", password: "" });
          setErrors({});

          navigate("/login", {
            state: { successMessage: "User registered successfully!" },
          }); // Pass success message as state
        })
        .catch((err) => {
          if (err.response && err.response.data && err.response.data.message) {
            // Display an error toast with the error message
            toast.error(err.response.data.message, {
              position: toast.POSITION.TOP_CENTER,
            });
          } else {
            console.log(err);
          }
        });
    }
  };

  return (
    <div className="d-flex justify-content-center align-items-center bg-primary pb-5">
      <div className="bg-white p-3 rounded w-25 text-center regi">
        <h3 style={{ color: "#0d6efd" }}>Sign Up</h3>
        <br></br>
        <form action="" onSubmit={handleSubmit}>
          <div className="mb-3">
            <input
              type="text"
              placeholder="Enter Name"
              name="name"
              onChange={handleInput}
              className=" fixe rounded-0"
            />
            {errors.name && <span className="text-danger"> {errors.name}</span>}
          </div>
          <div className="mb-3">
            <input
              type="text"
              placeholder="Enter CompanyName"
              name="company_name"
              onChange={handleInput}
              className=" fixe rounded-0"
            />
          </div>
          <div className="mb-3">
            <input
              type="email"
              placeholder="Enter Email"
              name="email"
              onChange={handleInput}
              className="form-control fixe rounded-0"
            />
            {errors.email && (
              <span className="text-danger"> Email should not be empty</span>
            )}
          </div>
          <div className="mb-3">
            <input
              type="password"
              placeholder="Enter Password"
              name="password"
              onChange={handleInput}
              className="form-control rounded-0"
            />
            {errors.password && (
              <span className="text-danger"> {errors.password}</span>
            )}
          </div>
          <button
            type="submit"
            className="btn btn-success w-100 rounded-0"
            style={{
              color: "black",
              backgroundColor: "#0d6efd",
              borderColor: "#0d6efd",
            }}
          >
            Sign up
          </button>
          <br></br>
          <br></br>
          <Link
            to="/Login"
            className="btn btn-default border w-100 bg-light rounded-0 text-decoration-none"
          >
            Login
          </Link>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
}

export default Register;
