import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

const Policies = () => {
  useEffect(() => {
    // Scroll the page to the top when the component mounts or when the route changes
    window.scrollTo(0, 0);
  }, []);
  const products = JSON.parse(sessionStorage.getItem("cart"));

  var cart_total_amount = 0;

  //   const [product_fee, setproduct_fee] = useState(0);

  //     useEffect(() => {
  //       products.map((product) => {
  //         if (product != null) {
  //           var product_price = product.fees;
  //           product_price = product_price.replace("$", "");
  //           cart_total_amount =
  //           parseFloat(cart_total_amount) + parseFloat(product_price);
  //           setproduct_fee(Math.round((cart_total_amount + Number.EPSILON) * 100) / 100);
  //           console.log(product);
  //         }
  //       });

  //       console.log(product_fee);
  //     });

  return (
    <div>
      <section className="banner-area pages-banner">
        <div className="container">
          <div className="row banner-row align-items-center">
            <div className="col-lg-6">
              <div className="banner-heading">
                <h2>Privacy Policy</h2>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="faq-banner-img man-img">
                <img src="assets/images/faq-banner.png" alt="manImg" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="container">
        <div style={{ textAlign: "center" }}>
          {/* <h4 style={{ color: "black" }} className='mt-5 mb-2'>  Subtotal: ${product_fee} </h4> */}
          <h5 className="mt-4">
            <b>Privacy Statement</b>
          </h5>
          <h5 className="mt-1">
            <b>Updated: November 1, 2023</b>
          </h5>
          <br></br>
        </div>

        <p style={{ color: "black" }} className="mt-3 d-flex">
          <div className="ps-2">
            SAK Marketing, Inc. DBA Mumber.com (the “Company”) is strongly
            committed to protecting the privacy of your personal information.
            This privacy statement explains the Company’ data collection and use
            practices with respect to their websites, and mobile apps (the
            “Sites”). By using the pages in the Sites, you agree to the
            information collection and use practices described in this Privacy
            Statement. If you do not agree to the terms set forth herein, do not
            use the Sites.
          </div>
        </p>

        <p style={{ color: "black" }} className="mt-3 d-flex">
          <div className="ps-2">
            <b>Collection of Personal Information</b>
            <br></br>
            We may ask you for information that personally identifies you or
            provides information about yourself (“personal information”) or
            allows us to contact you to provide a service or carry out a service
            that you have requested. The personal information we collect may
            include your name, your email address, your personal and/or business
            telephone number, and other contact information, and other
            information about products and/or services requested through the
            Sites. You are not required to provide the personal information that
            we have requested, but, if you chose not to do so, in many cases we
            will not be able to provide you with our products or services or
            respond to questions you may have.
          </div>
        </p>

        <p style={{ color: "black" }} className="mt-3 d-flex">
          <div className="ps-2">
            The Sites may also collect certain non-personal information about
            your visit, such as the name of the Internet service provider and
            the Internet Protocol (IP) address through which you access the
            Internet; the date and time you access the Site(s); the pages that
            you access while at the Site(s) and the Internet address of the
            website from which you linked directly to the Site(s). This
            information is used, among other reasons, to help improve the Sites,
            analyze use trends, and administer the Sites.
          </div>
        </p>

        <p style={{ color: "black" }} className="mt-3 d-flex">
          <div className="ps-2">
            <b>Use of Personal Information</b>
            <br></br>
            Except as otherwise required by law, the personal information
            collected on the Sites will be used solely to operate the Sites and
            to provide the service(s) and/or product(s) or carry out the
            transaction(s) you have requested or authorized. In support of these
            purposes, the Company may use personal information to provide you
            with more effective customer service, send promotional or
            advertising materials to you (see opt-out provisions below), to
            improve the Sites and any related Company products or services, and
            to make the Sites easier to use by eliminating the need for your
            repeated entry of the same information.
          </div>
        </p>

        <p style={{ color: "black" }} className="mt-3 d-flex">
          <div className="ps-2">
            The Company may occasionally hire other company to provide limited
            services on our behalf, such as website hosting, answering customer
            questions about products and services, and sending information about
            our products, special offers, and other services. We will only
            provide those company the personal information they need to deliver
            the service. They are required to maintain the confidentiality of
            the information and are prohibited from using that information for
            any other purpose.
          </div>
        </p>

        <p style={{ color: "black" }} className="mt-3 d-flex">
          <div className="ps-2">
            The Company may disclose personal information if required to do so
            by law or in the good faith belief that such action is necessary to
            (a) conform to the edicts of the law or comply with legal process
            served on the Company or the Sites; or (b) protect and defend the
            rights or property of the Company and the Sites. The Company may
            also disclose personal information about you if we determine that
            disclosure is reasonably necessary to enforce our Terms and
            Conditions or protect other users of the Sites.
          </div>
        </p>

        <p style={{ color: "black" }} className="mt-3 d-flex">
          <div className="ps-2">
            <b>Control of Personal Information</b>
            <br></br>
            Except as otherwise described in this Privacy Statement, your
            personal information will not be shared outside of the Company and
            their subsidiaries and affiliates without your permission.
          </div>
        </p>

        <p style={{ color: "black" }} className="mt-3 d-flex">
          <div className="ps-2">
            For persons over the age of 18, if you choose to register with
            through the Site(s), your unique username will be your email address
            and you will need to create a unique password. By using the username
            and password, you will be able to access all portions of the
            Site(s). You are solely responsible for choosing the password and
            maintaining the confidentiality of the password that you choose. It
            is your responsibility to keep your registration data current and
            accurate as well as to protect against unauthorized access to your
            password and to your computer. Failure to protect the security of
            these items may permit unauthorized individuals to obtain your
            personal information. The Company shall not be liable for any loss
            that you may incur because of someone else using your account,
            either with or without your knowledge.
          </div>
        </p>

        <p style={{ color: "black" }} className="mt-3 d-flex">
          <div className="ps-2">
            You must be 18 years old or over to register an account on the
            Site(s). No information should be submitted to or posted to the
            Sites by any child under 18 years of age. The Company does not
            knowingly collect information from children under 18. If you are
            under 18 years old, you may not attempt to register with the
            Site(s). If the Company determines that an account has been
            registered by a person under age 18, such account will be
            terminated. If you are between the ages of 13 and 17, you may use
            the Site(s) only with your parent or guardian’s consent; the Company
            reserve the right to request verification of such parent or
            guardian’s consent. Personal information of persons between ages 13
            and 17 will be collected as described in this privacy statement. By
            registering an account on the Site(s), you represent that you are
            age 18 or over.
          </div>
        </p>
        <p style={{ color: "black" }} className="mt-3 d-flex">
          <div className="ps-2">
            <b>Required Disclosures</b>
            <br></br>
            If required under applicable law, the Company will comply with
            lawful requests for information (which may include personal data)
            from public authorities, law enforcement and national security
            agencies, including to meet national security or law enforcement
            requirements
          </div>
        </p>

        <p style={{ color: "black" }} className="mt-3 d-flex">
          <div className="ps-2">
            <b>Changes to this Privacy Statement</b>
            <br></br>
            We reserve the right to update this privacy statement from time to
            time and without notice to you. All such updates shall be effective
            immediately unless otherwise stated. We encourage you to
            periodically review this privacy statement to stay informed about
            how we are helping to protect the personal information we collect.
            Your continued use of the Sites constitutes your agreement to this
            privacy statement, as amended from time to time.
          </div>
        </p>
        <p style={{ color: "black" }} className="mt-3 d-flex">
          <div className="ps-2">
            <b>Limitations of Privacy Statement</b>
            <br></br>
            This privacy statement explains only data collection and use
            practices related to the Company’ websites; it does not apply to
            other products or services of the Company. Please be aware that this
            privacy statement and any choices you make on the Sites will not
            necessarily apply to personal information you may have provided to
            the Company in the context of other, separately provided, products
            or services.
          </div>
        </p>

        <p style={{ color: "black" }} className="mt-3 d-flex">
          <div className="ps-2">
            <b>Contact Information</b>
            <br></br>
            The Company welcome your comments regarding this privacy statement.
            If you believe that the Company have not adhered to this privacy
            statement, please contact us electronically or via postal mail at
            the following address, and we will use commercially reasonable
            efforts to promptly determine and remedy the problem:
          </div>
        </p>

        <p style={{ color: "black" }} className="mt-3 d-flex">
          <div className="ps-2">
            <b>SAK Marketing, Inc. </b>
            <br></br>
            Attention: Legal Counsel
          </div>
        </p>

        <p style={{ color: "black" }} className=" d-flex">
          <div className="ps-2">Phone: +1 (838) 333-3333</div>
        </p>

        <p style={{ color: "black" }} className=" d-flex">
          <div className="ps-2">E-mail: </div>
        </p>
      </div>

      <section className="callout-area mt-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="common-heading">
                <h3>
                  <span className="blue-x">Market Smarter, </span> Not Harder
                </h3>
                <p>
                  Building a brand is hard work. Make it easier by marketing
                  with a memorable phone number. Your unique number will leave a
                  lasting impression so you are first to mind when there is a
                  need for your service.
                </p>
                <div className="common-btn">
                  <Link to="/contact" className="btn-primaris">
                    Get Started
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="collout-man">
                <img
                  loading="lazy"
                  src="/assets/images/footerMan2.png"
                  alt="man"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Policies;
