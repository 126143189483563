import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";


const Mumber_terms = () => {
  useEffect(() => {
    // Scroll the page to the top when the component mounts or when the route changes
    window.scrollTo(0, 0);
  }, []);
  const products = JSON.parse(sessionStorage.getItem("cart"));

  var cart_total_amount = 0;

//   const [product_fee, setproduct_fee] = useState(0);

//     useEffect(() => {
//       products.map((product) => {
//         if (product != null) {
//           var product_price = product.fees;
//           product_price = product_price.replace("$", "");
//           cart_total_amount =
//           parseFloat(cart_total_amount) + parseFloat(product_price);
//           setproduct_fee(Math.round((cart_total_amount + Number.EPSILON) * 100) / 100);
//           console.log(product);
//         }
//       });
  
//       console.log(product_fee);
//     });
  
    return (
        <div>

            <section className="banner-area pages-banner">
                <div className="container">
                    <div className="row banner-row align-items-center">
                        <div className="col-lg-6">
                            <div className="banner-heading">
                                <h2>License Agreement</h2>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="faq-banner-img man-img">
                                <img src="assets/images/faq-banner.png" alt="manImg" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>








            <div className='container'>

<div style={{ textAlign: "center" }}>
  {/* <h4 style={{ color: "black" }} className='mt-5 mb-2'>  Subtotal: ${product_fee} </h4> */}
  <h5 className="mt-4"><b>*Please agree to the License Term below.</b></h5>
  <br>
</br>
  <p style={{ color: "black" }}> <i> The item selected at checkout shall hereby be referred to as “Telephone Number”. The name entered in the Name on Card field shall hereby be referred to as “Licensee”. The subscriber of record of Telephone Number shall here in be referred to as “Licensor”.  </i></p>
  {/* <h5 className="mt-2">   *Please agree to the License Terms
    This telephone number is being licensed.
    <br></br>You will not own it nor be able to port the number away.*</h5> */}
</div>


<p style={{ color: "black" }} className='mt-3 d-flex'>

  <div>
  {/* <input type="checkbox" name="terms" id="terms2" />  */}
  </div>
  <div className="ps-2">
  <b> There is no ownership or porting away: </b>
  The Licensee understands and agrees that at no time shall they have any ownership, leasehold, or proprietary interest in the telephone number. The Licensee has no rights to become the subscriber of record for the telephone number, to port the Telephone Number to a different carrier, to submit any letter of authorization to any carrier or Resporg.Any and all rights to the Telephone Number will be retained the subscriber of record for the Telephone Number during the term and afterwards.
</div>

</p>

<p style={{ color: "black" }} className='mt-3 d-flex'>

<div>
{/* <input type="checkbox" name="terms" id="terms3" />  */}
</div>
<div className="ps-2">

    <b>Monthly fees:</b> The licensee shall agree to pay: <br></br>
    <ol type="a" style={{marginLeft: "20px"}}>
      <li>
      The monthly license fee as displayed at the time of checkout.
        </li>

        <li>
        Licensee warrants, represents, acknowledges, and agrees that Licensee shall be responsible to pay late fees is payment is not collected within 15 days of the due date. Late fees shall be equal to 25% of the license fee. 
        </li>
    </ol>

  </div>
</p>

<p style={{ color: "black" }} className='mt-3 d-flex'>

  <div>
  {/* <input type="checkbox" name="terms" id="terms4" />  */}
  </div>
  <div className="ps-2">  <b>License duration:</b> The license terms are effective on the date of Telephone Number activation (the "Effective Date"). The license is month-to-month and can be cancelled at any time by the licensee, or terminated by Licensor if licensee's account is in default due to non-payment (or in violation of terms of service).
  </div>
</p>


<p style={{ color: "black" }} className='mt-3 d-flex'>

  <div>
  {/* <input type="checkbox" name="terms" id="terms5" />  */}
  </div>
  <div className="ps-2">   <b> Reconnection:</b> If the License Agreement is terminated after 30 days of non-payment, there is no guarantee of reconnection or continuance of license. It is the sole responsibility of the Licensee to maintain its account in good standing.
  </div>

</p>


<p style={{ color: "black" }} className='mt-3'>
  <b> By checking the boxes above, I acknowledge that I have read and agree to these terms for licensing my Telephone Number. These terms can also be found in our complete  <span style={{ color: "#d5aa00" }}>terms of service.</span></b>


</p>

<br></br>

<h3 className="text-center">Terms of Service</h3>
    <br></br>
    
<p style={{textAlign:"center", color: "black"}}><u>PRELIMINARY STATEMENTS</u></p>

<p style={{ color: "black"}}>This License is entered into with reference to the following facts:</p>

<ul type="a" style={{marginLeft: "20px"}}>

  <li>
  1.LICENSOR has the exclusive right, subject to all applicable rules, obligations, limitations, and conditions under applicable tariffs and statutory and common law to the use of Telephone Number.
  </li>

  <li>
  2.LICENSOR wishes to license to LICENSEE its right to use Telephone Number to advertise, market, promote and otherwise commercially exploit the same under the terms of this License.
  </li>
  <li>
  3.LICENSEE wishes to use the stated Telephone Number for lawful marketing purposes.
  </li>
</ul>

<br></br>

<p style={{ color: "black"}}>NOW, THEREFORE, in consideration of the mutual promises contained in this Agreement, the Parties established this relationship on the terms and conditions that follow:</p>
    <br></br>



<ol type="1" style={{marginLeft: "20px"}}>
      <li>
        <em><b><u>Subject Matter</u></b></em>
        <p style={{ color: "black"}}>The subject matter of this License Agreement is:</p>

          <ol type="a" style={{marginLeft: "20px"}}>
              <li>
              The right to use Telephone Number, subject only to all rules, obligations, limitations, and conditions under applicable tariffs, statutory and common law copyright or trademark laws as may apply, and any other laws or regulations, including those promulgated by agencies having jurisdiction of the subject matter. 
              </li>
              <li>
              The right to use Telephone Number is more specifically defined as the right to receive telephone calls dialed to the Telephone Number from an Area Code(s) phone number within the continental United States of America during the term of the License Agreement, commencing when the Telephone Number becomes operative at Location.
              </li>
             
          </ol>
        </li>

        <li>
        <em><b><u>Duties of the Parties</u></b></em>

        <p style={{ color: "black"}}>
        In consideration of the mutual covenants and restrictions contained herein, the parties promise and agree as follows:
          </p>
          <ol type="a" style={{marginLeft: "20px"}}>
            <li>
            LICENSOR agrees to cause telephone calls connected to Telephone Number from a number that starts with one of the Area Code(s) within the continental United States of America, commencing when the Telephone Number becomes operational at Location and continuing for the duration of this License Agreement, to be routed via available telecommunications facilities across a Local Area Code(s) telephone number, designated by LICENSEE, terminated at Location.
            </li>

            <li>
            LICENSOR agrees to cause such routing to become operational at Location at the earliest possible date, as determined by the telecommunications carrier, following execution and return of this License Agreement.
            </li>

            <li>
            LICENSOR reserves the right, at its sole and exclusive discretion, to utilize the services of any telecommunications carrier, it so chooses, during the term of this License Agreement.
            </li>

            <li>
            LICENSEE agrees to designate, and maintain the designation of, the Local Area Code(s) telephone number(s) across which Telephone Number Area Code(s) calls will be routed during the License Agreement. LICENSEE further agrees to provide compatible telephone line(s), telephone equipment and personnel required to receive the telephone calls routed across the designated Local Area Code(s) telephone number(s) to Location.

                <ol type="i" style={{marginLeft: "20px"}}>
                  <li>
                  Apart from the rights and duties imposed upon the parties herein, LICENSOR shall have no control over, nor knowledge of the conduct of LICENSEE'S business. Accordingly, LICENSEE agrees to indemnify and hold LICENSOR harmless, including but not limited to any reasonable attorney fees incurred by LICENSOR, and defend LICENSOR from any and all actions arising from or pertaining to LICENSEE'S use of Telephone Number. In no event shall either party be liable in contract, tort, or otherwise for incidental, consequential, specific or indirect damages, including, without limitation, lost business profits, even if that party has been advised of the possibility of same, arising out of its acts or omissions in connection with the License.
                  </li>

                  <li>
                  Neither party shall be liable for any delay or failure to perform its obligations hereunder due to any case beyond its reasonable control, including, but not limited to, failure or interruption of the telecommunications or internet carriers to provide adequate service, labor disputes, fire, accident, act of the public enemy, war, rebellion, insurrection, sabotage, transportation delay, shortage of raw material, energy of machinery, act of God, government or the judiciary, provided however that nothing contained herein shall be construed to relieve LICENSEE of his/its obligation to make all payments due hereunder as they become due and payable.
                  </li>

                 
                </ol>
                
            </li>

            <li>
            This License creates no agency, contract of employment, partnership, association or joint venture of any description between the parties. The parties intend and agree that no right of continued or vested use of Telephone Number not specifically and expressly created herein shall be created or extended, based upon any general principal of estoppel or any other general principal of law.
            </li>

            <li>
            LICENSEE agrees to notify LICENSOR during normal Eastern Standard Time business hours of any malfunction in the Telephone Number or routing systems which affect LICENSEE'S use of Telephone Number at Location.
            </li>

            <li>
            LICENSEE acknowledges and agrees that this right to use Telephone Number is subject to all applicable tariffs, laws and rules including and particularly those in relation to non-payment for services, abuse of services, fraud and shortage of network facilities or components.  LICENSEE shall be subject to all terms and conditions of any underlying carrier agreements of LICENSOR. 
            </li>
          </ol>
        </li>

        <li>
        <em><b><u>Compensation Payable to LICENSOR</u></b></em>

        <ol type="a" style={{marginLeft: "20px"}}>

          <li>
          LICENSEE agrees to pay LICENSOR a Monthly License Fee in the amount specified at checkout (the "License Fee"). 
          </li>

          <li>
          As consideration for the rights and license granted hereunder, LICENSEE agrees to pay LICENSOR the License fee every month on the anniversary of the checkout date. Any payments not received by the 15th day after due shall incur a late fee equal to 25% of the License fee and shall be considered a default.
          </li>

          <li>
          LICENSEE agrees to complete a credit application for the telecommunications carrier or LICENSOR so that the Telephone Number relating to Area Code(s), phone charges may be billed directly to LICENSEE at LICENSOR's option. Further, LICENSEE agrees to pay all phone charges i.e. all telephone charges billed by or through the telecommunications carrier (including time and usage, line charge and long distance), together with all Federal and state taxes, surcharges and regulatory fees of any description (the "Billed Charges") which are attributable to Telephone Number and LICENSEE'S use of Telephone Number at Location or otherwise as it relates to Area Code(s). LICENSEE acknowledges that if said credit application is required, that it may be required to be completed before service can begin and further agrees to pay all said telecommunications charged upon receipt.
          </li>

          <li>
          LICENSEE agrees to pay, and hold LICENSOR harmless from, any claims for any and all charges imposed by the Local Area Code(s), i.e. LICENSEE'S local phone company which covers Location, including but not limited to, line installation, line repair, line maintenance, line switching, advanced line gestures, monthly flat rate charges, usage charges, surcharges, deaf and disabled fund charges, local, state, and Federal taxes or any other fees whatsoever imposed in relation to the designated Local Area Code(s) telephone number(s) across which Telephone Number is routed at Location.
          </li>
        </ol>
        </li>

        <li>
        <em><b><u>Designation of Local Area Telephone Number</u></b></em>
        <p style={{ color: "black"}}>
        The designated Local Area Code(s) shall mean the place or places designated from time to time in writing by Licensee as the location to which calls to the Number shall be routed to (“Local Area Code”).
        </p>
        </li>

        <li>
        <em><b><u>Effective Date and Term of License</u></b></em>
        <p style={{ color: "black"}}>
        This License shall be effective on the date of checkout. The license shall continue on a month to month basis until Licensee requests the agreement be terminated by providing written notice 30 days in advance. Licensor has the right to terminate the agreement at any time if Licensee is in default. 
        </p>
        </li>

        <li>
        <em><b><u>Termination</u></b></em>
        <ol type="a" style={{marginLeft: "20px"}}>
          <li>
          In the event of LICENSEE'S default hereunder, this License may be terminated immediately, or at any future time, by LICENSOR, and termination shall be effective from the date of issuance by LICENSOR of such notice.
          </li>

          <li>
          Telecommunications and web address services may be terminated by LICENSOR at any time co-incident with or following the termination of this License, or because of a violation or alleged violation of any telecommunications rules or regulations, fraud, shortage of network facilities and components or of any other tariffs, laws, rules, or regulations by any party or as a result of this License.
          </li>

          <li>
          Upon termination for any reason LICENSOR retains all rights to use, direct, control, point and manipulate Telephone Number or any derivatives thereof and LICENSEE will retain no rights or claims.
          </li>

          <li>
          Licensee may terminate the agreement by providing Licensor notice of their intention to cancel. Licensor will cancel the agreement provided that the Licensee is not in default and Licensee provides 30 day notice of their intention to cancel.
          </li>


        </ol>
        </li>

        <li>
        <em><b><u>Limitation of Liability</u></b></em>
        <p style={{ color: "black"}}>
        LICENSEE acknowledges that no shareholder, director, officer, or agent of LICENSOR shall be liable hereunder to LICENSEE in tort, contract, or otherwise for the failure, either actual or alleged, of LICENSOR to perform its obligations hereunder, such responsibility and liability to be limited to that of LICENSOR and any such liability of LICENSOR shall be limited to one- twelfth of the yearly License Fee related to the Telephone Number.
        </p>
        </li>

        <li>
        <em><b><u>Non-Assignability</u></b></em>
        <p style={{ color: "black"}}>
        The rights and duties imposed under this License are exclusive to the LICENSEE, are non-divisible, non-assignable, and may not be sub-licensed, transferred or assigned by LICENSEE, to any other entity without the advance written consent of LICENSOR. Nothing herein shall prevent LICENSOR from assigning its rights hereunder.
        </p>
        </li>

        <li>
        <em><b><u>Governing Law</u></b></em>
        <p style={{ color: "black"}}>
        This License shall be governed by applicable Federal and/or State laws, rules, communication tariffs and rules and the laws of the State of New York.
        </p>
        </li>

        <li>
        <em><b><u>Arbitration; Submission to Jurisdiction</u></b></em>
        <p style={{ color: "black"}}>
        The parties agree that any dispute arising between them arising out of this License or the relationship herein created, which cannot be resolved amicably, shall be submitted to the American Arbitration Association in Buffalo, New York before any suit is filed in a court of competent jurisdiction, which the parties agree will be in Buffalo, New York. The parties further agree to be bound by the decision of the arbitrator and that a judgment on the award rendered by the arbitrator, which may include administrative fees costs and attorney fees, may be enforced in any court having jurisdiction hereof. The parties may agree to enter into mediation at any time with the cost of the mediator being divided equally.
        </p>
        </li>

        <li>
        <em><b><u>Miscellaneous</u></b></em>
        <ol type="a" style={{marginLeft: "20px"}}>
          <li>
          This License contains the entire understanding between the parties with respect to its subject matter and supersedes all prior understandings and agreements, if any, between and among them.
          </li>

          <li>
          This License shall not be considered modified by any course of dealing between the parties and may only be amended by a written amendment to the License executed by both of the parties.
          </li>

          <li>
          This License shall be binding upon and inure to the benefit of the parties and their respective legal representatives, heirs, devisees, executors, administrators, assigns and successors.
          </li>

          <li>
          Each of the parties shall promptly execute and deliver all documents, provide all information, and take or forebear from all actions as may be reasonably necessary or appropriate to carry out the purposes of this License.
          </li>

          <li>
          This License may be executed in one or more counterparts, all of which shall be taken to be on and the same instrument and all of which shall have the same force and effect as if all of the parties have executed the same counterpart, and any fully executed License may be considered an original.
          </li>

          <li>
          All notices shall be in writing and shall be deemed effective when given by personal delivery or courier or when sent by certified or registered mail to the last known address of the party. Notice upon one individual or corporate entity of LICENSEE shall be considered and construed to have given notice to all of LICENSEE.
          </li>

          <li>
          All pronouns and any variations therefore shall be deemed to refer to the masculine, feminine, neuter, singular, or plural, as the identity of the person or persons may require.
          </li>
          <li>
          No failure by any party to insist upon the strict performance of any covenant, duty, condition of this License to exercise any right of remedy consequent upon a breach thereof shall constitute a waiver of any such breach or of such or any other covenant, term or condition.
          </li>

          <li>
          The License Fee on the anniversary of the first term start date will have an annual increase in the amount of seven percent to account for inflation and asset appreciation.
          </li>

          <li>
          The parties hereto intend and believe that each provision of this License complies with all applicable local, state and federal laws and judicial decisions etc. However, if any provision or provisions, or if any portion of any provision, in this License, is found by a court of law or alleged by a government entity to be in violation of any applicable local, state or federal ordinance, statue, law, rule, administrative or judicial decision, or public policy or such court, or government entity should declare such portion, provision or provisions of this License to be illegal, invalid, unlawful, void or unenforceable as written, then it is the intent of the parties hereto that such portion, provision or provisions shall be given force to the fullest extent that they are legal, valid, and enforceable, that the remainder of this License shall be construed as if such illegal, invalid, unlawful, void or unenforceable, provision or provisions were not contained herein except as it relates to compensation to LICENSOR, and that the rights, obligations and interest of the parties under the remainder of this License shall continue in full force and effect, except that such construction shall not reduce or modify compensation to LICENSOR and shall apply to the rights and obligations of both of the parties and not just the one affected in the particular determination, proceeding or allegation.
          </li>

          <li>
          All advertising, use, promotion and marketing done using Telephone Number shall comply with all applicable laws, statues and Cannons of Ethics.
          </li>

          <li>
          LICENSEE shall take no action which damages the rights, goodwill or reputation of Telephone Number, and LICENSOR use, rights, control and/or "ownership" of same.
          </li>

          <li>
          LICENSEE shall not attempt to trademark, service mark or copyright Telephone Number or any other similar combination of number sequence with or without dashes or other punctuation.
          </li>

          <li>
          LICENSEE shall not disclose any portion of this License to any third party without the advance written consent of LICENSOR.
          </li>

          <li>
          The Preliminary statements of this License are an integral part of this License.
          </li>
        </ol>
        </li>
    </ol>




</div>











            <section className="callout-area mt-5">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="common-heading">
                            <h3><span className="blue-x">Market Smarter, </span> Not Harder</h3>
              <p>Building a brand is hard work. Make it easier by marketing with a memorable
                 phone number. Your unique number will leave a
                 lasting impression so you are first to mind when there is a need for your service.</p>
                                <div className="common-btn">
                                <Link to="/contact" className="btn-primaris">Get Started</Link>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="collout-man">
                                <img loading="lazy" src="/assets/images/footerMan2.png" alt="man" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </div>
    )
}

export default Mumber_terms